import React from "react";

export default function QuestionSectionHeading({ index, heading, paragragh }) {
  return (
    <div className="flex gap-5">
      <div className="flex-shrink-0 w-[21px] h-[22px] leading-[22px] text-center bg-black text-white rounded-full">
        {index}
      </div>
      <div className="border-b-[0.5px] border-[#20202080] pb-2.5 w-full">
        <p className="leading-6 font-medium text-base">
          {heading}{" "}
          {index == 4 && <span className="ml-2 leading-6 font-medium text-xs text-[#666666]">
            Optional
          </span>}
        </p>
        <p className="leading-6 font-medium text-xs text-[#666666]">
          {paragragh}
        </p>
      </div>
    </div>
  );
}
