import { useFormik } from "formik";
import DashboardLayout from "../layout/DashboardLayout";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";

const EditState = ({ state, onHide }) => {
  const initialValues = {
    state_name: state.state_name,
    abbreviation: state.abbreviation,
    short_name: state.short_name,
    status: state.status,
  };
  console.log("state", state)
  const formik = useFormik({
    initialValues,
    onSubmit: async (data) => {
      try {
        const response = await ApiRequest.editState({ data, id: [state.id] });
        if (response.status === 200) {
          console.log("response update", response);
          toast.success("Successfully updated");
          onHide();
        }
      } catch (e) {
        console.log("Error-->", e);
        toast.error(e.response.data.message);
      }
      console.log("data", data);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="outlined">{"Edit State"}</Typography>
      <br></br>
      <br></br>
      <TextField
        label={"State Name"}
        variant="outlined"
        defaultValue={state.state_name}
        onChange={formik.handleChange}
        name="state_name"
      />
      <TextField
        label={"Abbreviation"}
        variant="outlined"
        defaultValue={state.abbreviation}
        onChange={formik.handleChange}
        name="abbreviation"
      />
      <TextField
        label={"Short Name"}
        variant="outlined"
        defaultValue={state.short_name}
        onChange={formik.handleChange}
        name="short_name"
      />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={state.status}
          onChange={(e) =>  {formik.handleChange(e); console.log("eeeeeeeeee", e.target.value)}}
          name="status"
        >
          <FormControlLabel value="active"  name="status" control={<Radio />} label="Active" />
          <FormControlLabel value="inactive" name="status"  control={<Radio/>} label="inactive" />
        </RadioGroup>
      </FormControl>
     
      <Button type="submit" className="btn btn-primary">
        {"Edit"}
      </Button>
    </form>
  );
};

export default EditState;
