import React from 'react';

const InputComponent = ({ 
  type, 
  errors, 
  placeholder, 
  event, 
  className, 
  style, 
  name, 
  onclick, 
  onchange 
}) => {
  return (
    <div className="input-wrapper" style={{ marginBottom: '10px' }}> {/* Added wrapper for layout */}
      <input
        className={className}
        type={type}
        name={name}
        placeholder={placeholder}
        style={style}
        onClick={onclick}
        onChange={onchange}
        {...(event && event(name))}
      />
      {errors && errors[name] && (
        <p style={{ color: 'red', fontSize: '0.875rem', marginTop: '4px' }} className="error-message">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export default InputComponent;
