import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as EditSvg } from "../assests/icons/edit-icon.svg";
import { ReactComponent as DeleteSvg } from "../assests/icons/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { getBooksAsyncThunk } from "../redux/slices/Master/masterSlice";
import TestimonialsTable from "../components/TestimonialsTable";
import { toast } from "react-toastify";
import { authorizatonCommonFun } from "../utils/basicService";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
// import { ReactComponent as EditSvg } from "../assests/icons/edit.svg";
// import { ReactComponent as DeleteSvg } from "../assests/icons/delete.svg";
const PublishedEBook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const books = useSelector(state => state?.master?.books);
  useEffect(() => {
    dispatch(getBooksAsyncThunk());
  }, []);

  useEffect(() => {
    console.log("books", books)

  }, [books])

  const data = [
    {
      id: "1",
      examType: "Seal",
      ebookName: "Write Persuasive essay in 15 minutes",
      description: "Teachers in detail about Persuasive writing",
      price: "$10",
      discountApplied: "10%",
      action: "Archive",
    },
  ];

  const handleUploadNewEBook = () => {
    navigate("/company/add-book");
  };

  const action = [
    {
      label: <EditSvg className="cursor-pointer" />,
      action: (testimonial) => {
        console.log("testimonial---->", testimonial);
       
        // setEditData(testimonial);
        // setOpen(true);
        navigate(`/company/book/${testimonial.id}`);
      }
    },
    {
      label: <DeleteSvg className="cursor-pointer" />,
      action: (testimonial) => {
        console.log("testimonial---->", testimonial);
        handleDelete(testimonial.id);
        // handleDelete(testimonial.id)
      }
    }

 ]

    // Handle delete promo code
    const handleDelete = async (id) => {
      try {
       const response = await ApiRequest.deleteBook(id);
       if(response.status===200) {
         toast.success("E-book deleted successfully");
          const data = {
           page,
           limit
          }
         dispatch(getBooksAsyncThunk(data));
       }
     } catch (e) {
       authorizatonCommonFun(e);
       console.log("Error", e);
       toast.error(e.response.data.message);
     }
     };

     
     const columns = [
      { name: "Exam Type", value: "exam_type_id" },
      { name: "eBook Name", value: "title" },
      { name: "Description", value: "price_information" },
      // { name: "Status", value: "book_status", },
      // { name: "Start Date", value: "startDate", type: "date" },
      // { name: "End Date", value: "endDate", type: "date" },
    ];
  const daata = {...books, data: books?.data ? books?.data.length > 0 && books?.data?.map((i) => {
    return {
      ...i,
      exam_type_id: i.exam_type?.exam_name 
    }
   }) : []};

  return (
    <DashboardLayout>
        <h1 className="dashboard-heading text-center">{"Publish E-Book"}</h1>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">

        <div>
          <TestimonialsTable 
             columns={columns}
             setPagee={setPage}
             setPageLimit={setLimit}
             actions={action}
             data={daata}
             handleDelete={handleDelete}
            //  handleEdit={handleUpdate}
          />
          {/* <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-200 text-gray-700 font-bold">
                <th className="px-4 py-2">Id</th>
                <th className="px-4 py-2">Exam Type</th>
                <th className="px-4 py-2">eBook Name</th>
                <th className="px-4 py-2">Description</th>
                <th className="px-4 py-2">Price</th>
                <th className="px-4 py-2">Discount Applied</th>
                <th className="px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{item.id}</td>
                  <td className="px-4 py-2">{item.examType}</td>
                  <td className="px-4 py-2">{item.ebookName}</td>
                  <td className="px-4 py-2">{item.description}</td>
                  <td className="px-4 py-2">{item.price}</td>
                  <td className="px-4 py-2 text-center">{item.discountApplied}</td>
                  <td className="px-4 py-2">
                    <div className="flex space-x-2">
                      <EditSvg className="cursor-pointer" />
                      <DeleteSvg className="cursor-pointer" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
          <div className="bg-gray-100 p-4 text-center">
            <button
              className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded"
              onClick={handleUploadNewEBook}
            >
              Upload New eBook
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PublishedEBook;
