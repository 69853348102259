import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import "./App.css";
import { theme } from "./theme";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthenticatedUser, setAuthToken } from "./redux/slices/auth/authSlice";
import router from "./Router";

function App() {
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log("token11", token);
  //   if(token) {
  //     dispatch(setAuthToken(token))
  //   } else {
  //     navigate('/login');
  //   };
  // }, [dispatch, token]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fragment>
          <RouterProvider router={router} />
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
