import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import { ReactComponent as EditSvg } from "../../assests/icons/edit.svg";
import { ReactComponent as DeleteSvg } from "../../assests/icons/delete.svg";
import { useNavigate } from "react-router-dom";

const TestimonialsTable = ({ data, handleDelete, handleEdit, setPagee, setPageLimit, columns, actions }) => {
  const navigate = useNavigate();

  // Destructure data
  console.log("data", data);
  
  const { data: testimonials = [], page = 1, total = 0, limit = 10 } = data;
  console.log("testimonials", testimonials)
  const [currentPage, setCurrentPage] = useState(page - 1); // Convert 1-based API page to 0-based
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setPagee(newPage + 1); // Convert back to 1-based for API
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setRowsPerPage(newLimit);
    setCurrentPage(0); // Reset to first page
    setPageLimit(newLimit); // Update rows per page for the parent
    setPagee(1); // Reset to first page for API
  };


  return (
    <>
      <TableContainer component={Paper} className="shadow-none">
        <Table
          sx={{
            minWidth: 650,
            borderCollapse: "separate",
            borderSpacing: "10px",
          }}
          aria-label="testimonials table"
        >
          <TableHead>
            <TableRow
              sx={{
                "& td, & th": {
                  border: 0,
                  margin: "5px",
                  color: "#6C757D",
                  lineHeight: "22px",
                  borderBottom: "1px solid #1E1E1E52",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                },
              }}
            >
              <TableCell>Id</TableCell>
              {columns.map((item, index) => (
                <TableCell key={index}>{item.name}</TableCell>
              ))}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials && testimonials.length > 0 && testimonials.map((testimonial, i) => (
              <TableRow
                key={testimonial.id}
                sx={{
                  "& td": {
                    borderBottom: "1px solid #EAE9F1",
                    margin: "5px",
                    color: "#6C757D",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  },
                }}
              >
                <TableCell>{currentPage * rowsPerPage + i + 1}</TableCell>
                {columns.map((column, colIndex) => (
                  <TableCell key={colIndex}>
                    {(column.value === "created_at" || column.type == "date" ? testimonial[column.value] : "")
                      ? moment(testimonial[column.value]).format("MMMM DD, YYYY")
                      : typeof(testimonial[column.value])==="boolean" ? testimonial[column.value] ? 'Active' : 'unactive' : testimonial[column.value]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  <div className="flex justify-center items-center gap-5">
                    {actions && actions.length > 0 && actions.map((item) => {
                        return <p onClick={() => item.action(testimonial)}>{item.label}</p>
                    })}
                    {/* <EditSvg
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(`/company/edit-testimonial/${testimonial.id}`);
                      }}
                    />
                    <DeleteSvg
                      className="cursor-pointer"
                      onClick={() => handleDelete(testimonial.id)}
                    /> */}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <TablePagination
        component="div"
        count={total} // Total testimonials from API
        page={currentPage} // Current page
        onPageChange={handleChangePage} // Function to handle page change
        rowsPerPage={rowsPerPage} // Rows per page
        onRowsPerPageChange={handleChangeRowsPerPage} // Function to change rows per page
        rowsPerPageOptions={[5, 10, 15]} // Options for rows per page
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`
        }
      />
    </>
  );
};

export default TestimonialsTable;
