export const ApiUrls = {
  // auth
  login: "/auths/login",
  allUsers: "/users/",
  search: "/users",
  userdelete: "/users",
  profileUpdate: "/users",
  editState: "get/states",

  createUser: "/auths/signup",
  verificationUser: "/auths/verify",
  forgotPassword: "/auths/forgot/password",
  confirmPassword: "/auths/reset/password",

  // packages
  getPackages: "/packages/all",
  getPackage: "/packages",
  createPackage: "/packages/create",
  editPackage: "/packages/edit",
  deletePackage: "/packages/delete",
  searchPackage: "/packages/list/search",
  publishPackage: "/packages/publish-package",
  getPublishPackage: "publish-package",
  // essay
  getEssays: "/packages/essay",
  createEssay: "/packages/essay/create",
  editEssay: "/packages/essay",
  deleteEssay: "/packages/essay",
  // state dropdown
  getStateDrown: "/get/states",
  // exam types dropdown
  getExamTypesDropdown: "/get/exams",
  // exam conducted by dropdown
  getExamConductedByDropdown: "/get/exams-conducted-by",
  createExamConducted: "/exams-conducted-by",
  imageUploadBooks: "/images/upload",
  addBook: "books",
  getBookId: "books",

  



  // subjects dropdown
  getSubjectsDropdown: "/get/subjects",
  //searched pakages Dropdown
  getSearchedPakageDropdown: "/packages/list/search",

  // subjects dropdown
  getTopicsDropdown: "/questions/topics/list",
  getSubtopicsDropdown: "/questions/subtopics/list",
  // questions
  addQuestion: "/questions",
  addRandomQuestion: "/test-questions/random/select",
  addComprehension: "/paragraphs",
  addBulkQuestions: "/questions/bulk",
  searchQuestions: "/questions/search",

  // test
  createTest: "/tests/create",
  createAdminUser: "/users",
  getTests: "/tests",
  searchTest: "/tests/search",
  getTest: "/tests/get-one",
  editTest: "/tests",
  deleteTest: "/tests",
  editComprehension: "/paragraphs/update",
  getTestQuestion: "/test-questions",
  assignQuestionToTest: "/test-questions",
  usedByTest: "test-questions/show/name",
  updatePosition: "test-questions/update/positions",
  getCounter: "/counters",
  updateCounter: "/counters",
  DashboardCard: "dashboards/card",

  // Testimonial
  createTestimonial: "/testimonials",
  editTestimonial: "/testimonials",
  getByIdTestimonial: "/testimonials",
  getByTestimonial: "/testimonials",
  createDiscounts: "/discounts",
  getDiscount: "/discounts",
  analysisData: "/questions/report/data"
};
