import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { analysisFilter } from "../redux/slices/Master/masterSlice";
import { getExamTpesDropdownAsyncThunk, getSubjectsDropdownAsyncThunk } from "../redux/slices/dropdowns/dropdownSlice";

const QuestionBankAnalysis = () => {
  const [complexity, setComplexity] = useState("");
  const [subjectt, setSubject] = useState("");
  const [examType, setExamType] = useState("");
  const [type, setType] = useState('');
  const [showReadingTable, setShowReadingTable] = useState(false);
  const subjects = useSelector((store) => store.dropdown.subjects);

  const dispatch = useDispatch();
  const { analysisData } = useSelector((state) => state.master);

  // Fetch data when filters are applied
  const fetchFilteredData = (subject) => {
    const queryParams = { 
      complexity,
       subject: subject ? "" : subjectt,
       question_type: type,
       examTypeId: subject ? "" :examType
       };
    dispatch(analysisFilter(queryParams)); // Dispatch with query parameters
  };
  const examTypes = useSelector((store) => store.dropdown.examTypes);

  useEffect(() => {
    fetchFilteredData(); // Fetch initial data on component mount
    dispatch(getSubjectsDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());

  }, [type]);

  const handleFilter = () => {
    console.log("Filtering with:", complexity, subjectt, examType);
    fetchFilteredData(); // Fetch filtered data
  };

  const handleClear = () => {
    setComplexity("");
    setSubject("");
    setExamType("");
    fetchFilteredData(true); // Reset filters and fetch data
  };

  const toggleReadingTable = (type) => {
    setShowReadingTable(!showReadingTable);
    setType(type);
  };

  useEffect(() => {
console.log("analysisData", analysisData)
  }, [analysisData]);

  return (
    <DashboardLayout>
      <div className="p-6 bg-gray-50 min-h-screen">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-lg font-semibold text-gray-700">
            Question Bank Analysis Report (Multiple Choice, Multiple Response &
            True False)
          </h1>
          <button className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600">
            Print
          </button>
        </div>

        {/* Filter Section */}
        <div className="flex items-center gap-4 mb-6 flex-wrap">
          <select
            value={complexity}
            onChange={(e) => setComplexity(e.target.value)}
            className="border-gray-300 rounded-md px-3 py-2 w-1/4 focus:ring-2 focus:ring-purple-500"
          >
             <option value="">Select Complexity</option>
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>

          <select
            value={subjectt}
            onChange={(e) => setSubject(e.target.value)}
            className="border-gray-300 rounded-md px-3 py-2 w-1/4 focus:ring-2 focus:ring-purple-500"
          >
            <option value="">{"Select Subject"}</option>
            {subjects && subjects.length > 0 && subjects.map((item) => {
              return <option value={item.id}>{item.subject_name}</option>
            })}
          </select>

          <select
            value={examType}
            onChange={(e) => setExamType(e.target.value)}
            className="border-gray-300 rounded-md px-3 py-2 w-1/4 focus:ring-2 focus:ring-purple-500"
          >
             <option value="">Select ExamType</option>
             {examTypes && examTypes.length > 0 && examTypes.map((item) => {
                   return <option value={item.id}>{item.exam_name}</option>
             })}
{/*             
            <option value="Mock">Mock</option>
            <option value="Final">Final</option> */}
          </select>

          <button
            onClick={handleFilter}
            className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-900"
          >
            Filter Now
          </button>
          <button
            onClick={handleClear}
            className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
          >
            Clear Filter
          </button>
          {!showReadingTable ? <button
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            onClick={() => toggleReadingTable('reading_comprehension')}
          >
           {"Reading Comprehension Question"}
          </button> : <button
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            onClick={() => toggleReadingTable('')}
          >
            {"MC MR TR Question"}
          </button> }
         
        </div>

        {/* Data Display Section */}
        <div>
          {analysisData && analysisData.length > 0 && analysisData?.map((item, index) => {
            if (item.question_type === "reading_comprehension") {
              return (
                <ParagraphSection
                  key={index}
                  title="(B) Reading Paragraph"
                  data={item.data}
                />
              );
            } else {
              return (
                <Section
                  key={index}
                  title={
                    item.question_type === "MCQ"
                      ? ` Multiple Choice Questions`
                      : item.question_type === "TrueFalse" ? "True False" : "Multi Multiple Response"
                  }
                  data={item.data}
                />
              );
            }
          })}
        </div>
      </div>
    </DashboardLayout>
  );
};

const Section = ({ title, data }) => (
  <div className="mb-6">
    <h2 className="text-lg font-semibold text-gray-700 mb-3">{title}</h2>
    <table className="w-full border-collapse border border-gray-300">
      <thead className="bg-purple-100">
        <tr>
          <th className="border border-gray-300 px-4 py-2">TOPIC</th>
          <th className="border border-gray-300 px-4 py-2">SUB-TOPIC</th>
          <th className="border border-gray-300 px-4 py-2">COMPLEXITY</th>
          <th className="border border-gray-300 px-4 py-2">
            NUMBER OF QUESTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr key={index} className="odd:bg-white even:bg-gray-50">
            <td className="border border-gray-300 px-4 py-2">{row.topic}</td>
            <td className="border border-gray-300 px-4 py-2">{row.subtopic}</td>
            <td className="border border-gray-300 px-4 py-2">
              {row.difficultyLevel}
            </td>
            <td className="border border-gray-300 px-4 py-2 text-center">
              {row.questionCount}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ParagraphSection = ({ title, data }) => (
  <div className="mb-6">
    <h2 className="text-lg font-semibold text-gray-700 mb-3">{title}</h2>
    <table className="w-full border-collapse border border-gray-300">
      <thead className="bg-purple-100">
        <tr>
          <th className="border border-gray-300 px-4 py-2">PARAGRAPH TOPIC</th>
          <th className="border border-gray-300 px-4 py-2">
            PARAGRAPH SUB-TOPIC
          </th>
          <th className="border border-gray-300 px-4 py-2">COMPLEXITY</th>
          <th className="border border-gray-300 px-4 py-2">
            NUMBER OF QUESTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr key={index} className="odd:bg-white even:bg-gray-50">
            <td className="border border-gray-300 px-4 py-2">{row.topic}</td>
            <td className="border border-gray-300 px-4 py-2">{row.subtopic}</td>
            <td className="border border-gray-300 px-4 py-2">
              {row.complexity}
            </td>
            <td className="border border-gray-300 px-4 py-2 text-center">
              {row.count}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default QuestionBankAnalysis;
