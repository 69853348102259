import React, { useEffect, useState } from "react";
import { ReactComponent as BookSvg } from "../../assests/icons/book.svg";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index.js";
import { testSchema } from "../../utils/yup/testSchema.ts";
import { editPackageAsyncThunk,} from "../../redux/slices/package/packageSlice.js";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelectDropdown from "../MultiSelectDropdown/Index.jsx";
import { getSearchedPakageDropdownAsyncThunk } from "../../redux/slices/dropdowns/dropdownSlice.js";
import { ReactComponent as DropdownChevronSvg } from "../../assests/icons/dropdown-arrow.svg";
import { createTestAsyncThunk, editTestAsyncThunk } from "../../redux/slices/test/testSlice.js";
export default function CreateTest({ fields, data, isEdit, duplicate=null }) {
  console.log("duplicate", duplicate)
  const [inputValue, setInputValue] = useState("");
  const [pakegeSubjects, setPakageSubjects] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const options = useSelector((store) => store.dropdown.getSearchedPakage);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const createLoading = useSelector((store) => store.tests.loadings.createTestAsyncThunk)
  const selectPackage = useSelector((store) => store.tests.packageId);
  const editLoading = useSelector((store) => store.tests.loadings.editTestAsyncThunk);

  console.log("dataaaaaa", data)

  const subjectNames = subjects
  .filter(subject => pakegeSubjects?.includes(subject.id))
  .map(subject => subject.subject_name);
  const initialValues = {
    test_name: data?.test_name,
    test_description: data?.test_description,
    max_number_of_questions: data?.max_number_of_questions,
    subjects_in_test: data?.subjects_in_test,
    grade: data?.grade,
    state_id: data?.state?.id,
    test_duration: data?.test_duration,
    difficulty_level: data?.difficulty_level,
    allow_student_timer_control: data?.allow_student_timer_control,
    show_calculations: data?.show_calculations,
    quality_checked: data?.quality_checked,
    is_active:data?.is_active,
    status: data?.status,
    package_id: data?.package?.id,
    exam_type: data?.exam_type?.id,
    test_conducted_by: data?.test_conducted_by?.id,
    // created_at: ""
  };
  const [sideValues, setSideValues] = useState([]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    submitCount,
  } = useFormik({
    initialValues,
    validationSchema: testSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      values.is_active = values.is_active === "false" ? false : Boolean(values.is_active);
      values.show_calculations = values.show_calculations === "false" ? false : Boolean(values.show_calculations);;
      values.quality_checked = values.show_calculations === "false" ? false : Boolean(values.quality_checked);
      values.allow_student_timer_control = values.show_calculations === "false" ? false : Boolean(values.allow_student_timer_control);
      if (isEdit) {
      values.is_active = sideValues.is_active === "false" ? false : Boolean(sideValues.is_active)

      values.show_calculations = sideValues?.show_calculations === "false" ? false : Boolean(values.show_calculations);
      values.quality_checked = sideValues?.quality_checked === "false" ? false : Boolean(values.quality_checked);
      values.allow_student_timer_control = sideValues?.allow_student_timer_control === "false" ? false : Boolean(values.allow_student_timer_control);
      values.state_id = sideValues?.state_id ? sideValues.state_id : (values.state_id);
      values.test_duration = sideValues?.test_duration ? sideValues.test_duration : values.test_duration;
      values.difficulty_level = sideValues?.difficulty_level ? sideValues.difficulty_level : values.difficulty_level;
      values.grade = sideValues?.grade ? sideValues.grade : values.grade;
      // console.log("sideValuessideValues", sideValues)
      // console.log("values------->", values)

        dispatch(editTestAsyncThunk({ data: values, id, navigate }));
      }
      else {
        dispatch(createTestAsyncThunk({ data: values, action, navigate }));
      }
    },
  });
  const handleInputChange = async (event, value) => {
    setInputValue(value);
    dispatch(getSearchedPakageDropdownAsyncThunk({ search: value }));
  };
  
  useEffect(() => {
    dispatch(getSearchedPakageDropdownAsyncThunk());
  }, [])
 
  useEffect(() => {
    console.log("optionsssss-s->>", initialValues)
  }, [initialValues])
  
  // let defaultPackageName = options.

  return (
    <>
      <h1 className="dashboard-heading text-center">Create a Test</h1>
      <p className="text-base text-center leading-6 text-[#00000099] mt-2">
        (Create a Test for Each State Exam){" "}
      </p>
      <div
        className="mt-10 max-w-[1000px] mx-auto rounded-[5px] py-[60px] px-12 bg-white"
        style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
      >
        <form
          onSubmit={handleSubmit}
          className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-9"
        >
          {fields?.map((pack) => {
            if (pack.select) {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={pack.id}
                    options={pack.options.filter(
                      (option) => !(option.value === false && values[pack.id] === false)
                    )} 
                    value={typeof(values[pack.id]) === "boolean" ? (values[pack.id]===false?"false":"true") : values[pack.id] || ""}
                    placeholder={pack.placeholder}
                    disablePlaceholder={true}
                    onChange={(e) => {setSideValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));console.log("is_active", e.target.name, "is_Active_value", e.target.value);handleChange(e)}}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.multiline) {
              return (
                <div className="lg:max-w-[336px] lg:row-span-2" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={pack.id}
                    multiline={true}
                    rows={5}
                    placeholder={pack.placeholder}
                    defaultValue={values[pack.id]}
                    onChange={(e) => {handleChange(e)}}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.autoComplete) {
              return (<>
                {data?.package ?
                   <div className="lg:max-w-[336px] " key={pack.id}>
                    {console.log("dataaaa.package", data.package)}
                   <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                     {pack.inputLabel}
                   </InputLabel>
                   <Autocomplete
                     fullWidth
                     options={options}
                     color="primary"
                     getOptionLabel={(option) => option.package_name}
                     onInputChange={handleInputChange}
                     defaultValue={data?.package}
                     // inputValue={values.package_name}
                     popupIcon={<DropdownChevronSvg />}
                     onChange={
                       (_, selectedOption) =>{
                         setFieldValue("package_id", selectedOption?.id)
                         setPakageSubjects(selectedOption?.subjects_in_package)}
                         // console.log("Selected package:", selectedOption)
                     }
                     // loading={loading}
                     renderInput={(params) => (
                       <TextField
                         value={values.package_id}
                         id="package_id"
                         error={touched.package_id && errors.package_id}
                         helperText={touched.package_id && errors.package_id}
                         {...params}
                         placeholder="Search Package"
                         variant="outlined"
                         InputProps={{
                           ...params.InputProps,
                           endAdornment: (
                             <>
                               {/* {loading ? (
                                 <CircularProgress color="inherit" size={20} />
                               ) : null}
                               {params.InputProps.endAdornment} */}
                             </>
                           ),
                         }}
                       />
                     )}
                     sx={{
                       "& .MuiSelect-icon": {
                         top: "calc(50% - 0.4em)",
                         right: "15px",
                       },
                     }}
                   />
                 </div>
                 : Object.values(selectPackage).length > 0 ? 
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    options={options}
                    color="primary"
                    getOptionLabel={(option) => option.package_name}
                    onInputChange={handleInputChange}
                    defaultValue={Object.values(selectPackage).length>0 ? selectPackage : {id: 0, name: "sds"}}
                    // inputValue={values.package_name}
                    popupIcon={<DropdownChevronSvg />}
                    onChange={
                      (_, selectedOption) =>{
                        setFieldValue("package_id", selectedOption?.id)
                      setPakageSubjects(selectedOption?.subjects_in_package)}
                      // console.log("Selected package:", selectedOption)
                    }
                    // loading={loading}
                    renderInput={(params) => (
                      <TextField
                        value={values.package_id}
                        id="package_id"
                        error={touched.package_id && errors.package_id}
                        helperText={touched.package_id && errors.package_id}
                        {...params}
                        placeholder="Search Package"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {/* {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment} */}
                            </>
                          ),
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiSelect-icon": {
                        top: "calc(50% - 0.4em)",
                        right: "15px",
                      },
                    }}
                  />
                </div> :    <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    options={options}
                    color="primary"
                    getOptionLabel={(option) => option.package_name}
                    onInputChange={handleInputChange}
                    // defaultValue={Object.values(selectPackage).length>0 ? selectPackage : {id: 0, name: "sds"}}
                    // inputValue={values.package_name}
                    popupIcon={<DropdownChevronSvg />}
                    onChange={
                      (_, selectedOption) =>{
                        setFieldValue("package_id", selectedOption?.id)
                      setPakageSubjects(selectedOption?.subjects_in_package)}
                      // console.log("Selected package:", selectedOption)
                    }
                    // loading={loading}
                    renderInput={(params) => (
                      <TextField
                        value={values.package_id}
                        id="package_id"
                        error={touched.package_id && errors.package_id}
                        helperText={touched.package_id && errors.package_id}
                        {...params}
                        placeholder="Search Package"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {/* {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment} */}
                            </>
                          ),
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiSelect-icon": {
                        top: "calc(50% - 0.4em)",
                        right: "15px",
                      },
                    }}
                  />
                </div>}
              </>);
            } else if (pack.id === "subjects_in_package") {
              return (
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    disabled={pack.disabled}
                    defaultValue={subjectNames} // Updated value to include "saleem" and "nadeem"
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              options?.find((x) => x?.value === value)?.name ||
                              value
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {/* Add menu items here */}
                  </Select>
                </div>
              );
            }
            else if (pack.id === "subjects_in_test") {
              return (
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <MultiSelectDropdown
                    id={pack.id}
                    disablePlaceholder={true}
                    placeholder={"Please select subjects"}
                    options={pack.options}
                    setFieldValue={(field, selected) => {
                      // Ensure that `selected` is an array (it could be null/undefined)
                      setFieldValue(
                        field,
                        selected?.split(",")
                        // Array.isArray(selected) ? selected : []
                      );
                    }}
                    value={
                      Array.isArray(values[pack.id]) ? values[pack.id] : []
                    } // Ensure value is always an array
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            }
            else {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  {console.log("values[pack.id]testname", values[pack.id])}
                  <TextField
                    fullWidth
                    type={pack.type}
                    id={pack.id}
                    placeholder={pack.placeholder}
                    value={values[pack.id]}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            }
          })}
          <div className="lg:col-span-2 lg:max-w-[435px] mx-auto w-full">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={createLoading || editLoading}
              className="rounded-lg px-6 py-3 leading-6 text-[20px] font-semibold font-poppins"
            >
              {(createLoading || editLoading) ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
