import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import { ReactComponent as SearchSvg } from "../assests/icons/search.svg";
import { ReactComponent as NoQuestionsSvg } from "../assests/images/no-question.svg";
import {
  alpha,
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  styled,
  TextField,
} from "@mui/material";
import TextFieldDropdown from "../components/Dropdown/Index";
import SortComponent from "../components/Sort/Index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { questionBankFilters } from "../constants/questionBankFilters";
import TablePerPage from "../components/TablePerPage/Index";
import QuestionCard from "../components/QuestionCard/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
  getSubtopicsDropdownAsyncThunk,
  getTopicsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice";
import PaginationButtons from "../components/PaginationButtons/Index";
import ComprehensionQuestionCard from "../components/ComprehensionQuestionCard/Index";
import {
  getQuestionAsyncThunk,
  searchQuestionsAsyncThunk,
} from "../redux/slices/question/questionSlice";
import {
  getTestAsyncThunk,
  getTestQuestionAsyncThunk,
} from "../redux/slices/test/testSlice";
import TestLayout from "../layout/testLayout";
import BasicCard from "../components/Box";
import BasicCardOne from "../components/BasicCard";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,

    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function TestQuestionBank() {
  const [fields, setFields] = useState([]);
  const [cardShow, setCardShow] = useState({});

  const [questionFilters, setQuestionFilters] = useState({
    question_type: "",
    is_image: "false",
    topic: "",
    quality_checked: "true",
    subtopic: "",
    grade: "",
    subject: "",
    exam_type_id: "",
    difficulty_level: "",
    question_id: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actions, setActions] = React.useState(null);
  const open = Boolean(anchorEl);
  const openActions = Boolean(actions);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get("limit") || 10;
  const page = queryParams.get("page") || 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const singleTest = useSelector((store) => store.tests.test);

  const { examTypes, subjects, topics, subtopics } = useSelector(
    (store) => store.dropdown
  );
  let hh = useSelector((store) => store.tests);
  //   useEffect(() => {
  //    console.log(" useSelector((store) => store.tests",  hh);
  //   }, [hh])
  //   console.log(" useSelector((store) => store.tests",  useSelector((store) => store.tests));
  const questionBank = useSelector((store) => store.tests?.getTestQuestion);
  const { id } = useParams();

  useEffect(() => {
    // Fetch all dropdown data on mount
    // dispatch(getExamTpesDropdownAsyncThunk());
    const data = { page: 1, test_id: id, limit: +limit };
    dispatch(getTestQuestionAsyncThunk(data));
    // dispatch(getSubjectsDropdownAsyncThunk());
    // dispatch(getTopicsDropdownAsyncThunk());
    // dispatch(getSubtopicsDropdownAsyncThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTestAsyncThunk(id));
  }, []);

  // useEffect(() => {
  //    console.log("singleTest", singleTest);
  // }, [singleTest])

  useEffect(() => {
    console.log("questionBank--", questionBank);
  }, [questionBank]);

  useEffect(() => {
    console.log("cardShow--->11222", cardShow);

  }, [cardShow]);
  const [description, setDescription] = useState('');
  const instructionSave = () => {

  }

  return (
    <>
      <TestLayout name={singleTest?.test_name} testId={singleTest?.test_id} />
      {/* // <DashboardLayout> */}
    
      <div
        className="bg-white rounded-lg "
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        <div className="px-[77px] py-[37px] ">
        <h1 className="dashboard-heading text-left">{"Test Question Bank"}</h1>
          <div className="flex sm:flex-row flex-col gap-[14px] sm:items-center flex-wrap">
            <div className="w-max mt-3">
              <SortComponent />
            </div>
          </div>
        </div>
        <div className="px-[77px] py-[25px] border-t-[1px] border-[#202020]">
          <div className="flex justify-between gap-3">
            {/* <TablePerPage
              options={[10, 25, 50, 100]}
              value={limit || 10}
              onChange={(e) => {
                navigate({
                  pathname: "/company/question-bank",
                  search: `?limit=${e.target.value || 10}&page=1`,
                });
                dispatch(
                    getQuestionAsyncThunk({
                    page: 1,
                    test-questions: 
                    limit: e.target.value,
                    ...questionFilters,
                  })
                );
              }}
              id={"question-bank"}
            /> */}
            <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
              Count: {questionBank?.total}
            </p>
          </div>
          <div className="mt-5">
            {questionBank?.total > limit && (
              <PaginationButtons
                dataLength={questionBank?.questions?.length}
                total={questionBank?.total}
                pathname={"/company/question-bank"}
                asyncThunk={getQuestionAsyncThunk}
                asyncThunkData={{ limit: +limit, ...questionFilters }}
              />
            )}
          </div>
          <div className="my-20 flex flex-col gap-5">
            <BasicCardOne
              onClick={() => navigate(`/company/add_instruction/${id}`)}
              question={{
                question_text: "+ Add Introduction",
                question_id: "sdd",
              }}
            />
            {questionBank?.results?.map((question, ind) =>
              question?.passage ? (
                <ComprehensionQuestionCard
                  passage={question.passage}
                  setCardShow={(questionId) => {
                    setCardShow((prev) => ({
                      [questionId]: !prev[questionId], // Toggle current card
                    }));
                  }}
                  cardShow={cardShow}
                  index={ind}
                  testId={id}
                  key={ind}
                />
              ) : (
                <QuestionCard
                  question={question.question}
                  index={ind}
                  key={ind}
                  testQuestion={true}
                  cardShow={cardShow}
                  setCardShow={(questionId) => {
                    setCardShow((prev) => ({
                      [questionId]: !prev[questionId], // Toggle current card
                    }));
                  }}
                />
              )
            )}
          </div>
          {questionBank?.total > limit && (
            <PaginationButtons
              dataLength={questionBank?.questions?.length}
              total={questionBank?.total}
              pathname={"/company/question-bank"}
              asyncThunk={searchQuestionsAsyncThunk}
              asyncThunkData={{ limit: +limit, ...questionFilters }}
            />
          )}
        </div>
      </div>
      {/* // </DashboardLayout> */}
    </>
  );
}
