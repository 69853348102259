import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const RadioComponent = ({ option, title, defaultValue, name, onClick, formik }) => {
    console.log("defaultValue", defaultValue)
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={formik.values.status}
        name={name}
        onClick={onClick}
      >
        {option.map((item) => {
          return <FormControlLabel value={item.value} control={<Radio />} label={item.label} />            
        })}
        {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
      </RadioGroup>
    </FormControl>
  );
};
export default RadioComponent;
