import DownloadSvg from "@mui/icons-material/Download";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { excelStepThreeFields } from "../../constants/addQuestionFields";
import TextFieldDropdown from "../Dropdown/Index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../../redux/slices/dropdowns/dropdownSlice";
import { useNavigate } from "react-router-dom";
import { getIn, useFormik } from "formik";
import DynamicEditor from "../tinyEditor/Index";
import {
  bulkQuestionSchema,
  questionSchema,
} from "../../utils/yup/questionSchema.ts";
import { addBulkQuestionsAsyncThunk } from "../../redux/slices/package/packageSlice";
import QuestionSectionHeading from "../QuestionSectionHeading/Index";
import OptionSelect from "../OptionSelect/Index";
import DeleteSvg from "@mui/icons-material/DeleteOutline";
import ExcelOptionSelect from "../ExcelOptionSelect/Index.jsx";

export default function ExcelStepThree({ activeStep, setActiveStep }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fields, setFields] = useState([]);
  const [correctOptionId, setCorrectOptionId] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const excelQuestions = useSelector((store) => store.packages.excelQuestion);
  const loading = useSelector(
    (store) => store.packages.loadings.addBulkQuestionsAsyncThunk
  );

  useEffect(() => {
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);
  //new code

  const questionsPerPage = 10;

  useEffect(() => {
    const values = excelStepThreeFields(examTypes, subjects);
    setFields(values);
  }, [examTypes, subjects]);

  const initialValues = excelQuestions;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: bulkQuestionSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      console.log("values", values)
      dispatch(
        addBulkQuestionsAsyncThunk({
          data: values,
          action,
          setCorrectOptionId,
          navigate,
        })
      );
      // }
    },
  });

  useEffect(() => {
    excelQuestions?.questions?.forEach((ques, ind) => {
      ques?.options?.forEach((op, i) => {
        if (op?.is_correct == "true") {
          setCorrectOptionId((pre) => [
            ...pre,
            `questions[${ind}].options[${i}].is_correct`,
          ]);
        }
      });
    });
  }, []);
  // Pagination logic: Calculate current page questions
  // const totalQuestions = values.questions?.length || 0;
  // const startIndex = currentPage * questionsPerPage;
  // const endIndex = startIndex + questionsPerPage;
  // const currentQuestions = values.questions?.slice(startIndex, endIndex) || [];

  // const totalPages = Math.ceil(values.questions?.length / questionsPerPage);
  // const handleNext = () => {
  //   if (currentIndex + questionsPerPage < totalQuestions) {
  //     setCurrentIndex((prev) => prev + questionsPerPage);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex((prev) => prev - questionsPerPage);
  //   }
  // };
  const totalQuestions = values.questions?.length || 0;
  const startIndex = currentIndex;
  const endIndex = Math.min(startIndex + questionsPerPage, totalQuestions);
  const currentQuestions = values.questions?.slice(startIndex, endIndex) || [];

  // Handlers for pagination
  const handleNext = () => {
    if (endIndex < totalQuestions) {
      setCurrentIndex((prev) => prev + questionsPerPage);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - questionsPerPage);
    }
  };

  return (
    <div
      className="w-full max-w-[1000px] mx-auto p-10 bg-white rounded-[10px] mt-20"
      style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
    >
      <div className="grid grid-cols-1 gap-5">
        {fields?.map((filter) => {
          if (filter.select) {
            return (
              <div key={filter.id}>
                <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                  {filter.inputLabel}
                </InputLabel>
                <TextFieldDropdown
                  id={`questions[0][${filter.id}]`}
                  options={filter.options}
                  value={values.questions[0][filter.id]}
                  disablePlaceholder={true}
                  placeholder={filter.placeholder}
                />
              </div>
            );
          } else {
            return (
              <div key={filter.id}>
                <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                  {filter.inputLabel}
                </InputLabel>
                <TextField
                  fullWidth
                  id={`questions[0][${filter.id}]`}
                  placeholder={filter.placeholder}
                  value={values.questions[0][filter.id]}
                />
              </div>
            );
          }
        })}
        <div>
          <Button
            variant="contained"
            color="primary"
            className="text-xs font-semibold leading-6 py-1.5 px-2.5 normal-case"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Save All Question"}
          </Button>
        </div>
      </div>
      <div className="flex gap-3 justify-between mt-10">
        <div className="col-span-2 max-w-max flex justify-center gap-5">
          <div>
            <Button
              variant="contained"
              color="secondary"
              className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
              type="button"
              disabled={currentIndex == 0}
              onClick={handlePrevious}
            >
              Previous
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
              type="button"
              onClick={handleNext}
              disabled={endIndex >= totalQuestions}
            >
              Next
            </Button>
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className="leading-5 text-sm font-bold font-poppins capitalize py-1 px-[10px] bg-[#FB3311]"
            type="button"
          >
            {/* Questions {currentIndex + 1} of {values.questions.length} */}
            Questions {startIndex + 1} to {endIndex} of {totalQuestions}
          </Button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-10 my-10 border-t border-solid border-[#202020CC] pt-10"
      >
        {currentQuestions.length > 0 ? (
          currentQuestions.map((question, currentIndex) => (
            <div
              key={currentIndex}
              className="border border-purple-300 rounded-md p-4 mb-4" // Complete border around the question
            >
              <div className="flex items-center mb-2 justify-center">
                <span
                  className="bg-purple-200 text-gray-700 font-bold py-1 px-3 rounded-full mr-2" // Highlighted title
                  style={{ width: "fit-content" }}
                >
                  Question {startIndex + currentIndex + 1}
                </span>
                <DeleteSvg
                  className="float-end text-red-500 w-7 h-7 cursor-pointer"
                  onClick={() => {
                    if (values.questions?.length > 1) {
                      const updatedQuestions = [...values.questions];
                      updatedQuestions.splice(startIndex + currentIndex, 1);
                      setFieldValue("questions", updatedQuestions);
                    } else {
                      navigate("/company/question-bank");
                    }
                  }}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-7">
                <div>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Topic
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={`questions[${currentIndex}].topic`}
                    placeholder={"Enter a placeholder"}
                    value={values.questions[currentIndex].topic}
                  />
                </div>
                <div>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Subtopic
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={`questions[${currentIndex}].subtopic`}
                    placeholder={"Enter a placeholder"}
                    value={values.questions[currentIndex].subtopic}
                  />
                </div>
              </div>

              <DynamicEditor
                id={`questions[${startIndex + currentIndex}].question_text`}
                value={question.question_text}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
              {touched.question_text && errors.question_text && (
                <p className="text-[#d32f2f] text-xs">{errors.question_text}</p>
              )}

              <p className="italic bg-[#202020] w-max p-3 bg-opacity-5 my-2">
                Question Level Information For Reporting
              </p>
              <div className="my-7">
                <QuestionSectionHeading
                  index={2}
                  heading={"Add your multiple choice answer options"}
                  paragraph={
                    "Test takers will select between these answer options."
                  }
                />
              </div>
              {question.options.map((option, ind) => (
                <ExcelOptionSelect
                  key={ind}
                  id={option}
                  index={ind}
                  questionIndex={startIndex + currentIndex}
                  option={option}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  error={Boolean(
                    getIn(
                      touched,
                      `questions[${
                        startIndex + currentIndex
                      }].options[${ind}].option_text`
                    ) &&
                      getIn(
                        errors,
                        `questions[${
                          startIndex + currentIndex
                        }].options[${ind}].option_text`
                      )
                  )}
                  helperText={
                    getIn(
                      touched,
                      `questions[${
                        startIndex + currentIndex
                      }].options[${ind}].option_text`
                    ) &&
                    getIn(
                      errors,
                      `questions[${
                        startIndex + currentIndex
                      }].options[${ind}].option_text`
                    )
                  }
                  correctOptionId={correctOptionId}
                  setCorrectOptionId={setCorrectOptionId}
                  questionType={"MCQ"}
                />
              ))}

              <QuestionSectionHeading
                index={3}
                heading={"Explanation"}
                paragraph={""}
              />
              <div className="ml-9">
                <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
                  Explaination
                </InputLabel>
                <TextField
                  fullWidth
                  multiline={true}
                  rows={3}
                  id={`questions[${startIndex + currentIndex}].explanation`}
                  className="max-w-[510px]"
                  value={
                    values.questions[startIndex + currentIndex].explanation
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(
                    getIn(
                      touched,
                      `questions[${startIndex + currentIndex}].explanation`
                    ) &&
                      getIn(
                        errors,
                        `questions[${startIndex + currentIndex}].explanation`
                      )
                  )}
                  helperText={
                    getIn(
                      touched,
                      `questions[${startIndex + currentIndex}].explanation`
                    ) &&
                    getIn(
                      errors,
                      `questions[${startIndex + currentIndex}].explanation`
                    )
                  }
                />
              </div>
            </div>
          ))
        ) : (
          <p>No questions available</p>
        )}

        <div>
          <Button
            variant="contained"
            color="primary"
            className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Save Questions"}
          </Button>
        </div>
      </form>
    </div>
  );
}

//colured data

{
  /* <form
  onSubmit={handleSubmit}
  className="flex flex-col gap-10 my-10 border-t border-solid border-[#202020CC] pt-10"
>
  {currentQuestions.length > 0 ? (
    currentQuestions.map((question, currentIndex) => (
      <div 
        key={currentIndex}
        className="bg-purple-100 p-4 mb-4 rounded-md" // Light purple background with padding and margin
      >
        <div className="flex items-center mb-2">
          <span
            className="bg-gray-200 text-gray-700 font-bold py-1 px-3 rounded-full mr-2"
            style={{ width: "fit-content" }}
          >
            Question {startIndex + currentIndex + 1}
          </span>
          <DeleteSvg
            className="float-end text-red-500 w-7 h-7 cursor-pointer"
            onClick={() => {
              if (values.questions?.length > 1) {
                const updatedQuestions = [...values.questions];
                updatedQuestions.splice(startIndex + currentIndex, 1);
                setFieldValue("questions", updatedQuestions);
              } else {
                navigate("/company/question-bank");
              }
            }}
          />
        </div>

        <DynamicEditor
          id={`questions[${startIndex + currentIndex}].question_text`}
          value={question.question_text}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
        />
        {touched.question_text && errors.question_text && (
          <p className="text-[#d32f2f] text-xs">{errors.question_text}</p>
        )}
        <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
          Question Level Information For Reporting
        </p>

        <QuestionSectionHeading
          index={2}
          heading={"Add your multiple choice answer options"}
          paragraph={"Test takers will select between these answer options."}
        />
        {question.options.map((option, ind) => (
          <ExcelOptionSelect
            key={ind}
            id={option}
            index={ind}
            questionIndex={startIndex + currentIndex}
            option={option}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            error={Boolean(
              getIn(
                touched,
                `questions[${startIndex + currentIndex}].options[${ind}].option_text`
              ) &&
              getIn(
                errors,
                `questions[${startIndex + currentIndex}].options[${ind}].option_text`
              )
            )}
            helperText={
              getIn(
                touched,
                `questions[${startIndex + currentIndex}].options[${ind}].option_text`
              ) &&
              getIn(
                errors,
                `questions[${startIndex + currentIndex}].options[${ind}].option_text`
              )
            }
            correctOptionId={correctOptionId}
            setCorrectOptionId={setCorrectOptionId}
            questionType={"MCQ"}
          />
        ))}

        <QuestionSectionHeading
          index={3}
          heading={"Explanation"}
          paragraph={""}
        />
        <div className="ml-9">
          <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
            Correctly answered
          </InputLabel>
          <TextField
            fullWidth
            id={`questions[${startIndex + currentIndex}].explanation`}
            className="max-w-[510px]"
            value={values.questions[startIndex + currentIndex].explanation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              getIn(touched, `questions[${startIndex + currentIndex}].explanation`) &&
              getIn(errors, `questions[${startIndex + currentIndex}].explanation`)
            )}
            helperText={
              getIn(touched, `questions[${startIndex + currentIndex}].explanation`) &&
              getIn(errors, `questions[${startIndex + currentIndex}].explanation`)
            }
          />
        </div>
      </div>
    ))
  ) : (
    <p>No questions available</p>
  )}

  <div>
    <Button
      variant="contained"
      color="primary"
      className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
      type="submit"
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} /> : "Save Questions"}
    </Button>
  </div>
</form> */
}

// <div
// className="w-full max-w-[1000px] mx-auto p-10 bg-white rounded-[10px] mt-20"
// style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
// >
// <div className="grid grid-cols-1 gap-5">
//   {fields?.map((filter) => {
//     if (filter.select) {
//       return (
//         <div key={filter.id}>
//           <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//             {filter.inputLabel}
//           </InputLabel>
//           <TextFieldDropdown
//             id={`questions[0][${filter.id}]`}
//             options={filter.options}
//             value={values.questions[0][filter.id]}
//             disablePlaceholder={true}
//             placeholder={filter.placeholder}
//           />
//         </div>
//       );
//     } else {
//       return (
//         <div key={filter.id}>
//           <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//             {filter.inputLabel}
//           </InputLabel>
//           <TextField
//             fullWidth
//             id={`questions[0][${filter.id}]`}
//             placeholder={filter.placeholder}
//             value={values.questions[0][filter.id]}
//           />
//         </div>
//       );
//     }
//   })}
//   <div>
//     <Button
//       variant="contained"
//       color="primary"
//       className="text-xs font-semibold leading-6 py-1.5 px-2.5 normal-case"
//       type="submit"
//       onClick={handleSubmit}
//       disabled={loading}
//     >
//       {loading ? <CircularProgress size={24} /> : "Save All Question"}
//     </Button>
//   </div>
// </div>
// <div className="flex gap-3 justify-between mt-10">
//   <div className="col-span-2 max-w-max flex justify-center gap-5">
//     <div>
//       <Button
//         variant="contained"
//         color="secondary"
//         className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
//         type="button"
//         disabled={currentIndex == 0}
//         onClick={() => setCurrentIndex((pre) => pre - 1)}
//       >
//         Previous
//       </Button>
//     </div>
//     <div>
//       <Button
//         variant="contained"
//         color="primary"
//         className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
//         type="button"
//         onClick={() => setCurrentIndex((pre) => pre + 1)}
//         disabled={currentIndex + 1 == values?.questions?.length}
//       >
//         Next
//       </Button>
//     </div>
//   </div>
//   <div>
//     <Button
//       variant="contained"
//       color="primary"
//       className="leading-5 text-sm font-bold font-poppins capitalize py-1 px-[10px] bg-[#FB3311]"
//       type="button"
//     >
//       Questions {currentIndex + 1} of {values.questions.length}
//     </Button>
//   </div>
// </div>
// {/* <form
//   onSubmit={handleSubmit}
//   className="flex flex-col gap-10 my-10 border-t border-solid border-[#202020CC] pt-10"
// >
//   <div>
//     <DeleteSvg
//       className="float-end text-red-500 w-7 h-7 cursor-pointer"
//       onClick={() => {
//         if (values.questions?.length > 1) {
//           const updatedQuestions = [...values.questions];
//           updatedQuestions.splice(currentIndex, 1);
//           setFieldValue("questions", updatedQuestions);
//         } else {
//           navigate("/company/question-bank");
//         }
//       }}
//     />
//     <QuestionSectionHeading
//       index={1}
//       heading={"Write Your Question"}
//       paragragh={"Enter your question only, without answers."}
//     />
//   </div>
//   <div>
//     <DynamicEditor
//       id={`questions[${currentIndex}].question_text`}
//       value={values.questions[currentIndex].question_text}
//       setFieldValue={setFieldValue}
//       onBlur={handleBlur}
//     />
//     {touched.question_text && errors.question_text && (
//       <p className="text-[#d32f2f] text-xs">{errors.question_text}</p>
//     )}
//   </div>
//   <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
//     Question Level Information For Reporting{" "}
//   </p>
//   <QuestionSectionHeading
//     index={2}
//     heading={"Add your multiple choice answer options"}
//     paragragh={"Test takers will select between these answer options."}
//   />
//   {values.questions[currentIndex].options.map((option, ind) => (
//     <ExcelOptionSelect
//       key={ind}
//       id={option}
//       index={ind}
//       questionIndex={currentIndex}
//       option={option}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       setFieldValue={setFieldValue}
//       error={Boolean(
//         getIn(
//           touched,
//           `questions[${currentIndex}].options[${ind}].option_text`
//         ) &&
//           getIn(
//             errors,
//             `questions[${currentIndex}].options[${ind}].option_text`
//           )
//       )}
//       helperText={
//         getIn(
//           touched,
//           `questions[${currentIndex}].options[${ind}].option_text`
//         ) &&
//         getIn(
//           errors,
//           `questions[${currentIndex}].options[${ind}].option_text`
//         )
//       }
//       correctOptionId={correctOptionId}
//       setCorrectOptionId={setCorrectOptionId}
//       questionType={"MCQ"}
//     />
//   ))}
//   <QuestionSectionHeading
//     index={3}
//     heading={"Explanation"}
//     paragragh={""}
//   />
//   <div className="ml-9">
//     <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
//       Correctly answered
//     </InputLabel>
//     <TextField
//       fullWidth
//       id={`questions[${currentIndex}].explanation`}
//       className="max-w-[510px]"
//       value={values.questions[currentIndex].explanation}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       error={Boolean(
//         getIn(touched, `questions[${currentIndex}].explanation`) &&
//           getIn(errors, `questions[${currentIndex}].explanation`)
//       )}
//       helperText={
//         getIn(touched, `questions[${currentIndex}].explanation`) &&
//         getIn(errors, `questions[${currentIndex}].explanation`)
//       }
//     />
//   </div>
//   <div>p
//     <Button
//       variant="contained"
//       color="primary"
//       className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
//       type="submit"
//       disabled={loading}
//     >
//       {loading ? <CircularProgress size={24} /> : "Save Question"}
//     </Button>
//   </div>
// </form> */}

// <form
// onSubmit={handleSubmit}
// className="flex flex-col gap-10 my-10 border-t border-solid border-[#202020CC] pt-10"
// >
// {currentQuestions.length > 0 ? (
// currentQuestions.map((question, currentIndex) => (
// <div key={currentIndex}>
//   <div>
//     <DeleteSvg
//       className="float-end text-red-500 w-7 h-7 cursor-pointer"
//       onClick={() => {
//         if (values.questions?.length > 1) {
//           const updatedQuestions = [...values.questions];
//           updatedQuestions.splice(startIndex + currentIndex, 1);
//           setFieldValue("questions", updatedQuestions);
//         } else {
//           navigate("/company/question-bank");
//         }
//       }}
//     />
//     <QuestionSectionHeading
//       index={1}
//       heading={"Write Your Question"}
//       paragraph={"Enter your question only, without answers."}
//     />
//   </div>
//   <div>
//     <DynamicEditor
//       id={`questions[${startIndex + currentIndex}].question_text`}
//       value={question.question_text}
//       setFieldValue={setFieldValue}
//       onBlur={handleBlur}
//     />
//     {touched.question_text && errors.question_text && (
//       <p className="text-[#d32f2f] text-xs">{errors.question_text}</p>
//     )}
//   </div>
//   <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
//     Question Level Information For Reporting
//   </p>
//   <QuestionSectionHeading
//     index={2}
//     heading={"Add your multiple choice answer options"}
//     paragraph={"Test takers will select between these answer options."}
//   />
//   {question.options.map((option, ind) => (
//     <ExcelOptionSelect
//       key={ind}
//       id={option}
//       index={ind}
//       questionIndex={startIndex + currentIndex}
//       option={option}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       setFieldValue={setFieldValue}
//       error={Boolean(
//         getIn(
//           touched,
//           `questions[${startIndex + currentIndex}].options[${ind}].option_text`
//         ) &&
//         getIn(
//           errors,
//           `questions[${startIndex + currentIndex}].options[${ind}].option_text`
//         )
//       )}
//       helperText={
//         getIn(
//           touched,
//           `questions[${startIndex + currentIndex}].options[${ind}].option_text`
//         ) &&
//         getIn(
//           errors,
//           `questions[${startIndex + currentIndex}].options[${ind}].option_text`
//         )
//       }
//       correctOptionId={correctOptionId}
//       setCorrectOptionId={setCorrectOptionId}
//       questionType={"MCQ"}
//     />
//   ))}
//   <QuestionSectionHeading
//     index={3}
//     heading={"Explanation"}
//     paragraph={""}
//   />
//   <div className="ml-9">
//     <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
//       Correctly answered
//     </InputLabel>
//     <TextField
//       fullWidth
//       id={`questions[${startIndex + currentIndex}].explanation`}
//       className="max-w-[510px]"
//       value={values.questions[startIndex + currentIndex].explanation}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       error={Boolean(
//         getIn(touched, `questions[${startIndex + currentIndex}].explanation`) &&
//         getIn(errors, `questions[${startIndex + currentIndex}].explanation`)
//       )}
//       helperText={
//         getIn(touched, `questions[${startIndex + currentIndex}].explanation`) &&
//         getIn(errors, `questions[${startIndex + currentIndex}].explanation`)
//       }
//     />
//   </div>
// </div>
// ))
// ) : (
// <p>No questions available</p>
// )}

// <div className="flex justify-between">
// <Button
// variant="contained"
// disabled={currentPage === 0}
// onClick={() => setCurrentPage(currentPage - 1)}
// >
// Previous
// </Button>
// <Button
// variant="contained"
// disabled={currentPage + 1 === totalPages}
// onClick={() => setCurrentPage(currentPage + 1)}
// >
// Next
// </Button>
// </div>

// <div>
// <Button
// variant="contained"
// color="primary"
// className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
// type="submit"
// disabled={loading}
// >
// {loading ? <CircularProgress size={24} /> : "Save Questions"}
// </Button>
// </div>
// </form>
// </div>
