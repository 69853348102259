import { useEffect, useRef } from "react";

const DynamicEditor = ({
  id,
  value,
  setFieldValue,
  onBlur,
  questionType,
  error,
}) => {
  const refdiv = useRef(null);
  const rteRef = useRef(null); // Store rte instance

  window.rte_file_upload_handler = async function (
    file,
    callback,
    optionalIndex,
    optionalFiles
  ) {
    const url = await handleImageUpload(file);
    if (url) callback(url); // Pass the URL to the editor callback
  };

  useEffect(() => {
    if (refdiv.current && !rteRef.current) {
      rteRef.current = new window.RichTextEditor(refdiv.current);
      rteRef.current.setHTMLCode(value); // Initialize with value

      rteRef.current.attachEvent(
        "exec_command_insertfile",
        (state, cmd, value) => {
          state.returnValue = true;
          console.log("File upload triggered");
        }
      );

      rteRef.current.attachEvent("change", () => {
        const htmlContent = rteRef.current.getHTMLCode();
        if (questionType === "TrueFalse") {
          
          setFieldValue(id, value);
        } else {
          setFieldValue(id, htmlContent);
        }
      });
    }
  }, []);

  // Reset editor when value is updated
  useEffect(() => {
    if (rteRef.current && value === "") {
      rteRef.current.setHTMLCode(""); // Reset editor when value is empty
    }
  }, [value]);

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      // Replace this URL with your API endpoint for image uploads
      const response = await fetch("https://admin.smartexams.com.au/api/images/upload", {
        method: "POST",
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        return data.path; // Return the uploaded image URL
      } else {
        console.error("Image upload failed:", response.statusText);
        return null;
      }
    } catch (err) {
      console.error("Error uploading file: ", err);
      return null;
    }
  };

  return <div ref={refdiv} />;
};

export default DynamicEditor;
