import React from "react";
import { ReactComponent as DropdownChevronSvg } from "../../assests/icons/dropdown-arrow.svg";
import { MenuItem, TextField } from "@mui/material";

export default function TablePerPage({ options, value, onChange, id, sx }) {
  return (
    <div className="flex gap-1 items-center my-2">
      <p className="text-[#202020] font-poppins leading-4 text-[10px]">Show</p>
      <TextField
        id={id}
        variant="outlined"
        color="primary"
        name={id}
        select
        value={value}
        onChange={onChange}
        SelectProps={{
          style: {
            color: "#202020B2",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "24px",
          },
          IconComponent: DropdownChevronSvg,
        }}
        sx={{
          "& .MuiSelect-icon": {
            top: "calc(50% - 0.4em)",
            right: "15px",
          },
          ".MuiInputBase-root": {
            "& .MuiInputBase-input": {
              paddingY: "8px",
            },
          },
          ...sx,
        }}
      >
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <p className="text-[#202020] font-poppins leading-4 text-[10px]">
        entries
      </p>
    </div>
  );
}
