import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Radio,
  TextField,
} from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index";
import { lazy, Suspense } from "react";
const DynamicEditor = lazy(() => import("../tinyEditor/Index"));

export default function ComprehensionOptionSelect({
  ind,
  index,
  option,
  onChange,
  setFieldValue,
  onBlur,
  error,
  helperText,
  correctOptionId,
  setCorrectOptionId,
  questionType,
}) {
  const alphabet =
    index == 0
      ? "A"
      : index == 1
      ? "B"
      : index == 2
      ? "C"
      : index == 3
      ? "D"
      : index == 4
      ? "E"
      : index == 5
      ? "F"
      : null;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <p className="text-[#B6B6B6] border border-[#B6B6B6] rounded-full w-[21px] h-[22px] flex justify-center items-center font-semibold">
            {alphabet}
          </p>
          <FormControlLabel
            control={
              <Radio
                id={`questions[${ind}].options[${index}].is_correct`}
                checked={
                  correctOptionId.includes(
                    `questions[${ind}].options[${index}].is_correct`
                  )
                    ? true
                    : false
                }
                color="secondary"
                // checkedIcon={<Checked className="flex-shrink-0" />}
                // icon={<Unchecked className="flex-shrink-0" />}
                onChange={(e) => {
                  const found = correctOptionId.findIndex(
                    (el) => el.slice(0, 12) === `questions[${ind}]`
                  );
                  if (found !== -1) {
                    // Create a copy of correctOptionId before modifying it
                    const newArray = [...correctOptionId];
                    newArray.splice(
                      found,
                      1,
                      `questions[${ind}].options[${index}].is_correct`
                    );
                    setCorrectOptionId(newArray);
                    setFieldValue(correctOptionId[found], "false");
                  } else {
                    setCorrectOptionId([
                      ...correctOptionId,
                      `questions[${ind}].options[${index}].is_correct`,
                    ]);
                  }

                  setFieldValue(
                    `questions[${ind}].options[${index}].is_correct`,
                    "true"
                  );
                }}
                value={option.is_correct}
                className="ml-3 p-0"
              />
            }
            label={
              <span className="text-[#202020] font-xs font-medium ml-2">
                Set as correct answer
              </span>
            }
          />
        </div>
        <p className="text-[#202020] font-xs font-medium">{index < 4 ? "Mandatory" : "Optional"}</p>
      </div>
      <div className="my-3">
        <Suspense fallback={"Loading..."}>
          <DynamicEditor
            id={`questions[${ind}].options[${index}].answer_text`}
            value={option.answer_text}
            setFieldValue={setFieldValue}
            onBlur={onBlur}
            height={150}
            questionType={questionType}
          />
        </Suspense>
        {error && <p className="text-[#d32f2f] text-xs">{helperText}</p>}
      </div>
      <div className="my-3 w-full sm:max-w-[227px]">
        <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
          Is image present in choice {alphabet}?
        </InputLabel>
        <TextFieldDropdown
          id={`questions[${ind}].options[${index}].is_image_present`}
          name={`questions[${ind}].options[${index}].is_image_present`}
          options={[
            { name: "No", value: "false" },
            { name: "Yes", value: "true" },
          ]}
          onChange={onChange}
          value={option.is_image_present}
        />
      </div>
    </div>
  );
}
