import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#8C52FF",
    },
    secondary: {
      main: "#525252",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#202020B2",
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "Poppins",
            lineHeight: "24px",
          },
          color: "#202020B2",
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: "Poppins",
          lineHeight: "24px",
        },
      },
    },
  },
});
