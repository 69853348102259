import { Button, TextField, Typography, Box } from "@mui/material";
import { useFormik } from "formik";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";

const AddExamConducted = ({ fun, onHide, examConducted, setExamConducted }) => {
  const initialValues = {
    name: examConducted?.name || "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const response = examConducted
        ? await ApiRequest.updateExamConducted(values, examConducted?.id)
        : await ApiRequest.createExamConducted(values);
      if (response.status === 201) {
        toast.success("Successfully created");
        fun();
        onHide();
      }
      if (response.status === 200) {
        toast.success("Update Successfully");
        setExamConducted(null);
        fun();
        onHide();
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "30vh",
        backgroundColor: "#f4f4f4",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Typography
          sx={{ fontWeight: "500", fontSize: "20px", marginBottom: 2 }}
          variant="h5"
        >
          {examConducted ? "Edit Exam Conducted By" : "Add Exam Conducted By"}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: 2,
              paddingY: 1.2,
              fontWeight: "bold",
            }}
          >
            {examConducted ? "Edit Exam Conducted By" : "Add Exam Conducted By"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default AddExamConducted;
