import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoute() {
  const { user } = useSelector((store) => store.auth);
  // if (!user) {
  //   return <Navigate to="/signin" />;
  // }
  return <Outlet />;
}
