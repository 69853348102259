import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import PromoCodesTable from "../components/PromoCodesTable";
import { Button } from "@mui/material";
import { ReactComponent as EditSvg } from "../assests/icons/edit.svg";

import { ReactComponent as DeleteSvg } from "../assests/icons/delete.svg";
import AddPromoCodeModal from "../pages/add-promocode";
import { useDispatch, useSelector } from "react-redux";
import { getDiscount } from "../redux/slices/Master/masterSlice";
import TestimonialsTable from "../components/TestimonialsTable";
import { useNavigate } from "react-router-dom";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import { authorizatonCommonFun } from "../utils/basicService";

export default function PromoCodes() {
  const [open, setOpen] = useState(false);
  const { discount } = useSelector((state) => state?.master);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState({});
  const [limit, setLimit] = useState(10);
const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      page,
      limit,
    };
    dispatch(getDiscount(data));
  }, [page]);

  useEffect(() => {
    console.log("discount", discount);
  }, [discount]);

  const columns = [
    { name: "Code", value: "code" },
    { name: "Value", value: "value" },
    { name: "Description", value: "description" },
    { name: "Status", value: "is_expired", },
    { name: "Start Date", value: "startDate", type: "date" },
    { name: "End Date", value: "endDate", type: "date" },
  ];

  const action = [
    {
      label: <EditSvg className="cursor-pointer" />,
      action: (testimonial) => {
        setEditData(testimonial);
        setOpen(true);
        // navigate(`/company/edit-testimonial/${testimonial.id}`);
      }
    },
    {
      label: <DeleteSvg className="cursor-pointer" />,
      action: (testimonial) => {handleDelete(testimonial.id)}
    }

 ]

 useEffect(() => {
   console.log("editData", editData)
 }, [editData])

  const [promoCodes, setPromoCodes] = useState([
    {
      id: 1,
      code: "SAVE10",
      value: "$10",
      description: "Get 10% off on your purchase",
      status: "Active",
    },
    {
      id: 2,
      code: "NEW20",
      value: "$20",
      description: "Get 20% off on your purchase",
      status: "Inactive",
    },
    // Add more promo codes as needed
  ]);

  // Handle delete promo code
  const handleDelete = async (id) => {
   try {
    const response = await ApiRequest.deleteDiscount(id);
    if(response.status===200) {
      toast.success("Discount deleted successfully");
       const data = {
        page,
        limit
       }
      dispatch(getDiscount(data));
    }
  } catch (e) {
    authorizatonCommonFun(e);
    console.log("Error", e);
    toast.error(e.response.data.message);
  }
  };

  // Handle update promo code
  const handleUpdate = (updatedPromoCode) => {
    setPromoCodes((prev) =>
      prev.map((promoCode) =>
        promoCode.id === updatedPromoCode.id ? updatedPromoCode : promoCode
      )
    );
  };

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Promo Codes</h1>
      <div className="mt-10 max-w-[900px] mx-auto">
        <div className="flex justify-end mb-5">
          <Button
            variant="contained"
            color="primary"
            className="rounded-none leading-6 text-xs font-semibold font-poppins"
            startIcon={<PlusSvg />}
            onClick={() => setOpen(true)}
          >
            Add Promo Code
          </Button>
        </div>
        {/* Pass the handleDelete and handleUpdate methods */}
        <TestimonialsTable
          columns={columns}
          setPagee={setPage}
          setPageLimit={setLimit}
          actions={action}
          data={discount}
          handleDelete={handleDelete}
          handleEdit={handleUpdate}
        />
        {/* <PromoCodesTable 
          promoCodes={promoCodes} 
          handleDelete={handleDelete} 
          handleUpdate={handleUpdate} 
        /> */}
        <AddPromoCodeModal open={open} setOpen={setOpen} id={editData?.id} data={editData} fun={() => dispatch(getDiscount())} />
      </div>
    </DashboardLayout>
  );
}
