import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
// import DragDropBox from "../components/DragDropBox";
import { getTestQuestionAsyncThunk } from "../redux/slices/test/testSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import { authorizatonCommonFun } from "../utils/basicService";
import DragDropBox from "../components/DragDropBox/index.js";

const QuestionDragDrop = () => {
  const [myComponents, setMyComponents] = useState([]);
  const questionBank = useSelector((store) => store.tests?.getTestQuestion);
  const { id } = useParams();
  const dispatch = useDispatch();

  // Fetch data on mount and when `id` changes
  useEffect(() => {
    const data = { page: 1, test_id: id,
      orderBy: [`testQuestion.position`], order: 'ASC'
       };
    dispatch(getTestQuestionAsyncThunk(data));
  }, [dispatch, id]);

  // Initialize components from questionBank
  useEffect(() => {
    if (questionBank?.results?.length > 0) {
      const components = questionBank.results.map((item, index) => ({
        ...item,
        testQuestionId: item.id,
        position: item.position || index, // Ensure a valid position value
        questionType: item.question.question_type,
      }));
      components.sort((a, b) => a.position - b.position); // Sort by position
      setMyComponents(components);
    }
  }, [questionBank]);

  // Handle drag-and-drop stop event
  const handleDragStop = (draggedIndex, newPosition) => {
    if (!myComponents || myComponents.length === 0) return;

    const updatedComponents = [...myComponents];
    const [draggedComponent] = updatedComponents.splice(draggedIndex, 1);

    // Insert the dragged component at the new position
    updatedComponents.splice(newPosition, 0, draggedComponent);

    // Update positions
    updatedComponents.forEach((comp, idx) => {
      comp.position = idx;
    });

    // setMyComponents(updatedComponents);
    updatePosition(updatedComponents);
  };

  // Update positions on the server
  const updatePosition = async (data) => {
    try {
      const payload = data.map((comp) => ({
        testQuestionId: comp.testQuestionId,
        position: comp.position,
      }));

      const response = await ApiRequest.updatePositionAssignQuestion(payload);
        //  console.log("response", response);
         
      // setMyComponents(response?.results);
      // Refresh data after update
      const refreshData = { page: 1, test_id: id,
       orderBy: [`testQuestion.position`], order: 'ASC'
      };
      dispatch(getTestQuestionAsyncThunk(refreshData));
    } catch (error) {
      console.error("Error updating position:", error);
      toast.error(error.response?.data?.message || "An error occurred");
      authorizatonCommonFun(error);
    }
  };

  return (
    <DashboardLayout>
      <div
        className="bg-white rounded-lg mt-[26px]"
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        {myComponents.length > 0 &&
          myComponents.map((item, index) => (
            <>
            {console.log("item--->", item)}
            <DragDropBox
              key={item.id}
              background="#e8e8e8"
              title={`Question ${index + 1}`}
              question={item.question}
              index={index}
              onDragStop={(draggedIndex, newPosition) =>
                handleDragStop(draggedIndex, newPosition)
              }
            />
            </>
          ))}
      </div>
    </DashboardLayout>
  );
};

export default QuestionDragDrop;
