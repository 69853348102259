import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { authorizatonCommonFun } from "../../../utils/basicService/index.js";

export const createTestAsyncThunk = createAsyncThunk(
    "auth/createTestAsyncThunk",
    asyncWrapper(async ({ data, action, navigate }, thunk) => {
      try {  
      const response = await ApiRequest.createTest(data);
        if (response.status === 201) {
            toast.success("Test created successfully!");
            if(action) {
              action.resetForm();
            }
              navigate("/company/tests");
        }
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    })
); export const getTestsAsyncThunk = createAsyncThunk(
    "auth/getTestsAsyncThunk",
    asyncWrapper(async (data, thunk) => {
      try {
      const response = await ApiRequest.getTests(data);
        return response.data;
      }
      catch (e) {
        authorizatonCommonFun(e);
      }
    })
);
export const searchTestAsyncThunk = createAsyncThunk(
    "auth/searchTestAsyncThunk",
    asyncWrapper(async (data, thunk) => {
      const response = await ApiRequest.searchTests(data);
      return response.data;
    })
  );
  export const getTestAsyncThunk = createAsyncThunk(
    "auth/getTestAsyncThunk",
    asyncWrapper(async (data, thunk) => {
     try {
      const response = await ApiRequest.getTest(data);
      return response.data;
    } catch (e) {
      toast.error(e.response.data.message);
      authorizatonCommonFun(e);
      console.log("error--->", e);
    }
    })
    
  );

  export const getTestQuestionAsyncThunk = createAsyncThunk(
    "auth/getTestQuestionAsyncThunk",
     asyncWrapper(async (data) => {
     try {
      const response = await ApiRequest.getTestQuestion(data);
      console.log("responsee---->", response)
      return response.data;
    } catch (e) {
      authorizatonCommonFun(e);
    }
      }))
  export const editTestAsyncThunk = createAsyncThunk(
    "auth/editTestAsyncThunk",
    asyncWrapper(async ({ data, id, navigate }, thunk) => {
      const response = await ApiRequest.editTest({ data, id });
      if (response.status === 200) {
        toast.success("Test updated successfully!");
        navigate("/company/Tests");
      }
      return response.data;
    })
  );
  
  export const usedByTestAsyncThunk = createAsyncThunk(
    "auth/usedByTestAsyncThunk",
    asyncWrapper(async (data, thunk) => {
      console.log("data--->", data)
     try {
      const response = await ApiRequest.usedByTest(data);
      console.log("response---->", response);
      return response.data;
      // if (response.status === 200) {
      //   toast.success(response.data.message);
      //   window.location.href="/company/Tests";
      // }
    } catch (e) {
      authorizatonCommonFun(e);
      console.log("Error", e);
      toast.error(e.response.data.message);
    }
    })
  );
  export const DeleteTestAsyncThunk = createAsyncThunk(
    "auth/deleteTestAsyncThunk",
    asyncWrapper(async ({  id, navigate }, thunk) => {
      console.log("id--->", id)
      const response = await ApiRequest.deleteTest(id);
      console.log("response---->", response);
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.href="/company/Tests";
      }
    })
  );
const initialState = {
    tests: {},
    test:{},
    getTestQuestion: [],
    usedByTestData: [],
    packageId: {},
    loadings: {
        createTestAsyncThunk: false,
        getTestsAsyncThunk: false,
        editTestAsyncThunk: false,
        usedByTestAsyncThunk: false
    },
};
export const testSlice = createSlice({
    name: "tests",
    initialState,
    extraReducers: (builder) => {
        builder
            // tests
            .addCase(getTestsAsyncThunk.fulfilled, (state, action) => {
                state.tests = action.payload;
            })
            .addCase(getTestAsyncThunk.fulfilled, (state, action) => {
                state.test = action.payload;
              })
            .addCase(getTestQuestionAsyncThunk.fulfilled, (state, action) => {
              console.log("test questionaction.payload", action.payload)
              state.getTestQuestion = action.payload;
            }) 
            .addCase(usedByTestAsyncThunk.fulfilled, (state, action) => {
              console.log('action.payload--->', action.payload);
              state.usedByTestData = action.payload;
            }) 
            .addMatcher(
                isAnyOf(
                    createTestAsyncThunk,
                    getTestsAsyncThunk

                ),
                handleLoadings
            );
    },
    reducers: {
        setPackageId: (state, action) => {
           state.packageId = action.payload;
        }
    },
});

export default testSlice.reducer;
export const { setPackageId } =
    testSlice.actions;
