import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamConductedByDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice";
import PositionedMenu from "../components/PositionedMenu";
import BasicModal from "../components/Modal";
import EditState from "./edit-state";
import { Button } from "@mui/material";
import AddSubjectModalForm from "./subjectAddModal";
import AddExamConducted from "./add-exam-conducted";

export default function Master() {
  const [activeTab, setActiveTab] = useState("state"); // State for active tab
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const [saveStateData, setStateData] = useState({});
  const [saveSubjecteData, setSaveSubjecteData] = useState(null);
  const [subjectModel, setSubjectModal] = useState(false);
  const [examConductedShow, setExamConductedShow] = useState(false);
  const [saveExamConducted, setSaveExamConducted] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  // Function to switch tabs
  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  const examTypesSelector = useSelector(state => state.dropdown?.examConductedBy);

  useEffect(() => {
   console.log("examTypesSelector", examTypesSelector)
  }, [examTypesSelector])
  
  useEffect(() => {
    dispatch(getStateDropdownAsyncThunk());
  dispatch(getExamConductedByDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, [dispatch]);

  useEffect(() => {
    console.log("examTypesSelector", examTypesSelector);
  }, [examTypesSelector]);

  function toTitleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const handleClick = () => {
  setExamConductedShow(true);
}


  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">{"Master Table"}</h1>
      <div className="p-6 bg-white shadow-md rounded-md">
        <ul className="flex justify-center w-full mx-auto space-x-4 mb-4">
          <li
            className={`tab-item cursor-pointer px-4 py-2 ${
              activeTab === "state"
                ? "border-b-4 border-[#8C52FF] font-bold -mb-[4px] text-black"
                : ""
            }`}
            onClick={() => switchTab("state")}
          >
            {"State"}
          </li>
          <li
            className={`tab-item cursor-pointer px-4 py-2 ${
              activeTab === "subject"
                ? "border-b-4 border-[#8C52FF] font-bold -mb-[4px] text-black"
                : ""
            }`}
            onClick={() => switchTab("subject")}
          >
            Subject
          </li>
          <li
            className={`tab-item cursor-pointer px-4 py-2 ${
              activeTab === "examConductedBy"
                ? "border-b-4 border-[#8C52FF] font-bold -mb-[4px] text-black"
                : ""
            }`}
            onClick={() => switchTab("examConductedBy")}
          >
            Exam Conducted By
          </li>
        </ul>

        {/* Tab Content */}
        <div className="w-full">
          {activeTab === "state" && (
            <div className="tab-content w-full">
              <h2 className="text-2xl font-semibold mb-4">State</h2>
              <div className="overflow-x-auto w-full">
                <table className="min-w-full table-auto border-collapse">
                  <thead>
                    <tr className="">
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        ID
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Name
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Status
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {states &&
                      states.length > 0 &&
                      states.map((item, i) => {
                        return (
                          <>
                            <tr className="">
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {i + 1}
                              </td>
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {item.state_name}
                              </td>
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {item.status&&toTitleCase(item.status)}
                              </td>
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {" "}
                                <PositionedMenu
                                  title={
                                    <MoreVertIcon
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                  option={[
                                    {
                                      id: item,
                                      label: "Edit",
                                      action: (id) => {
                                        setStateData(id);
                                        setOpenModal(true);
                                      },
                                    },
                                  ]}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}

                    {/* <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">2</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        Victoria
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        {" "}
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">3</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        Australian Capital Territory
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        {" "}
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "subject" && (
            <div className="tab-content w-full">
              <h2 className="text-2xl font-semibold mb-4">
                {activeTab === "subject" && (
                  <Button
                    className="btn btn-primary"
                    onClick={() => setSubjectModal(!subjectModel)}
                    style={{ width: "20rem" }}
                  >
                    {"Add Subject"}
                  </Button>
                )}
              </h2>
              <div className="overflow-x-auto w-full">
                <table className="min-w-full table-auto border-collapse">
                  <thead>
                    <tr className="">
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        ID
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Name
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects &&
                      subjects.length > 0 &&
                      subjects.map((item, i) => {
                        return (
                          <>
                            <tr className="">
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {i + 1}
                              </td>
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {item.subject_name}
                              </td>
                              <td className="px-4 border-b-2 text-[#6C757D] py-2">
                                {" "}
                                <PositionedMenu
                                  title={
                                    <MoreVertIcon
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                  option={[
                                    {
                                      id: item,
                                      label: "Edit",
                                      action: (id) => {
                                        // alert(id);
                                        setSaveSubjecteData(id);
                                        setSubjectModal(true);
                                      },
                                    },
                                  ]}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {/* <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">2</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        Verbal Reasoning
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">3</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        Numerical Reasoning
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">3</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        Abstract Reasoning
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "examConductedBy" && (
            <div className="tab-content w-full">
              <h2 className="text-2xl font-semibold mb-4">
                <Button
                  style={{ width: "20rem" }}
                  onClick={handleClick}
                  className="btn btn-primary"
                >
                  {"Add Exam Conducted By"}
                </Button>
              </h2>
              <div className="overflow-x-auto w-full">
                <table className="min-w-full table-auto border-collapse">
                  <thead>
                    <tr className="">
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        ID
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Name
                      </th>
                      <th className="text-left border-b-4 text-[#6C757D] px-4 py-2">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {examTypesSelector && examTypesSelector.length > 0 && examTypesSelector.map((item, i) => {
                      return (<tr>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">{i+1}</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">{item.name}</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                      <PositionedMenu
                                  title={
                                    <MoreVertIcon
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                  option={[
                                    {
                                      id: item,
                                      label: "Edit",
                                      action: (id) => {
                                        // alert(id);
                                        setSaveExamConducted(id);
                                        setExamConductedShow(true);
                                      },
                                    },
                                  ]}
                                />
                      </td>
                      </tr>)
                    })}
                    {/* <tr className="">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">1</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        ACER
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">2</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        EDUTEST
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr className="border-b-2">
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">2</td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        LOCAL SCHOOL
                      </td>
                      <td className="px-4 border-b-2 text-[#6C757D] py-2">
                        <MoreVertIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => setSubjectModal(true)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <BasicModal
         open={examConductedShow}
         handleOpen={() => setExamConductedShow(true)}
         handleClose={() => setExamConductedShow(false)}
         component={<AddExamConducted
           fun={() => dispatch(getExamConductedByDropdownAsyncThunk())}
            onHide={() => setExamConductedShow(!examConductedShow)}
            examConducted={saveExamConducted}
            setExamConducted={setSaveExamConducted}
           />}
      /> 
      <BasicModal
        open={subjectModel}
        handleOpen={() => setSubjectModal(true)}
        handleClose={() => setSubjectModal(!subjectModel)}
        // title={"Add Subject"}
        component={
          <AddSubjectModalForm
            state={saveSubjecteData}
            fun={() => dispatch(getSubjectsDropdownAsyncThunk())}
            onHide={() => setSubjectModal(!subjectModel)}
            setState={setSaveSubjecteData}
          />
        }
      />
      <BasicModal
        open={openModal}
        // title={"Edit State Name"}
        component={
          <EditState
            state={saveStateData}
            onHide={() => setOpenModal(!openModal)}
          />
        }
        handleClose={() => setOpenModal(false)}
      />
    </DashboardLayout>
  );
}
