import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import DynamicEditor from "../components/tinyEditor/Index";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamConductedByDropdownAsyncThunk,
  getExamTpesDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice.js";
import { authorizatonCommonFun } from "../utils/basicService/index.js";
import { useNavigate, useParams } from "react-router-dom";

const EditBook = () => {
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null);
  const { id } = useParams();
  const [coverPreview, setCoverPreview] = useState(null);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const [CoverImage, setCoverImage] = useState(null);

  useEffect(() => {
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
    dispatch(getStateDropdownAsyncThunk());
  }, []);
 const [bookData, setBookData] = useState({});
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, setFieldValue, handleBlur, handleSubmit, handleChange, setValues } =
    useFormik({
      initialValues: {
        description: "",
        author_score: "",
        exam_type_id: "",
        subjectId: "",
        price: "",
        discount: "",
        state_id: "",
        title: "",
        grade: "",
        price_information: "",
      },
      onSubmit: async (values) => {
        console.log("pdfInputRef current---->", pdfInputRef.current.files[0]);
        // if (pdfInputRef.current.files[0] && fileInputRef.current.files[0]) {
          let formdata = new FormData();
          const author = parseInt(values.author_score);
          try {
            // Ensure proper numeric conversion before appending to FormData
            console.log("CoverImage", CoverImage);

            formdata.append(
              "coverImage",
              "https://cdn.hashnode.com/res/hashnode/image/upload/v1571822266210/WoU_msKQk.png?w=1000&h=250&auto=compress,format&format=webp"
            );
            formdata.append("url", "https://bcoder24.s3.ap-south-1.amazonaws.com/eah/a5e5fc2abf9e6ca307038504092f0d5bf59a9637~EAH.pdf")
            formdata.append("title", values.title);
            formdata.append("description", values.description);

            // Convert to number using Number() and fallback to 0 if not a valid number
            formdata.append("author_score", Number(values.author_score) || 0);
            formdata.append("exam_type_id", Number(values.exam_type_id) || 0);
            formdata.append("grade", Number(values.grade) || 0);
            formdata.append("subjectId", Number(values.subjectId) || 0);
            formdata.append("price", Number(values.price) || 0);
            formdata.append("discount", Number(values.discount) || 0);
            formdata.append("price_information", values.price_information);

            // Ensure state_id is a number or fallback to 0
            formdata.append("state_id", Number(values.state_id) || 0);

            // Log the formdata for debugging purposes
            console.log("FormData Contents: ", formdata);

            // Send the request
            try {
              const response = await ApiRequest.updateBook(formdata, id);
              if (response.status === 200) {
                toast.success("Book updated successfully");
                navigate('/company/book');
              } else {
                toast.error("Failed to create the book");
              }
            } catch (error) {
              console.error("Error while submitting form:", error);
              toast.error("An error occurred while creating the book");
            }

        //     const response = await ApiRequest.ZZZZ(formdata, id);

        //     if (response.status === 200) {
        //       toast.success("Book updated");
        //       navigate('/company/book');
        //     }
        //   } catch (e) {
        //     console.log("Error", e);
        //     authorizatonCommonFun(e);
        //     toast.error(e.response.data.message);
        //   }
        // }
        //  else {
        //   toast.error("Please select both the cover image and PDF file");
        // }
      } catch (e) {
            console.log("Error", e);
            authorizatonCommonFun(e);
            toast.error(e.response.data.message);
          }
        }
    });

    const bookGetFun = async (id) => {
        try {
          const response = await ApiRequest.getBookId(id);
          console.log("response data:", response.data); // Inspect the response structure
      
          if (response.status === 200) {
            // Ensure all the required fields are in the response data
            console.log("Fetched book data:", response.data);
       
            const data = response.data;
            setValues({
              title: data.title,
              description: data.description,
              price: data.price,
              exam_type_id: data.exam_type_id,
              author_score: data.author_score,
              subjectId: data.subjectId,
              grade: data.grade,
              state_id: data.state_id,
              discount: data.discount,
              price_information: data.price_information,
            });
      
            // Handle cover image (if applicable)
            if (data.coverImage) {
              setCoverImage(data.coverImage);
              setCoverPreview(data.coverImage);
            }
      
            setBookData(data);
          }
        } catch (e) {
          toast.error("Error fetching book data: " + e.response?.data?.message);
          authorizatonCommonFun(e);
          console.error("Error", e);
        }
      };
      
    
      useEffect(() => {
        bookGetFun(id)
      }, [])

  const handleBrowserCover = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setCoverImage(event.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setCoverPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handlePdfFile = (e) => {
    e.preventDefault();
    pdfInputRef.current.click();
  };

  const [pdfName, setPdfName] = useState(null);
  const handlePdfChange = () => {
    const file = pdfInputRef.current.files[0]; // Get the selected file
    if (file) {
      setPdfName(file.name); // Set the file name in state
    }
  };

  useEffect(() => {
 console.log("bookData", bookData)
  }, [bookData]);

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-6">{"Publish New eBook"}</h2>
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-6"
          onSubmit={handleSubmit}
        >
          {/* Title */}
          <div className="col-span-1">
            <label className="block text-gray-700 font-medium mb-2">
              Title
            </label>
            <input
              type="text"
              placeholder="Enter title"
              name="title"
              onChange={handleChange}
              value={values.title}
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Exam Type */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Exam Type
            </label>
            <select
              name="exam_type_id"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleChange}
              value={values.exam_type_id}
            >
              {examTypes &&
                examTypes.length > 0 &&
                examTypes.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.exam_name}
                  </option>
                ))}
            </select>
          </div>

          {/* Description */}
          <div className="col-span-1 md:col-span-2">
            <label className="block text-gray-700 font-medium mb-2">
              Description
            </label>
            {(bookData && bookData?.description) && (
  <DynamicEditor
    id="description"
    setFieldValue={setFieldValue}
    value={bookData.description}  // Use bookData.description
    onChange={(newValue) => setFieldValue("description", newValue)}
    onBlur={handleBlur}
  />
)
//  : (
//   <DynamicEditor
//     id="description"
//     setFieldValue={setFieldValue}
//     value={values.description}  // Use values.description
//     onChange={(newValue) => setFieldValue("description", newValue)}
//     onBlur={handleBlur}
//   />
// )
}

          </div>

          {/* Subject */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Subject
            </label>
            <select
              name="subjectId"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleChange}
              value={values.subjectId}
            >
              {subjects &&
                subjects.length > 0 &&
                subjects.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.subject_name}
                  </option>
                ))}
            </select>
          </div>

          {/* Grade */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Grade
            </label>
            <select
              name="grade"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleChange}
              value={values.grade}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          {/* Upload Cover */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Upload Cover
            </label>
            <div className="border-dashed border-2 border-gray-300 rounded-lg p-6 text-center">
              <p className="text-gray-500">Drag and drop cover image here</p>
              <button
                type="button"
                onClick={handleBrowserCover}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Browse Cover
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </div>
          </div>

          {/* Upload PDF */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Upload PDF
            </label>
            <div className="border-dashed border-2 border-gray-300 rounded-lg p-6 text-center">
              <p className="text-gray-500">
                {pdfName || "Drag and drop PDF here"}
              </p>
              <button
                type="button"
                onClick={handlePdfFile}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Browse PDF
              </button>
              <input
                style={{ display: "none" }}
                ref={pdfInputRef}
                type="file"
                onChange={handlePdfChange}
                accept="application/pdf"
              />
            </div>
          </div>

          {/* State */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              State
            </label>
            <select
              name="state_id"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleChange}
              value={values.state_id}
            >
              {states &&
                states.length > 0 &&
                states.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.state_name}
                  </option>
                ))}
            </select>
          </div>

          {/* Author Score */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Author Score
            </label>
            <input
              type="text"
              name="author_score"
              onChange={handleChange}
              value={values.author_score}
              placeholder="Author Score"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Price */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Price
            </label>
            <input
              type="number"
              name="price"
              onChange={handleChange}
              value={values.price}
              placeholder="Price"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Discount */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Discount
            </label>
            <input
              type="number"
              name="discount"
              onChange={handleChange}
              value={values.discount}
              placeholder="Discount"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Price Information */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Price Information
            </label>
            <input
              type="text"
              name="price_information"
              onChange={handleChange}
              value={values.price_information}
              placeholder="Price Information"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Preview Box */}
          <div className="border border-dashed border-gray-300 rounded-lg flex items-center justify-center h-36">
            {coverPreview ? (
              <img
                src={coverPreview}
                alt="Cover Preview"
                className="object-contain h-full w-full rounded-lg"
              />
            ) : (
              <p className="text-gray-500">Preview</p>
            )}
          </div>

          {/* Submit Button */}
          <div className="col-span-1 md:col-span-2 flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default EditBook;
