import { toast } from "react-toastify";

const addItem = (name, value) => {
    localStorage.setItem(name, value);
  }
  
  // Function to remove an item from localStorage
   const removeItem = (name) => {
    localStorage.removeItem(name);
  }
  
  // Function to get an item from localStorage
   export const getItem = (name) => {
    const item = localStorage.getItem(name);
    return item ? item : null;
  }
  export const deleteItem = (name) => {
    const item = localStorage.removeItem(name);
    return item ? item : null;
  }
  
  // Function to clear all items from localStorage
   const clearStorage = () => {
    localStorage.clear();
  }
  // export default {
  //  getItem,clearStorage,
  //  removeItem,
  //  addItem
  // }

  export const authorizatonCommonFun = (apiResponse) => {
    console.log("authorizatonCommonFun", apiResponse);
    if(apiResponse?.response?.data?.message  === "Unauthorized Exception") {
      toast.error("Token is expired");
      window.location.href = "/login";
    }
    if(apiResponse?.response?.data?.message  === "jwt expired") {
      toast.error("Token is expired");
      window.location.href = "/login";
    }
  }