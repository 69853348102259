import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getStateDropdownAsyncThunk } from "../redux/slices/dropdowns/dropdownSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { authorizatonCommonFun } from "../utils/basicService";

export default function Profile() {
  const dispatch = useDispatch();
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const user = JSON.parse(localStorage.getItem("app-token"));

  useEffect(() => {
    dispatch(getStateDropdownAsyncThunk());
  }, [dispatch]);

  console.log("user?.state_id " , user?.state_id )

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      address: user?.address || "",
      suburb: user?.suburb || "",
      city: user?.city || "",
      state_id: user?.state_id || "",
    },
    onSubmit: async (values) => {
      try {
        const response = await ApiRequest.profileUpdate({
          data: values,
          id: user.id,
        });
        if (response.status === 200) {
          toast.success("Profile updated");
          localStorage.setItem("app-token", JSON.stringify(response.data));
        }
      } catch (e) {
        console.log("error", e);
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    },
  });

  return (
    <DashboardLayout>
      <div
        className="mt-10 max-w-[1200px] mx-auto bg-white rounded-lg p-6 shadow-md"
        style={{ minHeight: "530px" }} // Set a minimum height for the card
      >
        <h2 className="text-2xl font-bold mb-4 text-center">Profile</h2>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <div style={{ maxWidth: "90%" }}>
                {" "}
                {/* Adjust width here */}
                <TextField
                  fullWidth
                  label="First Name"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter your first name"
                />
              </div>
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <div style={{ maxWidth: "90%" }}>
                {" "}
                {/* Adjust width here */}
                <TextField
                  fullWidth
                  label="Last Name"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter your last name"
                />
              </div>
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <div style={{ maxWidth: "95%" }}>
                {" "}
                {/* Adjust width here */}
                <TextField
                  fullWidth
                  label="Address"
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter your address"
                />
              </div>
            </Grid>

            {/* Suburb */}
            <Grid item xs={12} sm={6}>
              <div style={{ maxWidth: "90%" }}>
                {" "}
                {/* Adjust width here */}
                <TextField
                  fullWidth
                  label="Suburb"
                  id="suburb"
                  name="suburb"
                  value={formik.values.suburb}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter suburb"
                />
              </div>
            </Grid>

            {/* City */}
            <Grid item xs={12} sm={6}>
              <div style={{ maxWidth: "90%" }}>
                {" "}
                {/* Adjust width here */}
                <TextField
                  fullWidth
                  label="City"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter city"
                />
              </div>
            </Grid>

            {/* State Dropdown */}
            <Grid item xs={12}>
              <div style={{ maxWidth: "95%" }}>
                {" "}
                {/* Adjust width here */}
                <FormControl fullWidth>
                  {/* Ensure the InputLabel is linked to the Select via labelId */}
                  <InputLabel id="state-select-label" htmlFor="state_id">
                    State
                  </InputLabel>
                  <Select
                    labelId="state-select-label" // Link to InputLabel
                    id="state_id"
                    name="state_id"
                    defaultValue={formik.values.state_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="">Select a State</MenuItem>
                    {states?.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>

            {/* Save Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className="rounded-md text-white"
                style={{
                  fontSize: "0.875rem", // Smaller font size
                  padding: "0.5rem 1rem", // Smaller padding
                  maxWidth: "200px", // Limit button width
                  margin: "0 auto", // Center the button
                  display: "block", // Ensure centering works
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </DashboardLayout>
  );
}
