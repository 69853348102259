import React from "react";
import { Audio } from "react-loader-spinner";

const ReactLoader = ({ color }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.loaderContainer}>
        <Audio
          height="80"
          width="80"
          radius="9"
          color={color}
          ariaLabel="loading"
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  loaderContainer: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ReactLoader;
