import * as yup from "yup";

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain (e.g., .com, .in, .club)"
    )
    .email("Please enter a valid email"),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must have a valid domain (e.g., .com, .in, .club)"
    )
    .email("Please enter a valid email"),

  password: yup
    .string()
    .required("Password is required")
    .min(6, "Enter at least 6 characters")
    .max(90, "Password cannot be longer than 90 characters"),
});
export const addUserSchema = yup.object().shape({
  full_name: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .min(6, "Enter at least 6 characters")
  //   .max(90, "Password cannot be longer than 90 characters"),
  // user_name: yup.string().required("Name is required"),
  role: yup.string().required("Role is required"),
});
