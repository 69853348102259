import { Button } from "@mui/material";
import CheckCircleSvg from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { archieveQuestionAsyncThunk, deleteQuestionAsyncThunk } from "../../redux/slices/question/questionSlice";
import { duplicateQuestionAsyncThunk } from "../../redux/slices/package/packageSlice";
import { toast } from "react-toastify";
import { ApiRequest } from "../../utils/apiHelpers/apiRequest.ts";
import BasicCard from "../Box/index.js";
import { usedByTestAsyncThunk } from "../../redux/slices/test/testSlice.js";
import { Padding } from "@mui/icons-material";
import SweetAlertDemo from "../SweatAlert/index.js";

export default function QuestionCard({ question, index, testId, dispatchFun, assignToTest, cardShow, setCardShow, testQuestion }) {
  // alert("check two");
  console.log("question----", question);
  const navigate = useNavigate();
  // console.log("setCardShow", setCardShow)
//  alert("assignToTest", assignToTest)
  const [showAnswer, setShowAnswer] = useState(null);
  const { usedByTestData } = useSelector(state => state.tests);



  const loading = useSelector(
    (store) => store.question.loadings.deleteQuestionAsyncThunk
  );
  const dispatch = useDispatch();
  // dispatch(
  //   editQuestionAsyncThunk({
  //     data,
  //     id,
  //     questionType: "comprehension",
  //     navigate,
  //   })
  // );
  const handleArchieve = (data) => {
    console.log("dataaaaa--->", data);
    const id = data.question_id;
    const questionType = data.question_type;
    const { question_id,question_type, assignedToTest, ...newData } = data;
    dispatch(archieveQuestionAsyncThunk({data: {is_archived:true, ...newData}, id, questionType}));
  }
  const handleDuplicate = (data) => {
    const { question_id, assignedToTest, ...newData } = data;
    if(testId) {
      newData.test_id = Number(testId);
    }
    dispatch(duplicateQuestionAsyncThunk({data: newData}));
  }
  const [isLoading, setLoading] = useState(false);
  const handleAssignTest = async (data) => {
    try {
      const body = {
        question_id: data.question_id,
        test_id: Number(testId),
        position: 0
      }
      setLoading(!isLoading);
     const response = await ApiRequest.assignTestQuestion(body);
     if(response) {
      setLoading(!loading);
         toast.success("Assign question to test successfully");
         dispatchFun();
     }
    } catch (e) {
      setLoading(!loading);
      console.log("error", e);
      toast.error(e.response.data.message);
    }
  }


  const handleUsedIn = (entityId, entityType) => {
    console.log("question", question)
    console.log("entityId", entityId)

    setCardShow(entityId); // Show this card's details
    const body = {
      entityId,
      entityType,
    };
    dispatch(usedByTestAsyncThunk(body));
  };

  useEffect(() => {
    {cardShow && console.log("cardShow", cardShow && question)}

     console.log("cardShow", cardShow)
  }, [cardShow])
  return (
    <div style={{ boxShadow: "1px 8px 20px 0px #2020200F" }}>
      <div className="flex justify-between px-9 py-5 border-b border-solid border-[#2020201A]">
        <p className="text-base font-semibold leading-6 text-[#202020DB]">
          Question {index + 1}
        </p>
        <p className="text-sm leading-9 text-[#202020DB]">
  Question ID: {question?.question_id}
</p>
      </div>
      <div className="px-9">
        <p
          className="text-base leading-6 text-[#202020] border-b border-solid border-[#2020201A] py-5"
          dangerouslySetInnerHTML={{ __html: question?.question_text }}
        />
      </div>
      <div className="flex flex-col">
        {showAnswer == question?.question_text && (
          <>
            {question?.options?.map((que, ind) => (
              <div
                className={`flex justify-between gap-5 p-4 rounded-lg ${
                  que?.is_correct == "true" ? "bg-[#f6f6f8]" : ""
                } mx-5 my-2`}
                key={ind}
              >
                <div className="flex gap-2">
                  <span className="text-base leading-6 text-[#202020]">
                    {ind == 0
                      ? "A"
                      : ind == 1
                      ? "B"
                      : ind == 2
                      ? "C"
                      : ind == 3
                      ? "D"
                      : ind == 4
                      ? "E"
                      : ind == 5
                      ? "F"
                      : ""}
                  </span>
                  <p
                    className="text-base leading-6 text-[#202020]"
                    dangerouslySetInnerHTML={{ __html: que?.option_text }}
                  />
                </div>
                {que?.is_correct == "true" && (
                  <CheckCircleSvg className="text-green-500" />
                )}
              </div>
            ))}
            <div className="py-5 flex gap-10">
              <p className="text-sm leading-6 text-[#202020]">Question Type:</p>
              <p className="text-sm leading-6 text-[#202020]">
                {question?.question_type}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="px-9 py-5 flex flex-wrap gap-7 align-center">
        <Button
          variant="text"
          className="font-poppins text-sm normal-case"
          onClick={() =>
            showAnswer == question?.question_text
              ? setShowAnswer("")
              : setShowAnswer(question?.question_text)
          }
        >
          Answer
        </Button>
        <Link to={`/company/question/edit/${question?.question_id}`}>
          <Button variant="text" className="font-poppins text-sm normal-case">
            Edit
          </Button>
        </Link>
        <Button variant="text" className="font-poppins text-sm normal-case" onClick={() => handleDuplicate(question)}>
          Duplicate
        </Button>
        <Button variant="text" className="font-poppins text-sm normal-case" onClick={() => handleArchieve(question)}>
          Archive
        </Button>
        <SweetAlertDemo apiFunction={() => dispatch(deleteQuestionAsyncThunk(question?.question_id))} component={<Button
          disabled={loading}
          variant="text"
          className="font-poppins text-sm normal-case"
        >
          {loading ? "Deleting" : "Delete"} 
        </Button>} />
        
     
        <Button variant="text" className="felx justify-start font-poppins text-sm normal-case" onClick={() => handleUsedIn(question?.question_id ? question?.question_id : question?.question?.question_id, "question", question)}>
          Used In
        </Button>
        {(testId && assignToTest) && <Button variant="text" className="font-poppins text-sm normal-case" >
           {assignToTest ? "Question added to test": "Assign to test"}
        </Button>}
        {(testId && !assignToTest) &&<Button variant="text" className="font-poppins text-sm normal-case" onClick={() => handleAssignTest(question)}>
           {isLoading ? 'Loading...' :"Assign to test"}
        </Button>}
        {testQuestion && <Button onClick={() => navigate(`/company/re-order/${question.question_id}`)} variant="text" className="font-poppins text-sm normal-case">{"Reorder"}</Button>}
     
      <div style={{width:'100%', boxShadow:' rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
      {question  && cardShow && (cardShow[question?.question_id ? question?.question_id : question?.question?.question_id]) && usedByTestData && <BasicCard background={"#ffffff"} title={"Tests Currently using this Question"} testNames={usedByTestData?.testNames} />}
      </div>
      </div>
      
   
    </div>
  );
}

   
