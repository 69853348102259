import { Button } from "@mui/material";
import CheckCircleSvg from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuestionAsyncThunk } from "../../redux/slices/question/questionSlice";
import { addDuplicateComprehensionAsyncThunk } from "../../redux/slices/package/packageSlice";
import { toast } from "react-toastify";
import { ApiRequest } from "../../utils/apiHelpers/apiRequest.ts";
import { usedByTestAsyncThunk } from "../../redux/slices/test/testSlice.js";
import BasicCard from "../Box/index.js";
import SweetAlertDemo from "../SweatAlert/index.js";

export default function ComprehensionQuestionCard({ passage, cardShow, index, path, testId, questionBank, assignToTest, setCardShow, hideAdd }) {
  console.log("passsage", passage);
  // alert("check one");
  const [showAnswer, setShowAnswer] = useState(null);
  const loading = useSelector(
    (store) => store.question.loadings.deleteQuestionAsyncThunk
  );
  const navigate = useNavigate();
  const { usedByTestData } = useSelector(state => state.tests);

  const dispatch = useDispatch();
  const handleDuplicate = (passage) => {
    // Copy the passage without modifying the original
    console.log("passage", passage)
    const passageCopy = {
      ...passage,
      // Remove the subtopic from the copy
      questions: passage.questions.map((question) => {
        // Transform options without modifying the original
        let transformedOptions = testId ? question.answers.map((option) => ({
          answer_text: option.answer_text, // Spread to retain all properties
          is_correct: option.is_correct,
          rc_answer_id: option.rc_answer_id,
          is_image_present: option.is_image_present||option.is_image||"false",

        })) :question.options.map((option) => ({
          answer_text: option.answer_text, // Spread to retain all properties
          is_correct: option.is_correct,
          is_image_present: option.is_image_present||option.is_image||"false",
          rc_answer_id: option.rc_answer_id
        }));

        // Return a new question object with transformed options
        return {
          quality_checked: question.quality_checked,
          // answers: question.answers,
          difficulty_level: question.difficulty_level,
          exam_type_id: question.exam_type,
          subtopic :passage.subtopic||"",
          explanation:passage.explanation||"",
          grade: question.grade,
          is_image: question.is_image,
          options: transformedOptions,
          question_text: question.question_text,
          question_type: question.question_type,
          reading_question_id: question.reading_question_id,
          subject: question.subject,
          topic: question.topic,
        };
      }),
    };
    // delete passageCopy.subtopic;
  
    if(testId) {
      delete passageCopy.id; 
      delete passageCopy.sub_topic; 
      passageCopy.test_id = Number(testId);
    }
    console.log("Duplicated Passage:1", passageCopy);
    dispatch(addDuplicateComprehensionAsyncThunk({ data: passageCopy, path, navigate }));
  };

  const handleTestAssignCompression = async (passage) => {
    console.log("passage-->123", passage);
      try {
          const body = {
              passage_id: passage.passage_id,
              test_id: Number(testId)
          }
          const response = await ApiRequest.assignTestQuestion(body);
          if(response) {
            toast.success("Assign question to test successfully");
            path(); 
          }
      } catch (e) {
        console.log("error", e);
        toast.error(e.response.data.message);
      }
  }

  
  const handleUsedIn = (entityId, entityType, paas) => {
    // console.log("question", question)
    console.log(entityId, "<-------------->", paas)
    
    setCardShow(entityId); // Show this card's details
    const body = {
      entityId,
      entityType,
    };
    dispatch(usedByTestAsyncThunk(body));
  };
  
  useEffect(() => {
    console.log("cardShow--->11", cardShow);
    console.log("passage--->", passage);

  }, [cardShow]);

  return (
    <div style={{ boxShadow: "1px 8px 20px 0px #2020200F" }}>
      <div className="flex justify-between px-9 py-5 bordzer-b border-solid border-[#2020201A]">
        <p className="text-base font-semibold leading-6 text-[#202020DB] flex gap-2">
          Reading Comprehension Text:{" "}
          <span
            className=""
            dangerouslySetInnerHTML={{ __html: passage?.passage_text }}
          />
        </p>
        <p className="text-sm leading-9 text-[#202020DB]">
          Paragraph ID: {passage?.passage_id ? passage?.passage_id : passage?.id}
        </p>
      </div>
      <div className="px-9 py-5 flex flex-wrap gap-7">
        <Link
          to={`/company/question/edit/${passage?.passage_id ? passage?.passage_id : passage?.id}?questionType=comprehension`}
        >
          <Button variant="text" className="font-poppins text-sm normal-case">
            Edit
          </Button>
        </Link>
        <Button variant="text" className="font-poppins text-sm normal-case" onClick={() => handleDuplicate(passage)}>
          Duplicate
        </Button>
        <Button variant="text" className="font-poppins text-sm normal-case">
          Archive
        </Button>
        <SweetAlertDemo apiFunction={() => dispatch(deleteQuestionAsyncThunk(passage?.passage_id ? passage?.passage_id : passage?.id))} component={<Button
          disabled={loading}
          variant="text"
          className="font-poppins text-sm normal-case"
        >
          {loading ? "Deleting" : "Delete"} 
        </Button>} />
        {/* <Button
          onClick={() =>
            
          }
          disabled={loading}
          variant="text"
          className="font-poppins text-sm normal-case"
        >
          {loading ? "Deleting" : "Delete"}
        </Button> */}
        <Button variant="text" className="font-poppins text-sm normal-case" onClick={() => handleUsedIn(passage?.passage_id ? passage?.passage_id : passage?.id, "passage", passage)}>
          {"Used In"}
        </Button>

        {passage && cardShow && (cardShow[passage?.passage_id ? passage?.passage_id : passage?.id]) && <BasicCard background={"#e8e8e8"} title={"Tests Currently using this Question"} testNames={usedByTestData?.testNames} />}


          <Button variant="text" onClick={() => { console.log("passage", passage); handleTestAssignCompression(passage)}} className="font-poppins text-sm normal-case">
            {hideAdd && (assignToTest ? "Qestion added to test!" : "Add Reading Comprehension to Test")}
        </Button>
      </div>
      <div className="flex flex-col">
        {passage?.questions?.map((question, index) => (
          <div className="px-9" key={index}>
            <p
              className="text-base leading-6 text-[#202020] border-b border-solid border-[#2020201A] py-5"
              dangerouslySetInnerHTML={{ __html: question?.question_text }}
            />
            {showAnswer == question?.question_text &&
              question?.options?.map((que, ind) => (
                <div
                  className={`flex justify-between gap-5 p-4 rounded-lg ${
                    que?.is_correct == "true" ? "bg-[#f6f6f8]" : ""
                  } mx-5 my-2`}
                  key={ind}
                >
                  <div className="flex gap-2">
                    <span className="text-base leading-6 text-[#202020]">
                      {ind == 0
                        ? "A"
                        : ind == 1
                        ? "B"
                        : ind == 2
                        ? "C"
                        : ind == 3
                        ? "D"
                        : ind == 4
                        ? "E"
                        : ind == 5
                        ? "F"
                        : ""}
                    </span>
                    <p
                      className="text-base leading-6 text-[#202020]"
                      dangerouslySetInnerHTML={{ __html: que?.option_text }}
                    />
                  </div>
                  {que?.is_correct == "true" && (
                    <CheckCircleSvg className="text-green-500" />
                  )}
                </div>
              ))}
            <div className="px-9 py-5 flex flex-wrap gap-7">
              <Button
                variant="text"
                className="font-poppins text-sm normal-case"
                onClick={() =>
                  showAnswer == question?.question_text
                    ? setShowAnswer("")
                    : setShowAnswer(question?.question_text)
                }
              >
                Answer
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
