import React from "react";
import { useFormik } from "formik";
import DynamicEditor from "../components/tinyEditor/Index";
import DashboardLayout from "../layout/DashboardLayout";
import { Button } from "@mui/material";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function RichTextEditor({ index }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      myField: "Default Value", // Initial value for the editor field
    },
    onSubmit: async (values) => {
      const data = {
        test_description: values.myField,
      };
      try {
        const response = await ApiRequest.editTest({ data, id });
        toast.success("Instruction saved");
        navigate(`/company/tests-question/${id}`);
        console.log("response", response);
      } catch (e) {
        console.log("error--:", e);
        toast.error(e.response.data.message);
      }
      // Log form values on submit
    },
  });

  const handleBlur = () => {
    console.log("Blur event triggered");
  };

  const saveInstruction = () => {
    console.log("Editor Value:", formik.values.myField);
  };

  return (
    <DashboardLayout>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <DynamicEditor
            id={index} // Using the passed index as the editor ID
            value={formik.values.myField} // Bind Formik's state to the editor
            setFieldValue={(field, value) =>
              formik.setFieldValue("myField", value)
            } // Update Formik's state
            onBlur={handleBlur} // Handle blur events
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ width: "15rem" }}
              type="submit"
              className="btn btn-primary"
            >
              Submit Form
            </Button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
}

export default RichTextEditor;
