export const handleLoadings = (state: { loadings: { [x: string]: boolean } }, action: { type: string })=>{
    let asyncName = action?.type.split("/")
    if(action.type.endsWith("pending")){
        state.loadings[asyncName[1]] = true
    }
    if(action.type.endsWith("rejected")){
        state.loadings[asyncName[1]] = false
    }
    if(action.type.endsWith("fulfilled")){
        state.loadings[asyncName[1]] = false
    }
}