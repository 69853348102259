import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Radio,
  TextField,
} from "@mui/material";
import DynamicEditor from "../tinyEditor/Index";
import TextFieldDropdown from "../Dropdown/Index";

export default function ExcelOptionSelect({
  questionIndex,
  index,
  option,
  onChange,
  setFieldValue,
  onBlur,
  error,
  helperText,
  correctOptionId,
  setCorrectOptionId,
  questionType,
}) {
  const alphabet =
    index == 0
      ? "A"
      : index == 1
      ? "B"
      : index == 2
      ? "C"
      : index == 3
      ? "D"
      : index == 4
      ? "E"
      : index == 5
      ? "F"
      : null;
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <p className="text-[#B6B6B6] border border-[#B6B6B6] rounded-full w-[21px] h-[22px] flex justify-center items-center font-semibold">
            {alphabet}
          </p>
          {questionType == "MultiCorrect" ? (
            <div>
              <Checkbox
                name={`questions[${questionIndex}].options[${index}].is_correct`}
                id={`options[${index}].is_correct`}
                checked={option?.is_correct}
                color="secondary"
                className="ml-3 p-0"
                onChange={() =>
                  setFieldValue(
                    `options[${index}].is_correct`,
                    !option?.is_correct
                  )
                }
              />
              <span className="text-[#202020] font-xs font-medium ml-2">
                Set as correct answer
              </span>
            </div>
          ) : (
            <FormControlLabel
              control={
                <Radio
                  id={`questions[${questionIndex}].options[${index}].is_correct`}
                  checked={
                    correctOptionId.includes(
                      `questions[${questionIndex}].options[${index}].is_correct`
                    )
                      ? true
                      : false
                  }
                  color="secondary"
                  // checkedIcon={<Checked className="flex-shrink-0" />}
                  // icon={<Unchecked className="flex-shrink-0" />}
                  onChange={(e) => {
                    const found = correctOptionId.findIndex(
                      (el) => el.slice(0, 12) === `questions[${questionIndex}]`
                    );
                    if (found !== -1) {
                      // Create a copy of correctOptionId before modifying it
                      const newArray = [...correctOptionId];
                      newArray.splice(
                        found,
                        1,
                        `questions[${questionIndex}].options[${index}].is_correct`
                      );
                      setCorrectOptionId(newArray);
                      setFieldValue(correctOptionId[found], "false");
                    } else {
                      setCorrectOptionId([
                        ...correctOptionId,
                        `questions[${questionIndex}].options[${index}].is_correct`,
                      ]);
                    }

                    setFieldValue(
                      `questions[${questionIndex}].options[${index}].is_correct`,
                      "true"
                    );
                  }}
                  value={option.is_correct}
                  className="ml-3 p-0"
                />
              }
              label={
                <span className="text-[#202020] font-xs font-medium ml-2">
                  Set as correct answer
                </span>
              }
            />
          )}
        </div>
        <p className="text-[#202020] font-xs font-medium">Mandatory</p>
      </div>
      <div className="my-3">
        <DynamicEditor
          id={`questions[${questionIndex}].options[${index}].option_text`}
          value={option.option_text}
          setFieldValue={setFieldValue}
          onBlur={onBlur}
          height={150}
          questionType={questionType}
        />
        {/* <TextField
          id={`options[${index}].answer_text`}
          name={`options[${index}].answer_text`}
          onChange={onChange}
          value={option.answer_text}
        /> */}
        {error && <p className="text-[#d32f2f] text-xs">{helperText}</p>}
      </div>
      <div className="my-3 w-full sm:max-w-[227px]">
        <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
          Is image present in choice {alphabet}?
        </InputLabel>
        <TextFieldDropdown
          id={`questions[${questionIndex}].options[${index}].is_image_present`}
          name={`questions[${questionIndex}].options[${index}].is_image_present`}
          options={[
            { name: "No", value: "false" },
            { name: "Yes", value: "true" },
          ]}
          onChange={onChange}
          value={option.is_image_present}
        />
      </div>
    </div>
  );
}
