import React from 'react';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function DragDropBox({ background, title, question, index, onDragStop }) {
  // Handles when dragging stops
  const handleStop = (e, data) => {
    onDragStop(index, data.y); // Sends the index and new Y position back to the parent component
  };

  console.log("question---", question);
  return (
    <Draggable axis="y" onStop={handleStop}>
      {/* <Box> */}
        {/* <Card sx={{ minWidth: 275, background }}>
          <CardContent sx={{ background }}>
            <Typography>{title}</Typography> */}
            {/* {testNames?.length > 0 ? (
              testNames.map((item, idx) => ( */}
                {/* <Typography
                  // key={idx}
                  gutterBottom
                  sx={{ color: 'text.secondary', fontSize: 14 }}
                >
                  {testNames}
                </Typography> */}
              {/* ))
            ) : (
              <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                {"Loading..."}
              </Typography>
            )} */}
          {/* </CardContent>
        </Card>
      </Box> */}
      <div style={{ boxShadow: "1px 8px 20px 0px #2020200F" }}>
      <div className="flex justify-between px-9 py-5 border-b border-solid border-[#2020201A]">
        <p className="text-base font-semibold leading-6 text-[#202020DB]">
          Question
           {/* {index + 1} */}
        </p>
        <p className="text-sm leading-9 text-[#202020DB]">
  Question ID:
   {question?.question_id}
</p>
      </div>
      <div className="px-9">
        <p
          className="text-base leading-6 text-[#202020] border-b border-solid border-[#2020201A] py-5"
          dangerouslySetInnerHTML={{ __html: question?.question_text }}
        />
      </div>
      </div>

    </Draggable>
    
  );
}
