import React, { useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import DashboardCard from "../components/DashboardCard/Index";
import { ReactComponent as DashboardPackagesSvg } from "../assests/icons/dashboard-packages.svg";
import { ReactComponent as DashboardTestsSvg } from "../assests/icons/dashboard-tests.svg";
// import { ReactComponent as DashboardQuestionsSvg } from "../assests/icons/dashboard-questions.svg";
import { ReactComponent as DashboardScaleSvg } from "../assests/icons/dashboard-scale.svg";
import { ReactComponent as MonitorExamSvg } from "../assests/icons/monitor-live-exam.svg";
import { ReactComponent as ExamUsageSvg } from "../assests/icons/exam-usage.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAsyncThunk } from "../redux/slices/auth/authSlice";
// import TestLayout from "../layout/testLayout";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboard } = useSelector(state => state.auth);

  useEffect(() => {
    console.log("dashboard", dashboard);
  }, [dashboard])


  //  userCount,
  //     AdminUserCount,
  //     testCount,
  //     questionCount,
  //     packageCount,

  useEffect(() => {
    dispatch(dashboardAsyncThunk());
  }, [])
  return (<>
    {/* <TestLayout /> */}
    <DashboardLayout>
      <h1 className="dashboard-heading">{"Dashboard"}</h1>
      <div className="mt-20 min-h-[calc(100vh-220px)] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-x-10 gap-y-14">
        <DashboardCard
          Icon={DashboardPackagesSvg}
          title={"Packages Sold"}
          count={dashboard?.packageCount ? dashboard?.packageCount : 0}
        />
        <DashboardCard
          Icon={DashboardTestsSvg}
          title={"Tests Conducted"}
          count={dashboard?.testConductedCount ? dashboard.testConductedCount : 0}
        />
        {/* <DashboardCard
          Icon={DashboardQuestionsSvg}
          title={"Website Visitors"}
          count={124}
        /> */}
        <DashboardCard
          Icon={DashboardScaleSvg}
          title={"Ebooks Sold"}
          count={dashboard?.ebookSold ? dashboard?.ebookSold : 0}
        />
        
        <DashboardCard Icon={MonitorExamSvg} title={"Students"} count={dashboard?.userCount ? dashboard.userCount : 0} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Total Packages"}
          count={dashboard?.packageCount ? dashboard.packageCount : 0
          }
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Total Tests"} count={dashboard?.testCount ? dashboard.testCount : 0} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Ebooks Published"}
          count={dashboard?.bookCount ? dashboard.bookCount : 0}
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Questions"} count={dashboard?.questionCount ? dashboard.questionCount : 0} />
        <DashboardCard Icon={ExamUsageSvg} title={"Admin Users"} count={dashboard?.AdminUserCount ? dashboard.AdminUserCount : 0} />
        {/* <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Questions"}
          count={2}
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Database Usage"} count={2} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Students Portal Performance"}
          count={2}
        /> */}
        {/* <DashboardCard
          Icon={ExamUsageSvg}
          title={"Number Of Web Pages"}
          count={2}
        /> */}
        {/* <DashboardCard
          Icon={ExamUsageSvg}
          title={"Website Performance"}
          count={2}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Hosting Server Metrics"}
          count={2}
        /> */}
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Tests"}
          count={dashboard?.FeedbackTestCount ? dashboard.FeedbackTestCount : 0}

        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Packages"}
          count={dashboard?.FeedbackRecievedCount ? dashboard.FeedbackRecievedCount : 0}

        />
        <DashboardCard
          Icon={ExamUsageSvg} 
          title={"Number of Messages Not Responded"}
          count={dashboard?.messageCount ? dashboard.messageCount : 0}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Essay Submissions Needing Feedback"}
          count={dashboard?.essayCount ? dashboard.essayCount : 0}
        />
        {/* <DashboardCard
          Icon={ExamUsageSvg}
          title={"Topics Sub-Topics By Exams"}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Report Question Analysis Bank"}
        /> */}
      </div>
    </DashboardLayout>
    </>  );
}
