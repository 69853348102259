import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { authorizatonCommonFun } from "../../../utils/basicService/index.js";
import { toast } from "react-toastify";


export const getTestimonial = createAsyncThunk(
      "auth/getTestimonial",
      asyncWrapper(async (data, thunk) => {
        try {
        const response = await ApiRequest.getByTestimonial(data);
        return response.data;
        } catch (e) {
            authorizatonCommonFun(e);
            toast.error(e.response.data.message);
        }
      })
    );

   export const getDiscount = createAsyncThunk(
    "auth/getDiscount",
    asyncWrapper(async (data, thunk) => {
      try {
      const response = await ApiRequest.getDiscount(data);
      console.log("responseeeee", response)
      return response.data;
      } catch (e) {
          authorizatonCommonFun(e);
          toast.error(e.response.data.message);
      }
    })
  );

  export const analysisFilter = createAsyncThunk(
    "analysisFilter",
    asyncWrapper(async (data, thunk) => {
        try {
        const response = await ApiRequest.analysisData(data);
        console.log("responseeeee", response)
        return response.data;
        } catch (e) {
            authorizatonCommonFun(e);
            toast.error(e.response.data.message);
        }
      })
  )

    export const getBooksAsyncThunk = createAsyncThunk(
      "getBooksAsyncThunk",
      asyncWrapper(async (data) => {
          try {
          const response = await ApiRequest.getBooks(data);
          console.log("responseeeee", response)
          return response.data;
          } catch (e) {
              authorizatonCommonFun(e);
              toast.error(e.response.data.message);
          }
        })
    )
// export const getExamTpesDropdownAsyncThunk = createAsyncThunk(
//   "auth/getExamTpesDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getExamTypesDropdown();
//     return response.data;
//   })
// );
// export const getStateDropdownAsyncThunk = createAsyncThunk(
//   "auth/getStateDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getStateDropdown();
//     console.log('response', response.data);
//     return response.data;
//   })
// );

// export const getExamConductedByDropdownAsyncThunk = createAsyncThunk(
//   "auth/getExamConductedByDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getExamConductedByDropdown();
//     return response.data;
//   })
// );
// export const getSubjectsDropdownAsyncThunk = createAsyncThunk(
//   "auth/getSubjectsDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getSubjectsDropdown();
//     return response.data;
//   })
// );

// // export const getSearchedPakageDropdownAsyncThunk = createAsyncThunk(
// //   "auth/getSearchedPakageDropdownAsyncThunk",
// //   asyncWrapper(async (data, thunk) => {
// //     const response = await ApiRequest.getSearchedPakageDropdown(data);
// export const getSearchedPakageDropdownAsyncThunk = createAsyncThunk(
//   "auth/getSearchedPakageDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getSearchedPakageDropdown(data);
//     return response.data; // Ensure to return the data
//   })
// );

// export const getTopicsDropdownAsyncThunk = createAsyncThunk(
//   "auth/getTopicsDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getTopicsDropdown();
//     return response.data;
//   })
// );
// export const getSubtopicsDropdownAsyncThunk = createAsyncThunk(
//   "dropdown/getSubtopicsDropdown",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await ApiRequest.getSubtopicsDropdown();
//       return response.data;
//     } catch (error) {
//       // Log the error for debugging
//       console.error("Failed to fetch subtopics:", error);
//       // Return a custom error message
//       return rejectWithValue(error.response?.data?.message || "Failed to fetch subtopics");
//     }
//   }
// );
const initialState = {
//   getSearchedPakage:[],
//   stateDropdown: [],
//   examTypes: [],
//   examConductedBy: [],
//   subjects: [],
//   topics: [],
//   subtopics: [],
     discount: [],
     testimonial: [],
     analysisData: {},
     books: [],
  loadings: {
    // getExamTpesDropdownAsyncThunk: false,
    // getStateDropdownAsyncThunk: false,
    // getExamConductedByDropdownAsyncThunk: false,
    // getSubjectsDropdownAsyncThunk: false,
    // getTopicsDropdownAsyncThunk: false,
    // getSubtopicsDropdownAsyncThunk: false,
  },
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  extraReducers: (builder) => {
    builder
      // state dropdown
      .addCase(getBooksAsyncThunk.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.books = action.payload;
      })
      .addCase(analysisFilter.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.analysisData = action.payload;
      })
      .addCase(getTestimonial.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.testimonial = action.payload;
      })
      .addCase(getDiscount.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.discount = action.payload;
      })
    //   // exam type dropdown
    //   .addCase(getExamTpesDropdownAsyncThunk.fulfilled, (state, action) => {
    //     state.examTypes = action.payload;
    //   })
      // exam conducted dropdown
    //   .addCase(
    //     getExamConductedByDropdownAsyncThunk.fulfilled,
    //     (state, action) => {
    //       state.examConductedBy = action.payload;
    //     }
    //   )
    //   // subjects dropdown
    //   .addCase(getSubjectsDropdownAsyncThunk.fulfilled, (state, action) => {
    //     state.subjects = action.payload;
    //   })

    //   .addCase(getSearchedPakageDropdownAsyncThunk.fulfilled, (state, action) => {
    //     state.getSearchedPakage = action.payload;
    //   })

    //   // topics dropdown
    //   .addCase(getTopicsDropdownAsyncThunk.fulfilled, (state, action) => {
    //     state.topics = action.payload;
    //   })
    //   // subtopics dropdown
    //   .addCase(getSubtopicsDropdownAsyncThunk.fulfilled, (state, action) => {
    //     state.subtopics = action.payload;
    //   })
      .addMatcher(
        isAnyOf(
        //   getStateDropdownAsyncThunk,
        //   getExamTpesDropdownAsyncThunk,
        //   getExamConductedByDropdownAsyncThunk,
        //   getTopicsDropdownAsyncThunk,
        //   getSubtopicsDropdownAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    // setUser: (state, action) => {
    //   state.user = action.payload;
    // },
  },
});

export default masterSlice.reducer;
export const {  } = masterSlice.actions;
