import React, { useEffect } from "react";
import { Modal, Box, Button } from "@mui/material";
import DynamicEditor from "../components/tinyEditor/Index";
import DashboardLayout from "../layout/DashboardLayout";
import { useFormik } from "formik";
import { authorizatonCommonFun } from "../utils/basicService";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { useNavigate, useParams } from "react-router-dom";
import RadioComponent from "../components/RadioComponent/index.js";

const AddTestimonialModal = () => {
  const [testimonialData, setTestimonialData] = React.useState({
    username: "",
    description: "",
  });

  const { id } = useParams();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleEditorChange = (newValue) => {
  //   setFormData({ ...formData, description: newValue });
  // };
  const initialValues = {
    username: testimonialData?.username,
    status: 'active',
    description: testimonialData?.description,
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Testimonial Submitted:", formData);
  //   // Add API call or state update logic here
  //   setOpen(false); // Close the modal
  //   // setFormData({ name: "", description: "" }); // Reset form
  // };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        console.log("values", values);
        values.username = testimonialData.username;
        const response = id
          ? await ApiRequest.editTestimonial(values, id)
          : await ApiRequest.createTestimonial(values);
        console.log("response-->", response);
        if (response.status === 201 || response.status === 200) {
          if (id) {
            toast.success("Succesfully edit testimonial");
          } else {
            toast.success("Succesfully added testimonial");
          }
          navigate("/company/testimonial");
        }
      } catch (e) {
        console.log("Error", e);
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
      console.log("valuesssss--->", values);
    },
  });

  const getTestimonial = async (id) => {
    try {
      const response = await ApiRequest.getByIdTestimonial(id);
      console.log("responseeeee", response);
      if (response.status === 200) {
        setTestimonialData(response.data);
        formik.setFieldValue("descripiton", response.data.testimonial);
      }
    } catch (e) {
      console.log("error", e);
      toast.error(e.response.data.message);
    }
  };
  useEffect(() => {
    if (id) {
      getTestimonial(id);
    }
  }, [id]);

  useEffect(() => {
    console.log("testimonialData", testimonialData);
    formik.setValues({
      status: testimonialData?.status
    })
  }, [testimonialData]);

  return (
    // <Modal
    //   open={open}
    //   onClose={() => setOpen(false)}
    //   // sx={{ marginBottom: "10px" }}
    //   aria-labelledby="add-testimonial-modal-title"
    //   aria-describedby="add-testimonial-modal-description"
    // >
    <DashboardLayout>
      <Box
        // sx={{ width: "30px" }}
        className="bg-white p-6 rounded-lg shadow-lg w-[140%] md:w-[936px] mx-auto mt-[10%]"
      >
        <h2
          id="add-testimonial-modal-title"
          className="text-2xl font-bold mb-4"
        >
          {id ? "Edit Testimonial" : "Add Testimonial"}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {"Student Name"}
            </label>
            <input
              type="text"
              name="username"
              defaultValue={testimonialData?.username}
              // value={formData.name}
              onChange={formik.handleChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {"Description"}
            </label>
            {testimonialData && testimonialData.description ? (
              <>
                <DynamicEditor
                  id="description"
                  value={testimonialData.description}
                  setFieldValue={formik.setFieldValue}
                  onChange={formik.handleChange}
                />
              </>
            ) : (
              <DynamicEditor
                id="description"
                value={""}
                setFieldValue={formik.setFieldValue}
                onChange={formik.handleChange}
              />
            )}
          </div>
          <div className="mb-4">
            <RadioComponent
              defaultValue={testimonialData.status}
              title={"Status"}
              id={"testimonial-status"}
              name={"status"}
              formik={formik}
              onClick={(e) => {
                console.log("e.tarvet, value", e.target.value);
                formik.handleChange(e);
              }}
              option={[
                {
                  label: "Active",
                  value: "active",
                },
                {
                  label: "Inactive",
                  value: "inactive",
                },
              ]}
            />
          </div>
          <div className="flex justify-end">
            {/* <Button
              onClick={() => setOpen(false)}
              variant="text"
              color="secondary"
              className="mr-2"
            >
              Cancel
            </Button> */}
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </Box>
      {/* </Modal> */}
    </DashboardLayout>
  );
};

export default AddTestimonialModal;
