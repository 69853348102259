import React, { useState } from "react";
import { Dialog, Button, CircularProgress, InputAdornment } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { editEssaySchema } from "../../utils/yup/packageSchema.ts";
import { useDispatch, useSelector } from "react-redux";
import { editEssayAsyncThunk } from "../../redux/slices/package/packageSlice.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addUserSchema } from "../../utils/yup/authSchemas.ts";
import { addUserAsyncThunk, createUserAsyncThunk, getUsersAsyncThunk } from "../../redux/slices/auth/authSlice.js";
import TextFieldDropdown from "../Dropdown/Index.js";
import { ApiRequest } from "../../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import { authorizatonCommonFun } from "../../utils/basicService/index.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AddUserModal = ({ open, setOpen, userData, setUserData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    (store) => store.packages.loadings.createUserAsyncThunk
  );
  console.log("userDataaaaaa", userData)
  const initialValues = {
    full_name: userData.full_name,
    // user_name: "",
    email: userData?.email,
    role: userData?.role,
    // password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  console.log("Object.keys(userData).length > 0", Object.keys(userData).length);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: addUserSchema,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        console.log("Object.keys(userData).length > 0", Object.keys(userData).length > 0);
        if(Object.keys(userData).length > 0) {
          try {
          const response = await ApiRequest.updateUser(values, userData?.id);
          if (response.status == 200) {
            // thunk.dispatch(getUsersAsyncThunk());
            toast.success("Updated successfully!");
            console.log("response", response); 
              dispatch(getUsersAsyncThunk({
                 roles: "admin,operator",
              }));
              setUserData({});
             
              setOpen(false);
            // localStorage.setItem('app-token', JSON.stringify(response.data));
            // localStorage.setItem('token', response.);
            // action.resetForm();
            // setOpen(false);
          }
          return response.data;
        } catch (e) {
          authorizatonCommonFun(e);
          toast.error(e.response.data.message);
        }
        } else {
          dispatch(addUserAsyncThunk({ data: values, action, setOpen }));
          setOpen(false);
          window.location.reload();
        }
      },
    });

  function handleClose() {
    setUserData({});
    setOpen(false);
  }

  console.log("values", values)

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {Object.keys(userData).length===0 ? "Add User" : "Edit User"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ padding: "10px 10px" }}>
        <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Full Name
            </label>
            <TextField
              fullWidth
              id="full_name"
              name="full_name"
              value={values.full_name}
              color="secondary"
              variant="outlined"
              placeholder="Enter Full Name"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.full_name && Boolean(errors.full_name)}
              helperText={touched.full_name && errors.full_name}
            />
          </div>
          {/* <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              User name
            </label>
            <TextField
              fullWidth
              id="user_name"
              name="user_name"
              value={values.user_name}
              color="secondary"
              variant="outlined"
              placeholder="Write user name"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.user_name && Boolean(errors.user_name)}
              helperText={touched.user_name && errors.user_name}
            />
          </div> */}
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Email
            </label>
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              value={values.email}
              color="secondary"
              variant="outlined"
              placeholder="Enter Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Role
            </label>
            <TextFieldDropdown
              fullWidth
              id="role"
              name="role"
              value={values.role ? values.role : ""}
              color="secondary"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { name: "Select role", value: ""  },
                { name: "Admin", value: "admin" },
                { name: "Operator", value: "operator" },
              ]}
              className="dashboard-input"
              error={touched.role && Boolean(errors.role)}
              helperText={touched.role && errors.role}
            />
          </div>
          {Object.keys(userData).length === 0 && <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Password
            </label>
            <TextField
                fullWidth
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                color="secondary"
                variant="outlined"
                placeholder="Enter a Password"
                onChange={handleChange}
                onBlur={handleBlur}
                className="dashboard-input"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
          </div>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : Object.keys(userData).length === 0 ? "Add User" : "Edit User"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserModal;
