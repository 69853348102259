import { Box, Button, TextField, Typography } from '@mui/material';
import DashboardLayout from "../layout/DashboardLayout";
import { useFormik } from "formik";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import { useEffect } from "react";

const AddSubjectModalForm = ({fun, onHide, state, setState}) => {
    console.log("subject_namesubject_name", state)
  const initialValues = {
    subject_name: state?.subject_name,
  };

//   useEffect(()=>{
//    if(state) {
//     in
//    }
//   }, [state])

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("valuess", values);
        const response = state ? await ApiRequest.updateSubject(values, state?.id) : await ApiRequest.createSubject(values);
        console.log("response", response)
        if(response.status === 201) {
            toast.success("Successfully created");
            fun();
            onHide();
        }
        if(response.status === 200) {
            toast.success("Update Successfully");
            fun();
            setState(null);
            onHide();
        }
    },
  });
  return (
    // <DashboardLayout>
    <div className="flex items-center justify-center">
    <Box
      sx={{
        width: '100%',
        maxWidth: '400px',
        p: 2,
        bgcolor: 'white',
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '18px',
          textAlign: 'center',
          mb: 2,
        }}
        variant="h6"
      >
        {state ? 'Edit Subject' : 'Add Subject'}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Subject"
            variant="outlined"
            size="small"
            defaultValue={initialValues.subject_name}
            onChange={formik.handleChange}
            name="subject_name"
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
          >
            {state ? 'Edit Subject' : 'Add Subject'}
          </Button>
        </Box>
      </form>
    </Box>
  </div>
    // </DashboardLayout>
  );
};

export default AddSubjectModalForm;