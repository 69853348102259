import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import { ReactComponent as SearchSvg } from "../assests/icons/search.svg";
import { ReactComponent as NoQuestionsSvg } from "../assests/images/no-question.svg";
import {
  alpha,
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  styled,
  TextField,
} from "@mui/material";
import TextFieldDropdown from "../components/Dropdown/Index";
import SortComponent from "../components/Sort/Index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { questionBankFilters } from "../constants/questionBankFilters";
import TablePerPage from "../components/TablePerPage/Index";
import QuestionCard from "../components/QuestionCard/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
  getSubtopicsDropdownAsyncThunk,
  getTopicsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice";
import PaginationButtons from "../components/PaginationButtons/Index";
import ComprehensionQuestionCard from "../components/ComprehensionQuestionCard/Index";
import { searchQuestionsAsyncThunk } from "../redux/slices/question/questionSlice";
import TestLayout from "../layout/testLayout";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function TestByQuestionBank() {
  const [fields, setFields] = useState([]);
  const { id } = useParams();
  const [questionFilters, setQuestionFilters] = useState({
    question_type: "",
    is_image: "false",
    topic: "",
    quality_checked: "true",
    subtopic: "",
    grade: "",
    subject: "",
    exam_type_id: "",
    difficulty_level: "",
    question_id: "",
    test_id: Number(id)
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actions, setActions] = React.useState(null);
  const open = Boolean(anchorEl);
  const openActions = Boolean(actions);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get("limit") || 10;
  const page = queryParams.get("page") || 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cardShow, setCardShow] = useState({});

  const { examTypes, subjects, topics, subtopics } = useSelector(
    (store) => store.dropdown
  );
  const { questionBank, loadings } = useSelector((store) => store.question);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActionsClick = (event) => {
    setActions(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActions(null);
  };

  const searchQuestions = () => {
    const data = { page: 1, limit: +limit, ...questionFilters };
    // if(id) {
    //   questionFilters.test_id = id;
    // }
    // consol
    dispatch(searchQuestionsAsyncThunk(data));
  };

  useEffect(() => {
    // Fetch all dropdown data on mount
    dispatch(getExamTpesDropdownAsyncThunk());
    const data = { page: 1, limit: +limit, ...questionFilters };
    dispatch(searchQuestionsAsyncThunk(data));
    dispatch(getSubjectsDropdownAsyncThunk());
    dispatch(getTopicsDropdownAsyncThunk());
    dispatch(getSubtopicsDropdownAsyncThunk());
  }, [dispatch]);
  
  useEffect(() => {
    if (examTypes.length && subjects.length) {
      const values = questionBankFilters(examTypes, subjects, topics, subtopics);
      setFields(values);
    }
  }, [examTypes, subjects, topics, subtopics]);
  
  useEffect(() => {
    console.log("questionBank--", questionBank)
  }, [questionBank])

  return (
    <>
    <TestLayout name={"sd"} questionBank={id} />
    {/* // <DashboardLayout> */}
      <div className="flex justify-between">
        <h1 className="dashboard-heading">{id ? "Reuse Questions" :"Question Bank"}</h1>
        <div className="flex md:gap-14 gap-3">
        {!id && <> <div>
           <Button
              id="add-question"
              aria-controls={open ? "add-question-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
            >
              Add Question
            </Button>
            <StyledMenu
              id="add-question-menu"
              MenuListProps={{
                "aria-labelledby": "add-question",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <Link
                  to={"/company/question/add"}
                  style={{ width: "100% !important" }}
                >
                  Add new question
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <Link
                  to={"/company/question/add-excel-question"}
                  style={{ width: "100% !important" }}
                >
                  Import spreadsheet(.CSV)
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <Link
                  to={"/company/question/add-word-question"}
                  style={{ width: "100%" }}
                >
                  Import word(.DOC)
                </Link>
              </MenuItem>
            </StyledMenu>
          </div>
          <div>
            <Button
              id="actions"
              aria-controls={openActions ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openActions ? "true" : undefined}
              variant="outlined"
              disableElevation
              onClick={handleActionsClick}
              className="text-[#202020] border-[#202020]"
            >
              Actions
            </Button>
            <StyledMenu
              id="actions-menu"
              MenuListProps={{
                "aria-labelledby": "actions",
              }}
              anchorEl={actions}
              open={openActions}
              onClose={handleActionsClose}
            >
              <MenuItem onClick={handleActionsClose} disableRipple>
                Export questions
              </MenuItem>
            </StyledMenu>
          </div></>}
        </div>
      </div>
      <div
        className="bg-white rounded-lg mt-[26px]"
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        <div className="px-[77px] py-[50px]">
          <div className="flex sm:flex-row flex-col gap-[14px] sm:items-center flex-wrap">
            <div className="w-max mt-3">
              <SortComponent />
            </div>
            <div className="w-full max-w-[380px]">
              <InputLabel>Question Type</InputLabel>
              <TextFieldDropdown
                id={"question_type"}
                value={questionFilters.question_type}
                onChange={(e) =>
                  setQuestionFilters({
                    ...questionFilters,
                    question_type: e.target.value,
                  })
                }
                placeholder={"Please Select"}
                options={[
                  {
                    name: "MCQ",
                    value: "MCQ",
                  },
                  {
                    name: "Multi Select",
                    value: "MultiCorrect",
                  },
                  {
                    name: "True False",
                    value: "TrueFalse",
                  },
                  {
                    name: "Reading Comprehension",
                    value: "reading_comprehension",
                  },
                ]}
                sx={{
                  "& .MuiSelect-select": {
                    paddingRight: 4,
                    paddingLeft: 2,
                    paddingTop: "13px",
                    paddingBottom: "13px",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex gap-8 mt-5">
            <div>
              <Button
                variant="contained"
                color="secondary"
                className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
                onClick={searchQuestions}
                disabled={loadings.searchQuestionsAsyncThunk}
              >
                {loadings.searchQuestionsAsyncThunk
                  ? "Filtering"
                  : "Filter Now"}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
              >
                Clear Filter
              </Button>
            </div>
          </div>
        </div>
        <div className="px-[77px] py-[50px] border-t-[1px] border-[#202020]">
          <p className="text-[#202020]">
            Question Level Fields for Reporting (Multiple Choice, Multiple
            Response and True False )
          </p>
          <div className="mt-[30px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9">
            {fields.map((filter) => {
              if (filter.select) {
                return (
                  <div className={filter?.className} key={filter.id}>
                    <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                      {filter.inputLabel}
                    </InputLabel>
                    <TextFieldDropdown
                      id={filter.id}
                      options={filter.options}
                      placeholder={filter.placeholder}
                      disablePlaceholder={true}
                      value={questionFilters[filter.id]}
                      onChange={(e) =>
                        setQuestionFilters({
                          ...questionFilters,
                          [filter.id]: e.target.value,
                        })
                      }
                    />
                  </div>
                );
              } else if (filter.id === "search") {
                return (
                  <div className={filter?.className} key={filter.id}>
                    <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                      {filter.inputLabel}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id={filter.id}
                      placeholder={filter.placeholder}
                      slotProps={{
                        input: {
                          endAdornment: <SearchSvg />,
                        },
                      }}
                      value={questionFilters[filter.id]}
                      onChange={(e) =>
                        setQuestionFilters({
                          ...questionFilters,
                          [filter.id]: e.target.value,
                        })
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div className={filter?.className} key={filter.id}>
                    <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                      {filter.inputLabel}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id={filter.id}
                      placeholder={filter.placeholder}
                      value={questionFilters[filter.id]}
                      onChange={(e) =>
                        setQuestionFilters({
                          ...questionFilters,
                          [filter.id]: e.target.value,
                        })
                      }
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="px-[77px] py-[25px] border-t-[1px] border-[#202020]">
          <div className="flex justify-between gap-3">
            <TablePerPage
              options={[10, 25, 50, 100]}
              value={limit || 10}
              onChange={(e) => {
                navigate({
                  pathname: "/company/question-bank",
                  search: `?limit=${e.target.value || 10}&page=1`,
                });
                dispatch(
                  searchQuestionsAsyncThunk({
                    page: 1,
                    limit: e.target.value,
                    ...questionFilters,
                  })
                );
              }}
              id={"question-bank"}
            />
            <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
              Count: {questionBank?.total}
            </p>
          </div>
          <div className="mt-5">
            {questionBank?.total > limit && (
              <PaginationButtons
                dataLength={questionBank?.questions?.length}
                total={questionBank?.total}
                pathname={"/company/question-bank"}
                asyncThunk={searchQuestionsAsyncThunk}
                asyncThunkData={{ limit: +limit, ...questionFilters }}
              />
            )}
          </div>
          <div className="my-20 flex flex-col gap-5">
            {questionBank?.questions?.map((question, ind) =>
              !question?.question_type ? (
                <>
                <ComprehensionQuestionCard
                  passage={question}
                  index={ind}
                  testId={id}
                  hideAdd={true}
                  assignToTest={question.assignedToTest}
                  key={ind}
                  setCardShow ={(questionId) => {
                    console.log("question--->", questionId)
                    setCardShow((prev) => ({
                      [questionId]: !prev[questionId], // Toggle current card
                    }));
                  }}
                  path={() => dispatch(
                    searchQuestionsAsyncThunk({
                      page: 1,
                      limit: limit,
                      ...questionFilters,
                    })
                  )}
                />
                </>
              ) : (
                <QuestionCard question={question} cardShow={cardShow}  setCardShow ={(questionId) => {
                    console.log("question--->", questionId)
                    setCardShow((prev) => ({
                      [questionId]: !prev[questionId], // Toggle current card
                    }));
                  }} assignToTest={question.assignedToTest} index={ind} key={ind} testId={id} dispatchFun={() =>  dispatch(searchQuestionsAsyncThunk({
                  page: 1,
                  limit: limit,
                  ...questionFilters,
                }))} />
              )
            )}
          </div>
          {questionBank?.total > limit && (
            <PaginationButtons
              dataLength={questionBank?.questions?.length}
              total={questionBank?.total}
              pathname={"/company/question-bank"}
              asyncThunk={searchQuestionsAsyncThunk}
              asyncThunkData={{ limit: +limit, ...questionFilters }}
            />
          )}
        </div>
      </div>
    {/* // </DashboardLayout> */}
    </>
  );
}
