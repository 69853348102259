import { Button, InputLabel, TextField } from "@mui/material";
import { excelStepTwoFields } from "../../constants/addQuestionFields";
import TextFieldDropdown from "../Dropdown/Index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../../redux/slices/dropdowns/dropdownSlice";
import UploadFile from "../UploadFile/Index";
import { getIn, useFormik } from "formik";
import { excelStepTwoSchema } from "../../utils/yup/questionSchema.ts";
import * as XLSX from "xlsx";
import { addExcelQuestion } from "../../redux/slices/package/packageSlice.js";

export default function ExcelStepTwo({ activeStep, setActiveStep }) {
  const [fields, setFields] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const subjects = useSelector((store) => store.dropdown.subjects);

  const initialValues = {
    questions: [
      {
        question_text: "",
        question_type: "",
        subtopic: "",
        is_image: "false",
        subject: "",
        grade: "",
        explanation: "",
        difficulty_level: "",
        topic: "",
        exam_type_id: "",
        quality_checked: "true",
        options: Array.from({ length: 2 }, () => ({
          option_text: "",
          is_correct: false,
          is_image_present: "false",
        })),
      },
    ],
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: excelStepTwoSchema,
    enableReinitialize: true,
    // onSubmit: async (values, action) => {
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const data = event.target.result;
    //       const workbook = XLSX.read(data, { type: "binary" });

    //       const sheetName = workbook.SheetNames[0];
    //       const worksheet = workbook.Sheets[sheetName];

    //       // Using the 'raw' option to prevent automatic conversion
    //       const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });

    //       const formattedQuestions = json.map((row) => {
    //         const correctAnswers = row["Answer"]
    //           ? row["Answer"].split(",").map((ans) => ans.trim())
    //           : [];

    //         const options = ["A", "B", "C", "D", "E", "F"]
    //           .map((option) => {
    //             const regex = new RegExp(`^\\s*Choice\\s*${option}\\s*$`, "i");
    //             const matchingKey = Object.keys(row).find((key) =>
    //               regex.test(key)
    //             );

    //             if (matchingKey) {
    //               let optionText = row[matchingKey].toString();

    //               return {
    //                 option_text: optionText.trim(),
    //                 is_correct: correctAnswers.includes(option)
    //                   ? "true"
    //                   : "false",
    //                 is_image_present: "false",
    //               };
    //             }
    //             return null;
    //           })
    //           .filter((option) => option !== null);

    //         return {
    //           question_text: row.Question ? row.Question.trim() : "",
    //           options: options,
    //           explanation: row.Explanation ? row.Explanation.trim() : "",
    //           topic: row.Topic ? row.Topic.trim() : "",
    //           subtopic: row.SubTopic ? row.SubTopic.trim() : "",
    //         };
    //       });
    //       console.log(formattedQuestions);
    //       const result = formattedQuestions.map((ele, ind) => ({
    //         question_text: ele?.question_text,
    //         question_type: values.questions[0].question_type,
    //         subtopic: ele?.subtopic,
    //         is_image: "false",
    //         subject: values.questions[0].subject,
    //         grade: values.questions[0].grade,
    //         explanation: ele?.explanation,
    //         difficulty_level: values.questions[0].difficulty_level,
    //         topic: ele?.topic,
    //         exam_type_id: values.questions[0].exam_type_id,
    //         quality_checked: "true",
    //         options: ele?.options,
    //       }));
    //       dispatch(addExcelQuestion({ questions: result }));
    //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     };

    //     reader.readAsBinaryString(file);
    //   }
    // },
    onSubmit: async (values, action) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Using the 'raw' option to prevent automatic conversion
          const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });

          const formattedQuestions = json.map((row) => {
            const correctAnswers = row["Answer"]
              ? row["Answer"].split(",").map((ans) => ans.trim())
              : [];

            const options = ["A", "B", "C", "D", "E", "F"]
              .map((option) => {
                const regex = new RegExp(`^\\s*Choice\\s*${option}\\s*$`, "i");
                const matchingKey = Object.keys(row).find((key) =>
                  regex.test(key)
                );

                if (matchingKey) {
                  let optionText = row[matchingKey].toString();
                  if (optionText) {
                    return {
                      option_text: optionText.trim(),
                      is_correct: correctAnswers.includes(option)
                        ? "true"
                        : "false",
                      is_image_present: "false",
                    };
                  }
                }
                return null;
              })
              .filter((option) => option !== null);

            // Read the "Question Type" and map to the corresponding values
            let questionType = row["Question Type"]
              ? row["Question Type"].trim()
              : "";

            if (questionType.toLowerCase() === "multiplechoice") {
              questionType = "MCQ";
            } else if (questionType.toLowerCase() === "multipleresponse") {
              questionType = "MultiCorrect";
            } else if (questionType.toLowerCase() === "truefalse") {
              questionType = "TrueFalse";
            }

            return {
              question_text: row.Question ? row.Question.trim() : "",
              options: options,
              explanation: row.Explanation ? row.Explanation.trim() : "",
              topic: row.Topic ? row.Topic.trim() : "",
              subtopic: row.SubTopic ? row.SubTopic.trim() : "",
              question_type: questionType, // Mapped question type
            };
          });

          console.log(formattedQuestions);

          const result = formattedQuestions.map((ele, ind) => ({
            question_text: ele?.question_text,
            question_type: ele?.question_type, // Use the mapped question type
            subtopic: ele?.subtopic,
            is_image: "false",
            subject: values.questions[0].subject,
            grade: values.questions[0].grade,
            explanation: ele?.explanation,
            difficulty_level: values.questions[0].difficulty_level,
            topic: ele?.topic,
            exam_type_id: values.questions[0].exam_type_id,
            quality_checked: "true",
            options: ele?.options,
          }));

          dispatch(addExcelQuestion({ questions: result }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        reader.readAsBinaryString(file);
      }
    },
  });

  useEffect(() => {
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);

  useEffect(() => {
    const values = excelStepTwoFields(examTypes, subjects);
    setFields(values);
  }, [examTypes, subjects]);

  return (
    <div
      className="w-full max-w-[1000px] mx-auto p-10 bg-white rounded-[10px] mt-20"
      style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-5">
          {fields?.map((filter) => {
            if (filter.select) {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={`questions[0][${filter.id}]`}
                    options={filter.options}
                    value={values.questions[0][filter.id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      getIn(touched, `questions[0][${filter.id}]`) &&
                      getIn(errors, `questions[0][${filter.id}]`)
                    }
                    helperText={
                      getIn(touched, `questions[0][${filter.id}]`) &&
                      getIn(errors, `questions[0][${filter.id}]`)
                    }
                    disablePlaceholder={true}
                    placeholder={filter.placeholder}
                  />
                </div>
              );
            } else {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={`questions[0][${filter.id}]`}
                    placeholder={filter.placeholder}
                    value={values.questions[0][filter.id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      getIn(touched, `questions[0][${filter.id}]`) &&
                      getIn(errors, `questions[0][${filter.id}]`)
                    }
                    helperText={
                      getIn(touched, `questions[0][${filter.id}]`) &&
                      getIn(errors, `questions[0][${filter.id}]`)
                    }
                  />
                </div>
              );
            }
          })}
          <div>
            <UploadFile
              file={file}
              setFile={setFile}
              accept={
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
            />
            {getIn(touched, `questions[0].question_text`) && !file && (
              <p className="text-xs text-[#d32f2f] mt-1 ml-3">
                Please select an excel file to add questions
              </p>
            )}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="text-xs font-semibold leading-6 py-1.5 px-2.5 normal-case"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
