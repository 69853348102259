import { Button, InputLabel, TextField } from "@mui/material";
import {
  comprehensionFields,
  comprehensionStepTwoFields,
} from "../../constants/addQuestionFields";
import TextFieldDropdown from "../Dropdown/Index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../../redux/slices/dropdowns/dropdownSlice";
import UploadFile from "../UploadFile/Index";
import { getIn, useFormik } from "formik";
import { wordStepTwoSchema } from "../../utils/yup/questionSchema.ts";
import { addWordQuestions } from "../../redux/slices/package/packageSlice";
import mammoth from "mammoth";

export default function WordStepTwo({ activeStep, setActiveStep }) {
  const [file, setFile] = useState(null);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const subjects = useSelector((store) => store.dropdown.subjects);

  const initialValues = {
    passage_text: "",
    subtopic: "",
    is_image: "false",
    subject_id: "",
    grade: "",
    complexity: "",
    topic: "",
    exam_type_id: "",
    quality_checked: "true",
    total_questions: 1,
    questions: Array.from({ length: 1 }, () => ({
      question_text: "",
      subtopic: "",
      is_image: "false",
      subject: "",
      grade: "",
      question_type: "mcq",
      explanation: "",
      difficulty_level: "",
      topic: "",
      exam_type_id: "",
      quality_checked: "true",
      options: Array.from({ length: 6 }, () => ({
        answer_text: "",
        is_correct: "false",
        is_image_present: "false",
      })),
    })),
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: wordStepTwoSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      console.log("on submit");
      if (file) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const arrayBuffer = event.target.result;
            const result = await mammoth.extractRawText({
              arrayBuffer: arrayBuffer,
            });
            const text = result.value;
            const data = parseTextToJSON(text);
            const res = {
              passage_text: data?.passage_text,
              subtopic: data?.subtopic,
              is_image: "false",
              subject_id: values.subject_id,
              grade: values.grade,
              complexity: values.complexity,
              topic: data.topic,
              exam_type_id: values.exam_type_id,
              quality_checked: "true",
              total_questions: values.total_questions,
              questions: data?.questions.map((qu) => ({
                question_text: qu?.question_text,
                question_type: "mcq",
                subtopic: qu?.subtopic,
                is_image: "false",
                subject: qu?.subject,
                grade: qu?.grade,
                explanation: qu?.correct_feedback,
                difficulty_level: qu?.difficulty_level,
                topic: qu?.topic,
                exam_type_id: qu?.exam_type_id,
                quality_checked: "true",
                options: qu?.options?.map((op) => {
                  return {
                    is_image_present: "false",
                    ...op,
                  };
                }),
              })),
            };
            dispatch(addWordQuestions(res));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } catch (error) {
            console.error("Error reading file:", error);
          }
        };
        reader.readAsArrayBuffer(file);
      }

      const parseTextToJSON = (text) => {
        let lines = text
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line);
        let currentIndex = 0;
        let passage_text = "",
          topic = "",
          subtopic = "";
        let questions = [],
          currentQuestion = null,
          options = [];

        while (currentIndex < lines.length) {
          let line = lines[currentIndex];

          if (line === "Text###") {
            passage_text = lines[++currentIndex];
            while (lines[++currentIndex] !== "End of Text###") {
              passage_text += " " + lines[currentIndex];
            }
          } else if (line === "Text Topic###") {
            topic = lines[++currentIndex];
          } else if (line === "Text SubTopic###") {
            subtopic = lines[++currentIndex];
          } else if (line.startsWith("Question###")) {
            if (currentQuestion) {
              currentQuestion.options = options.filter(
                (option) => option.answer_text
              );
              questions.push(currentQuestion);
            }
            currentQuestion = {
              question_text: line.split("###")[1],
              options: [],
              topic: "",
              subtopic: "",
              correct_feedback: "",
            };
            options = [];
          } else if (line.startsWith("Choice") && line.includes("#:")) {
            const [label, optionText] = line.split("#:");
            if (optionText) {
              options.push({ answer_text: optionText, is_correct: "false" });
            }
          } else if (line.startsWith("Correct#:")) {
            const correctAnswer = line.split("#:")[1].trim();
            options.forEach((option, i) => {
              if (String.fromCharCode(65 + i) === correctAnswer) {
                option.is_correct = "true";
              }
            });
          } else if (line.startsWith("Explanation#:")) {
            currentQuestion.explanation = line.split("#:")[1].trim();
          } else if (line.startsWith("Question Topic#:")) {
            currentQuestion.topic = line.split("#:")[1].trim();
          } else if (line.startsWith("Question SubTopic#:")) {
            currentQuestion.subtopic = line.split("#:")[1].trim();
          } else if (line.startsWith("End of Question###")) {
            if (currentQuestion) {
              currentQuestion.options = options.filter(
                (option) => option.answer_text
              );
              questions.push(currentQuestion);
              currentQuestion = null;
              options = [];
            }
          }
          currentIndex++;
        }
        return {
          passage_text,
          topic,
          subtopic,
          questions,
        };
      };
    },
  });

  useEffect(() => {
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);

  useEffect(() => {
    const values = comprehensionStepTwoFields(examTypes, subjects);
    setFields(values);
  }, [examTypes, subjects]);

  return (
    <div
      className="w-full max-w-[11000px] mx-auto p-10 bg-white rounded-[10px] mt-20"
      style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
    >
      <p className="italic font-medium leading-6 mb-5">
        Question Level Information For Reporting
      </p>
      <form onSubmit={handleSubmit}>
        <UploadFile
          file={file}
          setFile={setFile}
          accept={
            ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          }
        />
        {touched.passage_text && !file && (
          <p className="text-xs text-[#d32f2f] mt-1 ml-3">
            Please select a word file to add questions
          </p>
        )}
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-5">
          {fields?.map((filter) => {
            if (filter.select) {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={filter.id}
                    options={filter.options}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                    disablePlaceholder={true}
                    placeholder={filter.placeholder}
                  />
                </div>
              );
            } else {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={filter.id}
                    placeholder={filter.placeholder}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                  />
                </div>
              );
            }
          })}
          <div className="md:col-span-3 flex justify-center">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className="text-xs font-semibold leading-6 py-1.5 px-2.5 normal-case max-w-[244px]"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
