import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { authorizatonCommonFun } from "../../../utils/basicService/index.js";

export const getQuestionAsyncThunk = createAsyncThunk(
  "auth/getQuestionAsyncThunk",
  asyncWrapper(async ({ id, questionType }, thunk) => {
    if (questionType === "comprehension") {
      try {
        const response = await ApiRequest.getComprehension(id);
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    } else {
      try {
        const response = await ApiRequest.getQuestion(id);
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    }
  })
);

export const archieveQuestionAsyncThunk = createAsyncThunk(
  "auth/archieveQuestionAsyncThunk",
  asyncWrapper(async ({ data, id, questionType }, thunk) => {
    if (questionType === "comprehension") {
      try {
        const response = await ApiRequest.editComprehension({ data, id });
        if (response.status === 200) {
          toast.success("Question archieve successfully!");
          // navigate("/company/question-bank");
        }
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    } else {
      try {
        const response = await ApiRequest.editQuestion({ data, id });
        if (response.status === 200) {
          toast.success("Question archieve successfully!");
          // navigate("/company/question-bank");
        }
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    }
  })
);

export const editQuestionAsyncThunk = createAsyncThunk(
  "auth/editQuestionAsyncThunk",
  asyncWrapper(async ({ data, id, questionType, navigate }, thunk) => {
    if (questionType === "comprehension") {
      try {
        const response = await ApiRequest.editComprehension({ data, id });
        if (response.status === 200) {
          toast.success("Question updated successfully!");
          navigate("/company/question-bank");
        }
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    } else {
      try {
        const response = await ApiRequest.editQuestion({ data, id });
        if (response.status === 200) {
          toast.success("Question updated successfully!");
          navigate("/company/question-bank");
        }
        return response.data;
      } catch (e) {
        authorizatonCommonFun(e);
        toast.error(e.response.data.message);
      }
    }
  })
);

export const deletePackageAsyncThunk = createAsyncThunk(
  "auth/deletePackageAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    try {
      const response = await ApiRequest.deletePackage(id);
      if (response.status === 200) {
        toast.success("Package delete successfully!");
        // thunk.dispatch(getPackagesAsyncThunk());
      }
      return response.data;
    } catch (e) {
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
    }
  })
);
export const deleteQuestionAsyncThunk = createAsyncThunk(
  "auth/deleteQuestionAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    try {
      const response = await ApiRequest.deleteQuestion(id);
      if (response.status == 200) {
        toast.success("Question deleted successfully!");
        // thunk.dispatch(searchQuestionsAsyncThunk());
      }
      return response.data;
    } catch (e) {
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
    }
  })
);

export const searchQuestionsAsyncThunk = createAsyncThunk(
  "auth/searchQuestionsAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    try {
      const response = await ApiRequest.searchQuestions(data);
      return response.data;
    } catch (e) {
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
    }
  })
);

const initialState = {
  question: {},
  questionBank: [],
  loadings: {
    getQuestionAsyncThunk: false,
    editQuestionAsyncThunk: false,
    searchQuestionsAsyncThunk: false,
    deleteQuestionAsyncThunk: false,
  },
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  extraReducers: (builder) => {
    builder
      // questions
      .addCase(getQuestionAsyncThunk.fulfilled, (state, action) => {
        state.question = action.payload;
      })
      .addCase(searchQuestionsAsyncThunk.fulfilled, (state, action) => {
        state.questionBank = action.payload;
      })
      // .addCase(deleteQuestionAsyncThunk.fulfilled, (state, action) => {
      //   console.log("action", action.payload);
      //   if (state.questionBank?.[0]?.passage_id) {
      //     state.questionBank?.filter(
      //       (qu) => qu?.passage_id != action?.payload?.passage_id
      //     );
      //   } else {
      //     state.questionBank?.filter(
      //       (qu) => qu?.question_id != action?.payload?.question_id
      //     );
      //   }
      // })
      .addMatcher(
        isAnyOf(
          getQuestionAsyncThunk,
          editQuestionAsyncThunk,
          deleteQuestionAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default questionSlice.reducer;
export const { setUser } = questionSlice.actions;
