export const questionBankFilters = (examTypes, subjects, topics, subtopics) => [
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    multiline: false,
    id: "grade",
    placeholder: "Please select a grade",
    className: "md:col-span-2",
    options: [
      {
        name: 1,
        value: 1,
      },
      {
        name: 2,
        value: 2,
      },
      {
        name: 3,
        value: 3,
      },
      {
        name: 4,
        value: 4,
      },
      {
        name: 5,
        value: 5,
      },
      {
        name: 6,
        value: 6,
      },
      {
        name: 7,
        value: 7,
      },
      {
        name: 8,
        value: 8,
      },
      {
        name: 9,
        value: 9,
      },
      {
        name: 10,
        value: 10,
      },
      {
        name: 11,
        value: 11,
      },
      {
        name: 12,
        value: 12,
      },
    ],
  },
  {
    inputLabel: "Question Complexity",
    type: "text",
    select: true,
    multiline: false,
    id: "difficulty_level",
    placeholder: "Select question complexity",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    multiline: false,
    id: "subject",
    placeholder: "Select a subject",
    options: subjects?.map((sb) => ({ name: sb?.subject_name, value: sb?.id })),
  },
  {
    inputLabel: "Is image present in Question",
    type: "text",
    select: true,
    multiline: false,
    id: "is_image",
    placeholder: "Select an option",
    className: "md:col-span-2",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Topic",
    type: "text",
    select: true,
    multiline: false,
    id: "topic",
    placeholder: "Select a topic",
    options: topics?.map((tp) => ({ name: tp, value: tp })),
  },
  {
    inputLabel: "Sub-Topic",
    type: "text",
    select: true,
    multiline: false,
    id: "subtopic",
    placeholder: "Select a sub topic",
    options: topics?.map((st) => ({ name: st, value: st })),
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    multiline: false,
    id: "exam_type_id",
    placeholder: "Select an exam type",
    className: "md:col-span-2",
    options: examTypes?.map((ex) => ({ name: ex?.exam_name, value: ex?.id })),
  },
  {
    inputLabel: "Quality Checked?",
    type: "text",
    select: true,
    multiline: false,
    id: "quality_checked",
    placeholder: "Select question quality",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Question Created By",
    type: "text",
    select: false,
    multiline: false,
    id: "created_by",
    placeholder: "Write question created by",
  },
  {
    inputLabel: "Search Topic",
    type: "text",
    select: false,
    multiline: false,
    id: "search",
    placeholder: "Search Topic",
    className: "md:col-span-2",
  },
  {
    inputLabel: "Question ID:",
    type: "text",
    select: false,
    multiline: false,
    id: "question_id",
    placeholder: "Enter a question ID",
  },
];
