import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index";
import QuestionSectionHeading from "../QuestionSectionHeading/Index";
import {
  addQuestionFields,
  comprehensionFields,
} from "../../constants/addQuestionFields";
import { getIn, useFormik } from "formik";
import { addComprehensionAsyncThunk } from "../../redux/slices/package/packageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { comprehensionQuestionSchema } from "../../utils/yup/questionSchema.ts";
import ComprehensionOptionSelect from "../ComprehensionSelect/Index.jsx";
import { editQuestionAsyncThunk } from "../../redux/slices/question/questionSlice.js";
const DynamicEditor = lazy(() => import("../tinyEditor/Index"));

export default function ComprehensionQuestion({
  examTypes,
  subjects,
  questionType,
  testId
}) {
  const [fields, setFields] = useState([]);
  const [questionFields, setQuestionFields] = useState([]);
  const [correctOptionId, setCorrectOptionId] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const redirectFlag = useRef(true);
  const loading = useSelector(
    (store) => store.packages.loadings.addComprehensionAsyncThunk
  );
  const { question } = useSelector((store) => store.question);
  const isEdit = (id && pathname.includes("edit")) || false;
  const initialValues = {
    passage_text: isEdit ? question.passage_text || "" : "",
    subtopic: isEdit ? question.subtopic || "" : "",
    is_image: isEdit ? question.is_image || "false" : "false",
    subject_id: isEdit ? question.subject_id || "" : "",
    grade: isEdit ? question.grade || "" : "",
    complexity: isEdit ? question.complexity || "" : "",
    topic: isEdit ? question.topic || "" : "",
    exam_type_id: isEdit ? question.exam_type_id || "" : "",
    quality_checked: isEdit ? question.quality_checked || "true" : "true",
    total_questions: isEdit ? question.total_questions || 10 : 10,
    questions: Array.from({ length: 10 }, (_, index) => ({
      question_text: isEdit
        ? question.questions?.[index]?.question_text || ""
        : "",
      subtopic: isEdit ? question.questions?.[index]?.subtopic || "" : "",
      is_image: isEdit
        ? question.questions?.[index]?.is_image || "false"
        : "false",
      subject: isEdit ? question.questions?.[index]?.subject || "" : "",
      grade: isEdit ? question.questions?.[index]?.grade || "" : "",
      explanation: isEdit
        ? question.questions?.[index]?.explanation || ""
        : "",
      difficulty_level: isEdit
        ? question.questions?.[index]?.difficulty_level || ""
        : "",
      topic: isEdit ? question.questions?.[index]?.topic || "" : "",
      exam_type_id: isEdit
        ? question.questions?.[index]?.exam_type_id || ""
        : "",
      quality_checked: isEdit
        ? question.questions?.[index]?.quality_checked || "true"
        : "true",
      options: Array.from({ length: 6 }, (__, optionIndex) => ({
        answer_text: isEdit
          ? question.questions?.[index]?.options?.[optionIndex]?.answer_text ||
            ""
          : "",
        is_correct: isEdit
          ? question.questions?.[index]?.options?.[optionIndex]?.is_correct ||
            "false"
          : "false",
        is_image_present: isEdit
          ? question.questions?.[index]?.options?.[optionIndex]
              ?.is_image_present || "false"
          : "false",
      })),
    })),
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: comprehensionQuestionSchema,
    enableReinitialize: true,
    onSubmit: async (values, action, custom) => {
      const { sub_topic, ...rest } = values;
      const test_id = testId?Number(testId):null;
      let data;
      if(test_id===null) {
         data = {
          max_questions_per_passage: values.total_questions,
          subtopic: sub_topic,
          ...rest,
        };
      } else {
         data = {
          max_questions_per_passage: values.total_questions,
          subtopic: sub_topic,
          test_id,
          ...rest,
        };
      }
   
      if (isEdit) {
        dispatch(
          editQuestionAsyncThunk({
            data,
            id,
            questionType: "comprehension",
            navigate,
          })
        );
      } else {
        dispatch(
          addComprehensionAsyncThunk({
            data,
            action,
            setCorrectOptionId,
            addMore: redirectFlag.current,
            navigate,
          })
        );
        redirectFlag.current = true;
      }
    },
  });

  const addMoreHandle = () => {
    redirectFlag.current = false;
    handleSubmit();
  };

  useEffect(() => {
    if (subjects?.length > 0 && examTypes?.length > 0) {
      const data = comprehensionFields(examTypes, subjects);
      const questionData = addQuestionFields(subjects, examTypes);
      setFields(data);
      setQuestionFields(questionData);
    }
    if (isEdit) {
      question?.questions?.forEach((ques, ind) => {
        ques?.options?.forEach((op, i) => {
          if (op?.is_correct == "true") {
            setCorrectOptionId((prevCorrectOptionId) => [
              ...prevCorrectOptionId,
              `questions[${ind}].options[${i}].is_correct`,
            ]);
          }
        });
      });
    }
  }, [subjects, examTypes]);

  // useEffect(() => {
  //   setFieldValue(
  //     "questions",
  //     Array.from({ length: values.total_questions }, () => ({
  //       question_text: "",
  //       question_type: questionType,
  //       subtopic: "",
  //       is_image: "false",
  //       subject: "",
  //       grade: "",
  //       question_type: "mcq",
  //       explanation: "",
  //       difficulty_level: "",
  //       topic: "",
  //       exam_type_id: "",
  //       quality_checked: "true",
  //       options: Array.from({ length: 6 }, () => ({
  //         answer_text: "",
  //         is_correct: "false",
  //         is_image_present: "false",
  //       })),
  //     }))
  //   );
  // }, [values.total_questions]);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-10">
        <QuestionSectionHeading
          index={2}
          heading={"Enter Your Paragraph"}
          paragragh={"Enter your paragraph"}
        />
        <div>
          <Suspense fallback={"Loading..."}>
            <DynamicEditor
              id={"passage_text"}
              value={values.passage_text}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            />
          </Suspense>
          {touched.passage_text && errors.passage_text && (
            <p className="text-[#d32f2f] text-xs">{errors.passage_text}</p>
          )}
        </div>
        <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
          Paragraph Level Information For Reporting{" "}
        </p>
        <div className="mt-[26px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8">
          {fields?.map((filter) => {
            if (filter.select) {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={filter.id}
                    options={filter.options}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                    disablePlaceholder={true}
                    placeholder={filter.placeholder}
                  />
                </div>
              );
            } else {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={filter.id}
                    placeholder={filter.placeholder}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className="flex gap-11">
          <QuestionSectionHeading
            index={3}
            heading={"Write Your Questions"}
            paragragh={""}
          />
          <QuestionSectionHeading
            index={4}
            heading={"Chooese Options"}
            paragragh={""}
          />
          <QuestionSectionHeading
            index={5}
            heading={"Give Answers"}
            paragragh={""}
          />
        </div>
        {/* <div className="flex gap-3 justify-between mt-3">
          <div className="col-span-2 max-w-max flex justify-center gap-5">
            <div>
              <Button
                variant="contained"
                color="secondary"
                className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
                type="button"
                disabled={ind == 0}
                onClick={() => setind((pre) => pre - 1)}
              >
                Previous
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
                type="button"
                onClick={() => setind((pre) => pre + 1)}
                disabled={ind + 1 == values?.total_questions}
              >
                Next
              </Button>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="leading-5 text-sm font-bold font-poppins capitalize py-1 px-[10px] bg-[#FB3311]"
              type="button"
            >
              Questions {ind + 1} of {values.questions.length}
            </Button>
          </div>
        </div> */}
        {values?.questions?.map((qu, ind) => (
          <div key={ind}>
            <p className="mb-5 pb-5 border-b border-solid border-[#202020] text-[#202020] leading-6 font-medium">
              Question No: {ind + 1}
            </p>
            <div>
              <Suspense fallback={"Loading..."}>
                <DynamicEditor
                  id={`questions[${ind}].question_text`}
                  value={values.questions[ind].question_text}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                />
              </Suspense>
              {getIn(touched, `questions[${ind}].question_text`) &&
                getIn(errors, `questions[${ind}].question_text`) && (
                  <p className="text-[#d32f2f] text-xs">
                    {getIn(errors, `questions[${ind}].question_text`)}
                  </p>
                )}
              <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
                Question Level Information For Reporting{" "}
              </p>
              <div className="mt-[26px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8">
                {questionFields?.map((filter) => {
                  if (filter.select) {
                    return (
                      <div key={filter.id}>
                        <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                          {filter.inputLabel}
                        </InputLabel>
                        <TextFieldDropdown
                          id={`questions[${ind}].${filter.id}`}
                          options={filter.options}
                          value={values.questions[ind][filter.id] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          helperText={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          disablePlaceholder={true}
                          placeholder={filter.placeholder}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={filter.id}>
                        <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                          {filter.inputLabel}
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`questions[${ind}].${filter.id}`}
                          placeholder={filter.placeholder}
                          value={values.questions[ind][filter.id] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          helperText={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <p className="leading-6 font-medium text-base my-5">Options</p>
            {values.questions[ind].options.map((option, index) => (
              <ComprehensionOptionSelect
                key={index}
                id={`questions[${ind}].options[${index}].answer_text`}
                ind={ind}
                index={index}
                option={option}
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                error={Boolean(
                  getIn(
                    touched,
                    `questions[${ind}].options[${index}].answer_text`
                  ) &&
                    getIn(
                      errors,
                      `questions[${ind}].options[${index}].answer_text`
                    )
                )}
                helperText={
                  getIn(
                    touched,
                    `questions[${ind}].options[${index}].answer_text`
                  ) &&
                  getIn(
                    errors,
                    `questions[${ind}].options[${index}].answer_text`
                  )
                }
                correctOptionId={correctOptionId}
                setCorrectOptionId={setCorrectOptionId}
                questionType={questionType}
              />
            ))}
            <p className="leading-6 font-medium text-base my-5">
              Give Feedback
            </p>
            <div className="ml-9">
              <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
                Explaination
              </InputLabel>
              <TextField
                fullWidth
                multiline={true}
                rows={3}
                id={`questions[${ind}].explanation`}
                className="max-w-[510px]"
                value={values.questions[ind].explanation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  getIn(touched, `questions[${ind}].explanation`) &&
                  getIn(errors, `questions[${ind}].explanation`)
                }
                helperText={
                  getIn(touched, `questions[${ind}].explanation`) &&
                  getIn(errors, `questions[${ind}].explanation`)
                }
              />
            </div>
            <p className="text-center mb-5 mt-10 pb-3 border-b border-solid border-[#202020] text-[#202020] leading-6 text-xs">
              End of Question {ind + 1}
            </p>
          </div>
        ))}
        {/* {ind + 1 == values.total_questions ? ( */}
        <div className="flex gap-8 mt-5">
          <div>
            <Button
              variant="contained"
              color="primary"
              className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
              type="button"
              disabled={loading}
              onClick={addMoreHandle}
            >
              Save and add more
            </Button>
          </div>
        </div>
        {/* ) : (
          <div className="flex gap-3 justify-between mt-10">
            <div className="col-span-2 max-w-max flex justify-center gap-5">
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
                  type="button"
                  disabled={ind == 0}
                  onClick={() => setind((pre) => pre - 1)}
                >
                  Previous
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className="leading-5 text-sm font-semibold font-poppins capitalize py-1 px-[10px]"
                  type="button"
                  onClick={() => setind((pre) => pre + 1)}
                  disabled={ind + 1 == values?.questions || errors.questions[ind]}
                >
                  Next
                </Button>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="leading-5 text-sm font-bold font-poppins capitalize py-1 px-[10px] bg-[#FB3311]"
                type="button"
              >
                Questions {ind + 1} of {values.questions.length}
              </Button>
            </div>
          </div>
        )} */}
      </form>
    </>
  );
}
