import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress, InputLabel } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsyncThunk } from "../redux/slices/auth/authSlice";
import { loginSchema } from "../utils/yup/authSchemas.ts";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default function ConfirmPasswordPage() {
  const user = useSelector((store) => store.auth.user);
  const { id } = useParams();
  // const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
 
  useEffect(() => {
    console.log("user", user)
    setTimeout(() => {
      if(user?.id != id) {
        navigate('/forgot-password');
      }
    }, 1000)
    
  }, [user])

  const initialValues = {
    password: "",
    confirmPassword: "",
    
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);



  const confirmPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required("password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: yup
      .string()
      .required("confirm password is required")
      .oneOf([yup.ref("password"), null], "Confirm Password must match"),
  });

  //   const dispatch = useDispatch();

    const onSubmit = async (values) => {
      // dispatch(loginAsyncThunk({ data: values, navigate }));
      try {
        delete values.confirmPassword;
        const response = await ApiRequest.changePassword(values, id);
        console.log("response", response)
        toast.success(response.data.message);
        navigate('/company/dashboard');
      } catch (e) {
        toast.error(e.response.data.message);
        console.log("Error", e.response.data.message);
      }
      
      console.log("id", id)
      console.log("values", values);
    }

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: confirmPasswordSchema,
      onSubmit,
    });

  return (
    <div className="flex items-center justify-center md:py-12 full-screen">
      <div className="md:w-full md:max-w-[600px]">
        <h1 className=" my-0 text-center font-Inter text-3xl font-bold text-black md:text-4xl lg:text-center">
          {"Reset Password"}
        </h1>
        <form className="mt-4 space-y-5 md:mt-14" onSubmit={handleSubmit}>
          <div>
            <InputLabel className="mb-2 font-poppins text-[#202020]">
              Password:
            </InputLabel>
            <TextField
              fullWidth
              data-testid="email"
              type={passwordVisible ? "text" : "password"}
              variant="outlined"
              color="secondary"
              placeholder="Password"
              borderRadius="20px"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: passwordVisible ? <p style={{cursor: "pointer"}} onClick={() => setPasswordVisible(!passwordVisible)}><VisibilityOffIcon  /></p> : <p style={{cursor: "pointer"}} onClick={() => setPasswordVisible(!passwordVisible)}><RemoveRedEyeIcon /></p>,
              }}
            />
          </div>
          <div>
            <InputLabel className="mb-2 font-poppins text-[#202020]">
              Confirm Password
            </InputLabel>
            <TextField
              fullWidth
              name="confirmPassword"
              type={confirmPasswordVisible ? "text" : "password"}
              variant="outlined"
              color="secondary"
              placeholder="Enter your confirm password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              id="confirmPassword"
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: confirmPasswordVisible ? <p style={{cursor: "pointer"}} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}><VisibilityOffIcon  /></p> : <p style={{cursor: "pointer"}} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}><RemoveRedEyeIcon /></p>,
              }}
              // InputProps={{
              //   endAdornment: <LockOpenIcon />,
              // }}
            />
          </div>

          <Button
            fullWidth
            variant="contained"
            data-testid="submit-btn"
            color="primary"
            type="submit"
            name="/login"
            //   disabled={loading}
            className="p-2.5 capitalize leading-6 text-base font-bold font-poppins"
          >
            {"Change Password"}
          </Button>
        </form>
      </div>
    </div>
  );
}
