import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index";
import QuestionSectionHeading from "../QuestionSectionHeading/Index";
import {
  addQuestionFields,
  comprehensionFields,
} from "../../constants/addQuestionFields";
import { getIn, useFormik } from "formik";
import { addComprehensionAsyncThunk } from "../../redux/slices/package/packageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  comprehensionQuestionSchema,
  wordStepThreeSchema,
} from "../../utils/yup/questionSchema.ts";
import ComprehensionOptionSelect from "../ComprehensionSelect/Index.jsx";
const DynamicEditor = lazy(() => import("../tinyEditor/Index"));

export default function WordStepThree({ questionType }) {
  const [fields, setFields] = useState([]);
  const [questionFields, setQuestionFields] = useState([]);
  const [correctOptionId, setCorrectOptionId] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const wordQuestions = useSelector((store) => store.packages.wordQuestions);
  const loading = useSelector(
    (store) => store.packages.loadings.addBulkQuestionsAsyncThunk
  );
  const initialValues = wordQuestions;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: wordStepThreeSchema,
    enableReinitialize: true,
    onSubmit: async (values, action, custom) => {
      const { sub_topic, ...rest } = values;
      const data = {
        max_questions_per_passage: values.total_questions,
        subtopic: sub_topic,
        ...rest,
      };
      // if (isEdit) {
      //   dispatch(editPackageAsyncThunk({ data: formData, id, navigate }));
      // } else {
      dispatch(
        addComprehensionAsyncThunk({
          data,
          action,
          setCorrectOptionId,
          addMore: false,
          navigate,
        })
      );
      // }
    },
  });

  useEffect(() => {
    if (subjects?.length > 0 && examTypes?.length > 0) {
      const data = comprehensionFields(examTypes, subjects);
      const questionData = addQuestionFields(subjects, examTypes);
      setFields(data);
      setQuestionFields(questionData);
    }
  }, [subjects, examTypes]);

  // useEffect(() => {
  //   setFieldValue(
  //     "questions",
  //     Array.from({ length: values.total_questions }, () => ({
  //       question_text: "",
  //       question_type: questionType,
  //       subtopic: "",
  //       is_image: "false",
  //       subject: "",
  //       grade: "",
  //       question_type: "mcq",
  //       explanation: "",
  //       difficulty_level: "",
  //       topic: "",
  //       exam_type_id: "",
  //       quality_checked: "true",
  //       options: Array.from({ length: 6 }, () => ({
  //         answer_text: "",
  //         is_correct: "false",
  //         is_image_present: "false",
  //       })),
  //     }))
  //   );
  //   setind(0);
  // }, [values.total_questions]);

  useEffect(() => {
    wordQuestions?.questions?.forEach((ques, ind) => {
      ques?.options?.forEach((op, i) => {
        if (op?.is_correct == "true") {
          setCorrectOptionId((prevCorrectOptionId) => [
            ...prevCorrectOptionId,
            `questions[${ind}].options[${i}].is_correct`,
          ]);
        }
      });
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-10">
        <QuestionSectionHeading
          index={2}
          heading={"Enter Your Paragraph"}
          paragragh={"Enter your paragraph"}
        />
        <div>
          <Suspense fallback={"Loading..."}>
            <DynamicEditor
              id={"passage_text"}
              value={values.passage_text}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
            />
          </Suspense>
          {touched.passage_text && errors.passage_text && (
            <p className="text-[#d32f2f] text-xs">{errors.passage_text}</p>
          )}
        </div>
        <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
          Paragraph Level Information For Reporting{" "}
        </p>
        <div className="mt-[26px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8">
          {fields?.map((filter) => {
            if (filter.select) {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={filter.id}
                    options={filter.options}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                    disablePlaceholder={true}
                    placeholder={filter.placeholder}
                  />
                </div>
              );
            } else {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={filter.id}
                    placeholder={filter.placeholder}
                    value={values[filter.id] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className="flex gap-11">
          <QuestionSectionHeading
            index={3}
            heading={"Write Your Questions"}
            paragragh={""}
          />
          <QuestionSectionHeading
            index={4}
            heading={"Chooese Options"}
            paragragh={""}
          />
          <QuestionSectionHeading
            index={5}
            heading={"Give Answers"}
            paragragh={""}
          />
        </div>
        {values?.questions?.map((qu, ind) => (
          <div>
            <p className="mb-5 pb-5 border-b border-solid border-[#202020] text-[#202020] leading-6 font-medium">
              Question No: {ind + 1}
            </p>
            <div>
              <Suspense fallback={"Loading..."}>
                <DynamicEditor
                  id={`questions[${ind}].question_text`}
                  value={values.questions[ind].question_text}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                />
              </Suspense>
              {getIn(touched, `questions[${ind}].question_text`) &&
                getIn(errors, `questions[${ind}].question_text`) && (
                  <p className="text-[#d32f2f] text-xs">
                    {getIn(errors, `questions[${ind}].question_text`)}
                  </p>
                )}
              <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
                Question Level Information For Reporting{" "}
              </p>
              <div className="mt-[26px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8">
                {questionFields?.map((filter) => {
                  if (filter.select) {
                    return (
                      <div key={filter.id}>
                        <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                          {filter.inputLabel}
                        </InputLabel>
                        <TextFieldDropdown
                          id={`questions[${ind}].${filter.id}`}
                          options={filter.options}
                          value={values.questions[ind][filter.id] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          helperText={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          disablePlaceholder={true}
                          placeholder={filter.placeholder}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={filter.id}>
                        <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                          {filter.inputLabel}
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`questions[${ind}].${filter.id}`}
                          placeholder={filter.placeholder}
                          value={values.questions[ind][filter.id] || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                          helperText={
                            getIn(
                              touched,
                              `questions[${ind}].${[filter.id]}`
                            ) &&
                            getIn(errors, `questions[${ind}].${[filter.id]}`)
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <p className="leading-6 font-medium text-base my-5">Options</p>
            {values.questions[ind].options.map((option, index) => (
              <ComprehensionOptionSelect
                key={index}
                id={`questions[${ind}].options[${index}].answer_text`}
                ind={ind}
                index={index}
                option={option}
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                error={Boolean(
                  getIn(
                    touched,
                    `questions[${ind}].options[${index}].answer_text`
                  ) &&
                    getIn(
                      errors,
                      `questions[${ind}].options[${index}].answer_text`
                    )
                )}
                helperText={
                  getIn(
                    touched,
                    `questions[${ind}].options[${index}].answer_text`
                  ) &&
                  getIn(
                    errors,
                    `questions[${ind}].options[${index}].answer_text`
                  )
                }
                correctOptionId={correctOptionId}
                setCorrectOptionId={setCorrectOptionId}
                questionType={questionType}
              />
            ))}
            <p className="leading-6 font-medium text-base my-5">
              Give Feedback
            </p>
            <div className="ml-9">
              <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
                Explaination
              </InputLabel>
              <TextField
                fullWidth
                multiline={true}
                rows={3}
                id={`questions[${ind}].explanation`}
                className="max-w-[510px]"
                value={values.questions[ind].explanation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  getIn(touched, `questions[${ind}].explanation`) &&
                  getIn(errors, `questions[${ind}].explanation`)
                }
                helperText={
                  getIn(touched, `questions[${ind}].explanation`) &&
                  getIn(errors, `questions[${ind}].explanation`)
                }
              />
            </div>
            <p className="text-center mb-5 mt-10 pb-3 border-b border-solid border-[#202020] text-[#202020] leading-6 text-xs">
              End of Question {ind + 1}
            </p>
          </div>
        ))}
        <div className="flex gap-8 mt-5">
          <div>
            <Button
              variant="contained"
              color="primary"
              className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
              type="submit"
              disabled={loading}
            >
              Save All Questions
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
