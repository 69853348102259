import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress, InputLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsyncThunk } from "../redux/slices/auth/authSlice";
import { loginSchema } from "../utils/yup/authSchemas.ts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from '@mui/icons-material/Lock';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from "react";
import ReactLoader from "../components/ReactLoader/index.js";

export default function Signin() {
  const loading = useSelector((store) => store.auth.loadings.loginAsyncThunk);

  const initialValues = {
    email: "",
    password: "",
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    dispatch(loginAsyncThunk({ data: values, navigate }));
  };

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit,
    });
useEffect(() => {
  console.log("loading", loading)
}, [loading])
  return (
<div className="flex items-center justify-center min-h-screen bg-gray-100 py-12">
  {loading && <ReactLoader color={'#a56bff'} />}
  <div className="md:w-full md:max-w-[600px] p-6 bg-white rounded-lg shadow-lg">
    <h1 className="text-center font-Inter text-2xl font-bold text-black md:text-5xl lg:text-center">
      Login to your account
    </h1>
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div>
        <InputLabel className="mb-2 font-poppins text-[#202020]">
          Email Id:
        </InputLabel>
        <TextField
          fullWidth
          data-testid="email"
          type="text"
          variant="outlined"
          color="secondary"
          placeholder="johndoe@gmail.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          name="email"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div>
        <InputLabel className="mb-2 font-poppins text-[#202020]">
          Your Password
        </InputLabel>
        <TextField
          fullWidth
          name="password"
          type={passwordVisible ? "text" : "password"}
          variant="outlined"
          color="secondary"
          placeholder="Enter your password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          id="password"
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: passwordVisible ? (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <VisibilityOffIcon />
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <RemoveRedEyeIcon />
              </p>
            ),
          }}
        />
      </div>
      <Link
        to="/forgot-password"
        className="flex justify-end font-poppins text-base font-bold leading-6 text-primary no-underline"
      >
        Forgot Password?
      </Link>
      <Button
        fullWidth
        variant="contained"
        data-testid="submit-btn"
        color="primary"
        type="submit"
        name="/login"
        disabled={loading}
        className="p-2.5 capitalize leading-6 text-base font-bold font-poppins"
      >
        {loading ? (
          <CircularProgress className="text-secodary" size={24} />
        ) : (
          "Login now"
        )}
      </Button>
    </form>
  </div>
</div>


  );
}
