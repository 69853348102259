import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import UsersTable from "../components/UsersTable/Index";
import { Button } from "@mui/material";
import AddUserModal from "../components/AddUser/AddUserModal";

export default function Users() {
  const [open, setOpen] = useState(false);
  const [userdata, setUserData] = useState(false);
   useEffect(() => {
     console.log("editUserData", userdata)
   }, [userdata]);
  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Users</h1>
      <div className="mt-10 max-w-[900px] mx-auto">
        <div className="flex justify-end mb-5">
          <Button
            variant="contained"
            color="primary"
            className="rounded-none leading-6 text-xs font-semibold font-poppins"
            startIcon={<PlusSvg />}
            onClick={() => setOpen(true)}
          >
            Add User
          </Button>
        </div>
        <UsersTable setOpen={setOpen} editUserData={setUserData} />
        <AddUserModal open={open} userData={userdata} setOpen={setOpen} setUserData={setUserData} />
      </div>
    </DashboardLayout>
  );
}
