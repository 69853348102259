import CheckCircleSvg from "@mui/icons-material/CheckCircle";

export default function BasicCardOne({ question, setValue, index, testId, dispatchFun, assignToTest, cardShow, setCardShow, onClick }) {

  return (
    <div style={{ boxShadow: "1px 8px 20px 0px #2020200F" }} onClick={onClick}>

      <div className="px-9">
      <p className="text-base font-semibold leading-6 text-[#202020DB]">
          Test Introduction {"optional"}
        </p>
        <p
          className="text-base leading-6 text-[#202020] border-b border-solid border-[#2020201A] py-5"
          dangerouslySetInnerHTML={{ __html: question?.question_text }}
        />
      </div>
      
    
    </div>
  );
}
