import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import EssayTable from "../components/EssaysTable/Index";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  createEssayAsyncThunk,
  getEssaysAsyncThunk,
  getPackageAsyncThunk,
} from "../redux/slices/package/packageSlice";

export default function CreateEssay() {
  const [value, setValue] = useState("");
  const essays = useSelector((store) => store.packages.essays);
  const packages = useSelector((store) => store.packages);
  const singlePackage = useSelector((store) => store?.package);
  const [packageName, setPackageName] = useState("");
  const [totalEssays, setTotalEssays] = useState(1);
  const [errors, setErrors] = useState({
    essay_name: null
  });

  const loading = useSelector(
    (store) => store.packages.loadings[createEssayAsyncThunk]
  );

  useEffect(() => {
    setPackageName(packages?.package?.package_name);
    setTotalEssays(packages?.package?.totalEssay)
     console.log("packages?.package_name-------->", packages?.package?.package_name)
  }, [packages?.package?.id])
  
  const [searchParams] = useSearchParams();
  const pckg = searchParams.get("package");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    console.log("handleSubmit", value === null)
    if(value==="") {
        setErrors({
          essay_name: "Essay names is required"
        })
    } else {
    const values = value.split("|");
    const data = {
      essay_name: values[0],
      essay_type: values[1],
      duration: Number(values[2]),
      package_id: Number(pckg),
      totalEssay:+totalEssays+1
    };
    dispatch(createEssayAsyncThunk({ data, pckg }));
    setValue("");
  }
  };
  useEffect(() => {
    console.log("pckg",pckg)
    dispatch(getEssaysAsyncThunk(pckg));
    // const pckgnum = Number(searchParams.get("package"));
    // const foundPackage = packages?.packages?.data?.find(
    //   (p) => p.package_id === pckgnum
    // );
    // if (foundPackage) {
    //   setPackageName(foundPackage.package_name);
    // }

    dispatch(getPackageAsyncThunk(pckg));
  }, [pckg]);

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading text-center">Add Essays To Package</h1>
      <div
        className="mt-10 max-w-[95%] mx-auto rounded-[5px] py-[60px] px-12 bg-white"
        style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-9 border-b border-[#202020] pb-10">
          <div>
            <div className="max-w-[336px]">
              <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                {"Package Name"}
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                value={packageName}
                aria-readonly
                id={"package_name"}
                placeholder={"Package name"}
              />
            </div>
            <div className="max-w-[336px] mt-6">
              <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                {"Total Essays Added to Package"}
              </InputLabel>
              <TextField
                fullWidth
                type="number"
                id={"total_essays"}
                defaultValue={essays.length}
              />
            </div>
          </div>
          <div className="max-w-[336px] row-span-2">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              {"Essay Names"}
            </InputLabel>
            <TextField
              fullWidth
              id={"essay_names"}
              multiline={true}
              rows={5}
              value={value}
              onChange={(e) => {setValue(e.target.value); setErrors({essay_name: ""})}}
            />
            {errors.essay_name && <p style={{color: "red"}}>{errors.essay_name}</p>}
            <Button
              fullWidth
              variant="contained"
              type="sumit"
              className="rounded-lg px-6 py-3 leading-6 text-[20px] font-semibold font-poppins mt-5 bg-[#52C479]"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </div>
        </div>
        <div>
          <p className="text-[#202020] leading-6 text-xl my-5">
            Essays in Package
          </p>
          <EssayTable essays={essays} packageid={pckg} />
        </div>
      </div>
    </DashboardLayout>
  );
}
