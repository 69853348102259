export const createPackageFields = (
  states,
  examTypes,
  examConductedBy,
  subjects
) => [
  {
    inputLabel: "Package Name",
    type: "text",
    select: false,
    multiline: false,
    name: "package_name",
    id: "package_name",
    placeholder: "Package name",
  },
  {
    inputLabel: "Package Description",
    type: "text",
    select: false,
    multiline: true,
    id: "package_description",
    name: "package_description",
    placeholder: "Package description",
  },
  {
    inputLabel: "Package Comments by Admin (Subjects included)",
    type: "text",
    select: false,
    multiline: false,
    id: "package_comments_by_admin",
    name: "package_comments_by_admin",
    placeholder: "Maths Package for VIC HAST",
    type: "text",
  },
  {
    inputLabel: "Discount Applied",
    type: "number",
    select: false,
    multiline: false,
    id: "discount_applied",
    name: "discount_applied",
    placeholder: "",
  },
  {
    inputLabel: "Price of Package",
    type: "number",
    min: 0,
    select: false,
    multiline: false,
    id: "price",
    name: "price",
    placeholder: "",
  },
  {
    inputLabel: "State",
    type: "text",
    select: true,
    multiline: false,
    id: "state_id",
    name: "state_id",
    placeholder: "Select a state",
    options: states.map((st) => ({ name: st.state_name, value: st.id })),
  },
  {
    inputLabel: "Package Duration",
    type: "text",
    select: true,
    multiline: false,
    id: "package_duration",
    name: "package_duration",
    placeholder: "Select duration",
    options: [
      {
        name: "3 months",
        value: 3,
      },
      {
        name: "6 months",
        value: 6,
      },
      {
        name: "9 months",
        value: 9,
      },
      {
        name: "12 months",
        value: 12,
      },
    ],
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    multiline: false,
    id: "exam_type",
    name: "exam_type",
    placeholder: "Select exam type",
    options: examTypes.map((ex) => ({ name: ex.exam_name, value: ex.exam_name })),
  },
  {
    inputLabel: "Subjects in Package",
    type: "text",
    select: false,
    multiline: false,
    id: "subjects_in_package",
    name: "subjects_in_package",
    placeholder: "Select a subject",
    options: subjects.map((sb) => ({ name: sb.subject_name, value: sb.subject_name })),
  },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    multiline: false,
    id: "grade",
    name: "grade",
    placeholder: "Select a grade",
    options: [
      {
        name: 1,
        value: 1,
      },
      {
        name: 2,
        value: 2,
      },
      {
        name: 3,
        value: 3,
      },
      {
        name: 4,
        value: 4,
      },
      {
        name: 5,
        value: 5,
      },
      {
        name: 6,
        value: 6,
      },
      {
        name: 7,
        value: 7,
      },
      {
        name: 8,
        value: 8,
      },
      {
        name: 9,
        value: 9,
      },
      {
        name: 10,
        value: 10,
      },
      {
        name: 11,
        value: 11,
      },
      {
        name: 12,
        value: 12,
      },
    ],
  },
  {
    inputLabel: "Published Package",
    type: "text",
    select: true,
    multiline: false,
    id: "is_active",
    placeholder: "Select an option",
    options: [
      {
        name: "Yes",
        value: true,
      },
      {
        name: "No",
        value: false,
      },
    ],
  },
  {
    inputLabel: "Package Quality Checked?",
    type: "text",
    select: true,
    multiline: false,
    id: "package_quality_checked",
    placeholder: "Select an option",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Is Package Free",
    type: "text",
    select: true,
    multiline: false,
    id: "is_package_free",
    placeholder: "Select an option",
    options: [
      {
        name: "No",
        value: "false",
      },
      {
        name: "Yes",
        value: "true",
      },
    ],
  },
  {
    inputLabel: "Test Type",
    type: "text",
    select: true,
    multiline: false,
    id: "test_type",
    placeholder: "Select test type",
    options: [
      {
        name: "Single Level",
        value: "Single Level",
      },
      {
        name: "Multiple Level",
        value: "Multiple Level",
      },
    ],
  },
  {
    inputLabel: "Number of Tests in Package",
    type: "text",
    select: true,
    multiline: false,
    id: "number_of_tests",
    placeholder: "Select number of tests",
    options: [
      {
        name: 10,
        value: 10,
      },
    ],
  },
  {
    inputLabel: "Package Assigned To?",
    type: "text",
    select: true,
    multiline: false,
    id: "package_assigned_to",
    placeholder: "Select assigned to",
    options: [
      {
        name: "Operator",
        value: "Operator",
      },
      {
        name: "Admin",
        value: "Admin",
      },
    ],
  },
  {
    inputLabel: "Essay",
    type: "text",
    select: true,
    multiline: false,
    name: "hasEssays",
    id: "hasEssays",
    placeholder: "Select eassy",
    options: [
      {
        name: "Yes",
        value: true,
      },
      {
        name: "No",
        value: false,
      },
    ],
  },
  {
    inputLabel: "Exam Conducted By",
    type: "text",
    select: true,
    multiline: false,
    id: "conducted_by_id",
    placeholder: "Select an option",
    options: examConductedBy.map((ex) => ({ name: ex.name, value: ex.id })),
  },
];
