import { ApiUrls } from "./apiUrls.ts";
import AxiosInstance from "./axiosIntance.ts";

export const ApiRequest = {
  // auth
  login: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.login}`, data),
  forgotPassword: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.forgotPassword}`, data),
  changePassword: async (data: any, id: any) =>
    await AxiosInstance.put(`${ApiUrls.confirmPassword}/${id}`, data),
  allUsers: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.search}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  deleteUser: async (id: any) =>
    await AxiosInstance.delete(`${ApiUrls.userdelete}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createUser: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.createUser}`, data),
  updateUser: async (data: any, id: any) => {
    return await AxiosInstance.put(`${ApiUrls.search}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  createAdminUser: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.createAdminUser}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  verificationUser: async (id: any, data: object) =>
    await AxiosInstance.put(`${ApiUrls.verificationUser}/${id}`, data),
  // packages
  getPackages: async (data) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.getPackages}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  getPackage: async (data: string) =>
    await AxiosInstance.get(`${ApiUrls.getPackage}/${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getPublishPackage: async (data: string) =>
    await AxiosInstance.get(`${ApiUrls.getPublishPackage}/${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createPackage: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createPackage, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editPackage: async ({ data, id }) =>
    await AxiosInstance.put(`${ApiUrls.editPackage}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deletePackage: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deletePackage}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  searchPackage: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.searchPackage}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  publishPackage: async (id: any, data: any) =>
    await AxiosInstance.patch(`${ApiUrls.publishPackage}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  // essays
  getEssays: async (param: string) =>
    await AxiosInstance.get(`${ApiUrls.getEssays}/${param}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createEssay: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createEssay, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editEssay: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.editEssay}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  deleteEssay: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deleteEssay}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  // get dropdowns
  getStateDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getStateDrown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getExamTypesDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamTypesDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getExamConductedByDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamConductedByDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getSubjectsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubjectsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  createSubject: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.getSubjectsDropdown, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  updateSubject: async (data: any, id: any) => {
    return await AxiosInstance.put(
      `${ApiUrls.getSubjectsDropdown}/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  },

  getSearchedPakageDropdown: async (data: any) =>
    await AxiosInstance.post(ApiUrls.getSearchedPakageDropdown, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  getTopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getTopicsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getSubtopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubtopicsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  // questions
  addQuestion: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addQuestion, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  addComprehension: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addComprehension, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  addBulkQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addBulkQuestions, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  searchQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.searchQuestions, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  // Testimonial
  createTestimonial: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.createTestimonial, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  getByTestimonial: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();

    return await AxiosInstance.get(
      `${ApiUrls.getByTestimonial}?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  },

  // Testimonial
  editTestimonial: async (data: any, id: any) => {
    return await AxiosInstance.put(`${ApiUrls.editTestimonial}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  // Testimonial
  getByIdTestimonial: async (id: any) => {
    return await AxiosInstance.get(`${ApiUrls.getByIdTestimonial}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  deleteTestimonial: async (id: any) => {
    return await AxiosInstance.delete(`${ApiUrls.getByIdTestimonial}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  //create discount
  createDiscounts: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.createDiscounts, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  updateDiscounts: async (data: any, id: any) => {
    return await AxiosInstance.put(`${ApiUrls.createDiscounts}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  deleteDiscount: async (id: any) => {
    return await AxiosInstance.delete(`${ApiUrls.createDiscounts}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  //test
  createTest: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createTest, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getTestQuestion: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(
      `${ApiUrls.getTestQuestion}?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  },
  usedByTest: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.usedByTest}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  updatePositionAssignQuestion: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.updatePosition, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  assignTestQuestion: async (data: any) => {
    // const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.post(`${ApiUrls.assignQuestionToTest}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  getTests: async (data) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.getTests}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  searchTests: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.searchTest}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getTest: async (data) => {
    return await AxiosInstance.get(`${ApiUrls.getTest}/${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  editTest: async ({ data, id }) =>
    await AxiosInstance.put(`${ApiUrls.editTest}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getCounter: async () => {
    return await AxiosInstance.get(`${ApiUrls.getCounter}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  updateCounter: async (data) => {
    return await AxiosInstance.post(`${ApiUrls.updateCounter}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  getDashboard: async () => {
    return await AxiosInstance.get(`${ApiUrls.DashboardCard}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  editState: async ({ data, id }) => {
    return await AxiosInstance.put(`${ApiUrls.editState}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    });
  },
  profileUpdate: async ({ data, id }) => {
    return await AxiosInstance.put(`${ApiUrls.profileUpdate}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  deleteTest: async (id) =>
    await AxiosInstance.delete(`${ApiUrls.deleteTest}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  getQuestion: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addQuestion}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editQuestion: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.addQuestion}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getComprehension: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addComprehension}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editComprehension: async ({ data, id }) =>
    await AxiosInstance.put(`${ApiUrls.editComprehension}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  deleteQuestion: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.addQuestion}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  // Discount
  getDiscount: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.getDiscount}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  analysisData: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.analysisData}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  createExamConducted: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.getExamConductedByDropdown, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  updateExamConducted: async (data: any, id: any) => {
    return await AxiosInstance.put(
      `${ApiUrls.getExamConductedByDropdown}/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  },

  imageUploadBooksAdd: async (data: any) => {
    console.log("data", data);
    return await AxiosInstance.post(ApiUrls.imageUploadBooks, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  addBook: async (data: any) => {
    return await AxiosInstance.post(ApiUrls.addBook, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },

  //question
  addRandomQuestion: async (id: any, questioType: any) => {
    return await AxiosInstance.get(
      `${ApiUrls.addRandomQuestion}/${id}/${questioType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  },

  updateBook: async (data: any, id: any) => {
    return await AxiosInstance.put(`${ApiUrls.addBook}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  getBookId: async (id: any) => {
    return await AxiosInstance.get(`${ApiUrls.addBook}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  deleteBook: async (id: any) => {
    // const queryParams = new URLSearchParams(data).toString();

    return await AxiosInstance.delete(`${ApiUrls.addBook}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
  getBooks: async (data: any) => {
    const queryParams = new URLSearchParams(data).toString();

    return await AxiosInstance.get(`${ApiUrls.addBook}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  },
};
