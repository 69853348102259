export const addQuestionFields = (subjects, examTypes) => [
  {
    inputLabel: "Question Complexity",
    type: "text",
    select: true,
    id: "difficulty_level",
    placeholder: "Select question difficulty",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
  // {
  //   inputLabel: "Question Type",
  //   type: "text",
  //   select: true,
  //   id: "question",
  //   placeholder: "",
  //   options: [
  //     {
  //       name: "Multiple-Choice",
  //       value: "Multiple-Choice",
  //     },
  //     {
  //       name: "Medium",
  //       value: "medium",
  //     },
  //     {
  //       name: "Difficult",
  //       value: "difficult",
  //     },
  //   ],
  // },
  {
    inputLabel: "Is image present in Question",
    type: "text",
    select: true,
    id: "is_image",
    placeholder: "",
    options: [
      {
        name: "No",
        value: "false",
      },
      {
        name: "Yes",
        value: "true",
      },
    ],
  },
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    id: "subject",
    placeholder: "Please select subject",
    options: subjects.map((sb) => ({ name: sb.subject_name, value: sb.id })),
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    id: "exam_type_id",
    placeholder: "Please select exam type",
    options: examTypes.map((ex) => ({ name: ex.exam_name, value: ex.id })),
  },
  {
    inputLabel: "Topic",
    type: "text",
    select: false,
    id: "topic",
    placeholder: "Write a topic",
  },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    id: "grade",
    placeholder: "Please select grade",
    options: [
      {
        name: 1,
        value: 1,
      },
      {
        name: 2,
        value: 2,
      },
      {
        name: 3,
        value: 3,
      },
      {
        name: 4,
        value: 4,
      },
      {
        name: 5,
        value: 5,
      },
      {
        name: 6,
        value: 6,
      },
      {
        name: 7,
        value: 7,
      },
      {
        name: 8,
        value: 8,
      },
      {
        name: 9,
        value: 9,
      },
      {
        name: 10,
        value: 10,
      },
      {
        name: 11,
        value: 11,
      },
      {
        name: 12,
        value: 12,
      },
    ],
  },
  {
    inputLabel: "Quality Checked?",
    type: "text",
    select: true,
    id: "quality_checked",
    placeholder: "",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Sub-Topic",
    type: "text",
    select: false,
    id: "subtopic",
    placeholder: "Write a sub topic",
  },
];

export const comprehensionFields = (examTypes, subjects) => [
  {
    inputLabel: "Question Complexity",
    type: "text",
    select: true,
    id: "complexity",
    placeholder: "Select paragraph complexity",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    id: "exam_type_id",
    placeholder: "Please select exam type",
    options: examTypes.map((ex) => ({ name: ex.exam_name, value: ex.id })),
  },
  {
    inputLabel: "Is image present in Question",
    type: "text",
    select: true,
    id: "is_image",
    placeholder: "",
    options: [
      {
        name: "No",
        value: "false",
      },
      {
        name: "Yes",
        value: "true",
      },
    ],
  },
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    id: "subject_id",
    placeholder: "Please select subject",
    options: subjects.map((sb) => ({ name: sb.subject_name, value: sb.id })),
  },
  // {
  //   inputLabel: "Paragraph Topic",
  //   type: "text",
  //   select: false,
  //   id: "topic",
  //   placeholder: "Write a topic",
  // },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    id: "grade",
    placeholder: "Please select grade",
    options: Array.from({ length: 10 }, (i, ind) => ({
      name: ind + 1,
      value: ind + 1,
    })),
  },
  {
    inputLabel: "Quality Checked?",
    type: "text",
    select: true,
    id: "quality_checked",
    placeholder: "",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Paragraph Topic",
    type: "text",
    select: false,
    id: "topic",
    placeholder: "Write a topic",
  },
  {
    inputLabel: "Paragraph Sub-Topic",
    type: "text",
    select: false,
    id: "subtopic",
    placeholder: "Write a sub topic",
  },
];
export const comprehensionStepTwoFields = (examTypes, subjects) => [
  {
    inputLabel: "Question Complexity",
    type: "text",
    select: true,
    id: "complexity",
    placeholder: "Select paragraph complexity",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    id: "exam_type_id",
    placeholder: "Please select exam type",
    options: examTypes.map((ex) => ({ name: ex.exam_name, value: ex.id })),
  },
  {
    inputLabel: "Is image present in Question",
    type: "text",
    select: true,
    id: "is_image",
    placeholder: "",
    options: [
      {
        name: "No",
        value: "false",
      },
      {
        name: "Yes",
        value: "true",
      },
    ],
  },
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    id: "subject_id",
    placeholder: "Please select subject",
    options: subjects.map((sb) => ({ name: sb.subject_name, value: sb.id })),
  },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    id: "grade",
    placeholder: "Please select grade",
    options: Array.from({ length: 10 }, (i, ind) => ({
      name: ind + 1,
      value: ind + 1,
    })),
  },
  {
    inputLabel: "Quality Checked?",
    type: "text",
    select: true,
    id: "quality_checked",
    placeholder: "",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
];

export const excelStepTwoFields = (examTypes, subjects) => [
  // {
  //   inputLabel: "Question Type",
  //   type: "text",
  //   select: true,
  //   id: "question_type",
  //   placeholder: "Select question type",
  //   options: [
  //     {
  //       name: "True or False",
  //       value: "TrueFalse",
  //     },
  //     {
  //       name: "Multiple Choice",
  //       value: "MCQ",
  //     },
  //     {
  //       name: "Multiple Response",
  //       value: "MultiCorrect",
  //     },
  //   ],
  // },
  // {
  //   inputLabel: "",
  //   type: "text",
  //   select: false,
  //   id: "file",
  //   placeholder: "",
  // },
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    id: "subject",
    placeholder: "Please select subject",
    options: subjects?.map((sb) => ({ name: sb.subject_name, value: sb.id })),
  },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    id: "grade",
    placeholder: "Please select grade",
    options: Array.from({ length: 10 }, (i, ind) => ({
      name: ind + 1,
      value: ind + 1,
    })),
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    id: "exam_type_id",
    placeholder: "Please select exam type",
    options: examTypes?.map((ex) => ({ name: ex.exam_name, value: ex.id })),
  },
  {
    inputLabel: "Complexity",
    type: "text",
    select: true,
    id: "difficulty_level",
    placeholder: "Select question difficulty",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
];

export const excelStepThreeFields = (examTypes, subjects) => [
  {
    inputLabel: "Subject",
    type: "text",
    select: true,
    id: "subject",
    placeholder: "Please select subject",
    options: subjects?.map((sb) => ({ name: sb.subject_name, value: sb.id })),
  },
  {
    inputLabel: "Grade",
    type: "text",
    select: true,
    id: "grade",
    placeholder: "Please select grade",
    options: Array.from({ length: 10 }, (i, ind) => ({
      name: ind + 1,
      value: ind + 1,
    })),
  },
  {
    inputLabel: "Exam Type",
    type: "text",
    select: true,
    id: "exam_type_id",
    placeholder: "Please select exam type",
    options: examTypes?.map((ex) => ({ name: ex.exam_name, value: ex.id })),
  },
  {
    inputLabel: "Complexity",
    type: "text",
    select: true,
    id: "difficulty_level",
    placeholder: "Select question difficulty",
    options: [
      {
        name: "Easy",
        value: "easy",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Hard",
        value: "hard",
      },
    ],
  },
  {
    inputLabel: "Quality Checked?",
    type: "text",
    select: true,
    id: "quality_checked",
    placeholder: "",
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "false",
      },
    ],
  },
  {
    inputLabel: "Is image in Choice",
    type: "text",
    select: true,
    id: "is_image",
    placeholder: "",
    options: [
      {
        name: "No",
        value: "false",
      },
      {
        name: "Yes",
        value: "true",
      },
    ],
  },
];
