import { ReactComponent as DashboardSvg } from "../assests/icons/dashboard.svg";
import { ReactComponent as PackagesSvg } from "../assests/icons/packages.svg";
import { ReactComponent as AllTestsSvg } from "../assests/icons/all-tests.svg";
import { ReactComponent as QuestionBankSvg } from "../assests/icons/question-bank.svg";
import { ReactComponent as UserSvg } from "../assests/icons/user.svg";
import { ReactComponent as ProfileSvg } from "../assests/icons/profile.svg";
import { getItem } from "./basicService";

const user = JSON.parse(getItem('app-token'));
export const companyMenu = [
  {
    name: "Dashboard",
    pathname: "/company/dashboard",
    show: (user?.role === "admin" || user?.role === "user" || user?.role === "operator") && true,
    icon: DashboardSvg,
    iconClassName: "w-4 h-4",
    activePathname: "/company/dashboard",
  },
  {
    name: "Packages",
    pathname: "/company/packages",
    show: user?.role === "admin" && true,
    icon: PackagesSvg,
    iconClassName: "w-4 h-4",
    activePathname: ["/company/packages", "company/packages/create"],
  },
  {
    name: "All Tests",
    pathname: "/company/tests",
    show: user?.role === "admin" || user?.role === "operator",
    icon: AllTestsSvg,
    iconClassName: "w-4 h-4",
    activePathname: ["/company/tests"],
  },
  {
    name: "Question Bank",
    pathname: "/company/question-bank",
    show: user?.role === "admin",
    icon: QuestionBankSvg,
    iconClassName: "w-4 h-4",
    activePathname: "/company/question-bank",
  },
  {
    name: "User",
    pathname: "/company/users",
    show: user?.role === "admin",
    icon: UserSvg,
    iconClassName: "w-4 h-4",
    activePathname: "/company/users",
  },
  {
    name: "Profile",
    pathname: "/company/profile",
    show: user?.role === "admin" || user?.role === "operator",
    icon: ProfileSvg,
    iconClassName: "w-4 h-4",
    activePathname: "/company/profile",
  },
  {
    name: "Admin Tasks",
    pathname: "/company/admin-tasks",
    show: user?.role === "admin",
    icon: ProfileSvg,
    iconClassName: "w-4 h-4",
    activePathname: "/company/admin-tasks",
  },
];
