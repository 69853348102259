import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import TestimonialsTable from "../components/TestimonialsTable/index";
import { Button } from "@mui/material";
import AddTestimonialModal from "../pages/add-testimonial";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditSvg } from "../assests/icons/edit.svg";

import { ReactComponent as DeleteSvg } from "../assests/icons/delete.svg";
import { getTestimonial } from "../redux/slices/Master/masterSlice";
import { authorizatonCommonFun } from "../utils/basicService";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";

export default function Testimonials() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null); // State for editing a testimonial
  const [testimonials, setTestimonials] = useState([
    { id: 1, name: "John Doe", createdAt: "2024-12-08", status: "Active" },
    { id: 2, name: "Jane Smith", createdAt: "2024-12-07", status: "Inactive" },
    // Add more data as needed
  ]);

  const dispatch = useDispatch();
  const { testimonial } = useSelector(state => state?.master);

  useEffect(() => {
    const data = {
      page,
      limit: pageLimit ? pageLimit : 10
    }
    dispatch(getTestimonial(data));
  }, [page, pageLimit])

  // Handle delete testimonial
  const handleDelete = async (id) => {
    try {
       const reponse = await ApiRequest.deleteTestimonial(id);
       if(reponse.status === 200) {
         toast.success("Testimonial deleted successfully");
         const data = {
          page,
          limit: pageLimit ? pageLimit : 10
        }
        dispatch(getTestimonial(data));

       }
    } catch (e) {
      console.log("error", e);
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
    }
    // setTestimonials((prev) => prev.filter((testimonial) => testimonial.id !== id));
  };

  // Handle edit testimonial
  const handleEdit = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setOpen(true);
  };

  // Handle save (either add new or update existing)
  const handleSave = (updatedTestimonial) => {
    if (updatedTestimonial.id) {
      setTestimonials((prev) =>
        prev.map((testimonial) =>
          testimonial.id === updatedTestimonial.id ? updatedTestimonial : testimonial
        )
      );
    } else {
      const newTestimonial = { ...updatedTestimonial, id: testimonials.length + 1 };
      setTestimonials((prev) => [...prev, newTestimonial]);
    }
    setOpen(false);
    setSelectedTestimonial(null);
  };

  const navigate = useNavigate();
  const action = [
    {
      label: <EditSvg className="cursor-pointer" />,
      action: (testimonial) => {
        navigate(`/company/edit-testimonial/${testimonial.id}`);
      }
    },
    {
      label: <DeleteSvg className="cursor-pointer" />,
      action: (testimonial) => {handleDelete(testimonial.id)}
    }

 ]
  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">{"Testimonials"}</h1>
      <div className="mt-10 max-w-[900px] mx-auto">
        <div className="flex justify-end mb-5">
          <Button
            variant="contained"
            color="primary"
            className="rounded-none leading-6 text-xs font-semibold font-poppins"
            startIcon={<PlusSvg />}
            onClick={() => {
              setSelectedTestimonial(null); // Clear selected testimonial for adding a new one
              // setOpen(true);
              navigate('/company/add-testimonial');
            }}
          >
            Add Testimonial
          </Button>
        </div>
        <TestimonialsTable
          data={testimonial}
          actions={action}
          handleDelete={handleDelete}
          columns={ [
              { name: "Student Name", value: "username" },
              { name: "Created At", value: "created_at" },
              { name: "Status", value: "status" },
            ]}
          handleEdit={handleEdit}
          setPagee={setPage}
          setPageLimit={setPageLimit}
        />
        {/* <AddTestimonialModal
          open={open}
          setOpen={setOpen}
          onSave={handleSave}
          testimonial={selectedTestimonial}
        /> */}
      </div>
    </DashboardLayout>
  );
}
