import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css"; // Ensure you include the correct stylesheet if required

const SweetAlertDemo = ({apiFunction, component}) => {
  const [show, setShow] = useState(false);

  const handleConfirm = () => {
    setShow(false);
    apiFunction()

    // You can add additional actions for the "Yes" button here
    console.log("You clicked Yes");
  };


  const handleCancel = () => {
    setShow(false);
    // You can add additional actions for the "No" button here
    console.log("You clicked No");
  };

  return (
    <div>
    
      <button onClick={() => setShow(true)}>{component}</button>
      <SweetAlert
        show={show}
        title="Confirm"
        type="warning"
        // icon={<i className="fa fa-times-circle" style={{ color: "red", fontSize: "48px" }}></i>}
        confirmButtonColor="#8C52FF"
        text="Are you sure you want delete it"
        showCancelButton
        confirmButtonText="Yes"
        cancelButtonText="No"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default SweetAlertDemo;
