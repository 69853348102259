import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from 'moment'
import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ReactComponent as EditSvg } from "../../assests/icons/edit.svg";
import { ReactComponent as DeleteSvg } from "../../assests/icons/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAsyncThunk } from "../../redux/slices/auth/authSlice";
import { toast } from "react-toastify";
import { ApiRequest } from "../../utils/apiHelpers/apiRequest.ts";
import { getPackagesAsyncThunk } from "../../redux/slices/package/packageSlice";
import SweetAlertDemo from "../SweatAlert/index.js";

export default function UsersTable({setOpen, editUserData}) {
  const users = useSelector((store) => store.auth.package.data);
  const [sort, setSort] = useState({name: 'id', order: 'ASC'});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersAsyncThunk({
       roles: "admin,operator",
       orderBy: sort.name, order: sort.order
    }));
  }, [sort]);
  

  const handleChange = (name) => {
    setColumns((prevColumns) => {
      return prevColumns.map((it) => {
        if (it.value === name) {
          const newSort = it.sort === "asc" ? "desc" : "asc";
          return {
            ...it,
            sort: newSort,
            icon:
              newSort === "asc" ? (
                <ArrowDownwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ) : (
                <ArrowUpwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ),
          };
        }
        return {
          ...it,
        };
      });
    });

    console.log("Sort updated for:", name);
  };

  
  const [columns, setColumns] = React.useState([
    {
      name: "Name",
      sort: "asc",
      value: "full_name",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("full_name");
            setSort({ name: "full_name", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },

    {
      name: "Email",
      sort: "asc",
      value: "email",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("email");
            setSort({ name: "email", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Added",
      sort: "asc",
      value: "created_at",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("created_at");
            setSort({ name: "created_at", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Role",
      sort: "asc",
      value: "role",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("role");
            setSort({ name: "role", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
    },
  ]);
// const dispatch = useDispatch();
  const handleDelete = async (id) => {
    try {
      console.log("handleDelete", id)
       const response = await ApiRequest.deleteUser(id);
       dispatch(getUsersAsyncThunk({  roles: "admin,operator" }));
       toast.success("User deleted successfully");
       if(response.status === 200) {
        toast.success(response.message);
       }
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  return (
    <TableContainer component={Paper} className="shadow-none">
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: "separate",
          borderSpacing: "10px",
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              "& td, & th": {
                border: 0,
                margin: "5px",
                color: "#6C757D",
                lineHeight: "22px",
                borderBottom: "1px solid #1E1E1E52",
                fontSize: "14px",
                fontFamily: "Roboto",
              },
            }}
          >
            {columns && columns.length > 0 && columns.map((item) => (
              <TableCell>{item.name} {item.icon}</TableCell>
            ))}

            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow
              key={user.first_name}
              sx={{
                "& td": {
                  borderBottom: "1px solid #EAE9F1",
                  margin: "5px",
                  color: "#6C757D",
                  lineHeight: "22px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                },
              }}
            >
              <TableCell>{user.full_name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{moment(user.created_at).format('MMMM DD,YYYY')}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell align="center">
                <div className="flex justify-center items-center gap-5">
                  <EditSvg className="cursor-pointer" onClick={() => { setOpen(true); editUserData(user); }} />
                  <SweetAlertDemo apiFunction={() => handleDelete(user?.id)} component={
                        <DeleteSvg
                        className="cursor-pointer"
                      />
                      } />
                  {/* <DeleteSvg className="cursor-pointer" onClick={() =>{ handleDelete(user.id); }} /> */}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
