import Swal from "sweetalert2";
import { getSvgIcon } from "../../components/GetSvgIcons/Index";

export const SweetAlert = (
  type,
  title,
  text,
  confirmButtonText,
  loaderHtml,
  showLoaderConfirm,
) => {
  switch (type) {
    case "delete":
      return Swal.fire({
        title: title,
        html: text,
        iconHtml: getSvgIcon("delete"),
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        loaderHtml: loaderHtml,
        showLoaderOnConfirm: showLoaderConfirm,
        customClass: {
          popup: "deleteSwal",
        },
      });
    case "success":
      return Swal.fire({
        customClass: {
          popup: "confirmed-swal",
        },
        title: title,
        html: text,
        iconHtml: getSvgIcon("success"),
      });
    case "error":
      return Swal.fire({
        customClass: {
          popup: "error-swal",
        },
        title: title,
        html: text,
        iconHtml: getSvgIcon("error"),
      });
    case "warning":
      return Swal.fire({
        title: title,
        html: text,
        iconHtml: getSvgIcon("warning"),
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        customClass: {
          popup: "warningSwal",
        },
      });
    default:
      return Promise.resolve({ isConfirmed: false });
  }
};