import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { authorizatonCommonFun } from "../../../utils/basicService/index.js";
export const loginAsyncThunk = createAsyncThunk(
  "auth/loginAsyncThunk",
  asyncWrapper(async ({ data, navigate }, thunk) => {
    const response = await ApiRequest.login(data);
    if (response.status == 201) {
      console.log("response----->", response);
      localStorage.setItem("app-token", JSON.stringify(response.data.user));
      localStorage.setItem('role', response.data.user.role);
      localStorage.setItem("token", response.data.token);
      toast.success("Login successfully!");
      navigate("/company/dashboard");
      window.location.reload();
    }
    return response.data;
  })
);
export const verificationEmailAsyncThunk = createAsyncThunk(
  "auth/verificationEmailAsyncThunk",
  asyncWrapper(async ({ data, action, setOpen, navigate }, thunk) => {
// const navigate = useNavigate();

    console.log("data----->", data);
  try {
    let dataa = {activationCode: data.otp}
    let user = JSON.parse(localStorage.getItem('app-token'));
    console.log("local user", user);
    
    const response = await ApiRequest.verificationUser(user.id,dataa);
    console.log("response---->", response);
    if (response.status == 200) {
      // thunk.dispatch(getUsersAsyncThunk());
      toast.success("Account verified");
    // navigate("/login");
    if(navigate) {
      window.location.href = "/login";
    } 
      // action.resetForm();
      // setOpen(false);
    } 
    return response.data;
  }
  catch (e) {
    console.log("erer", e)
    toast.error(e.response.data.message);
  }
  })
);
export const addUserAsyncThunk = createAsyncThunk(
  "auth/addUserAsyncThunk",
  asyncWrapper(async ({ data, action, setOpen }, thunk) => {
    const response = await ApiRequest.createAdminUser(data);
    if (response.status == 201) {
      // thunk.dispatch(getUsersAsyncThunk());
      toast.success("Created user successfully!");
      // console.log("response", response);  
      // localStorage.setItem('app-token', JSON.stringify(response.data));
      // localStorage.setItem('token', response.);
      // action.resetForm();
      setOpen(false);
    }
    return response.data;
  })
);


export const createUserAsyncThunk = createAsyncThunk(
  "auth/createUserAsyncThunk",
  asyncWrapper(async ({ data, action, setOpen }, thunk) => {
    const response = await ApiRequest.createUser(data);
    if (response.status == 201) {
      // thunk.dispatch(getUsersAsyncThunk());
      toast.success("Signup successfully!");
      console.log("response", response);  
      localStorage.setItem('app-token', JSON.stringify(response.data));
      // localStorage.setItem('token', response.);
      // action.resetForm();
      // setOpen(false);
    }
    return response.data;
  })
);

export const dashboardAsyncThunk = createAsyncThunk(
  "auth/dashboardAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    try {
      const response = await ApiRequest.getDashboard();
       return response.data;
    } catch (e) {
      authorizatonCommonFun(e);
      console.log("Error", e);
      toast.error(e.response.data.message);
    }
  })
)

export const getUsersAsyncThunk = createAsyncThunk(
  "auth/getUsersAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    try {
    const response = await ApiRequest.allUsers(data);
    return response.data;
    }
    catch (e) {
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
    }
  })
);
export const getPackageAsyncThunk = createAsyncThunk(
  "auth/getPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getPackage(data);
    return response.data;
  })
);

export const searchPackageAsyncThunk = createAsyncThunk(
  "auth/searchPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.searchPackage(data);
    if (response.status == 200) {
      toast.success("Package delete successfully!");
    }
    return response.data;
  })
);
export const deletePackageAsyncThunk = createAsyncThunk(
  "auth/deletePackageAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    const response = await ApiRequest.deletePackage(id);
    if (response.status == 200) {
      toast.success("Package delete successfully!");
    }
    return response.data;
  })
);

export const createEssayAsyncThunk = createAsyncThunk(
  "auth/createEssayAsyncThunk",
  asyncWrapper(async ({ data, pckg }, thunk) => {
    const response = await ApiRequest.createEssay(data);
    if (response.status == 201) {
      toast.success("Essay added successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
    }
    return response.data;
  })
);

export const editEssayAsyncThunk = createAsyncThunk(
  "auth/editEssayAsyncThunk",
  asyncWrapper(async ({ data, id, pckg, fun }, thunk) => {
    const response = await ApiRequest.editEssay({ data, id });
    if (response.status == 200) {
      toast.success("Essay updated successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
      fun();
    }
    return response.data;
  })
);

export const getEssaysAsyncThunk = createAsyncThunk(
  "auth/getEssaysAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getEssays(data);
    return response.data;
  })
);

export const addQuestionAsyncThunk = createAsyncThunk(
  "auth/addQuestionAsyncThunk",
  asyncWrapper(async ({ data, action, navigate }, thunk) => {
    const response = await ApiRequest.addQuestion(data);
    if (response.status == 201) {
      toast.success("Question added successfully!");
      action.resetForm();
      // navigate("/company/packages");
    }
    return response.data;
  })
);

const initialState = {
  packages: [],
  package: {},
  essays: [],
  user: null,
  token: null,
  dashboard: [],
  users: [],
  loadings: {
    loginAsyncThunk: false,
    getPackageAsyncThunk: false,
    deletePackageAsyncThunk: false,
    searchPackageAsyncThunk: false,
    getPackageAsyncThunk: false,
    editEssayAsyncThunk: false,
    addQuestionAsyncThunk: false,
    getUsersAsyncThunk: false,
    createUserAsyncThunk: false,
    dashboardAsyncThunk: false
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      // auth
      .addCase(dashboardAsyncThunk.fulfilled, (state, action) => {
          state.dashboard = action.payload;
      })
      .addCase(loginAsyncThunk.pending, (state, action) => {
        state.loadings.loginAsyncThunk = true;
      })
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload; // Ensure this only exists once
        state.loadings.loginAsyncThunk = false;
      })
      .addCase(loginAsyncThunk.rejected, (state, action) => {
        state.loadings.loginAsyncThunk = false; // Handle rejection if needed
        state.error = action.error.message; // Optional error handling
      })
    
      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        state.package = action.payload;
      })
      .addCase(getPackageAsyncThunk.pending, (state, action) => {
        state.loadings.getPackageAsyncThunk = true; // Handle rejection if needed

      })
      .addCase(getPackageAsyncThunk.fulfilled, (state, action) => {
        state.loadings.getPackageAsyncThunk = false; // Handle rejection if needed
        state.package = action.payload;
      })
      .addCase(getPackageAsyncThunk.rejected, (state, action) => {
        state.loadings.getPackageAsyncThunk = false; // Handle rejection if needed
        state.error = action.error.message; // Optional error handling
      })
      .addCase(searchPackageAsyncThunk.fulfilled, (state, action) => {
        state.packages = action.payload;
      })
      // .addCase(deletePackageAsyncThunk.fulfilled, (state, action) => {
      //   state.packages = action.payload;
      // })
      // essays
      .addCase(getEssaysAsyncThunk.fulfilled, (state, action) => {
        state.essays = action.payload;
      })
      .addMatcher(
        isAnyOf(
          loginAsyncThunk,
          getEssaysAsyncThunk,
          deletePackageAsyncThunk,
          searchPackageAsyncThunk,
          editEssayAsyncThunk,
          addQuestionAsyncThunk,
          getUsersAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    setAuthenticatedUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    }
  },
});

export default authSlice.reducer;
export const { setAuthenticatedUser, setAuthToken } = authSlice.actions;
