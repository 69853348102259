import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Packages from "./pages/packages";
import Tests from "./pages/tests";
import EditPackage from "./pages/edit-package";
import EditTest from "./pages/edit-test";
import CreateTest from "./pages/create-test";
import Profile from "./pages/profile";
import QuestionBank from "./pages/question-bank";
import AddQuestion from "./pages/add-question";
import AddExcelQuestion from "./pages/add-excel-question";
import AddWordQuestion from "./pages/add-word-question";
import CreateEssay from "./pages/create-essay";
import AdminTasks from "./pages/admin-tasks";
import PublishPackage from "./pages/publish-package";
import PublishNewPackage from "./pages/publish-new-package";
import ProtectedRoute from "./components/ProtectedRoutes/Index";
import Package from "./pages/create-package";
import EditQuestion from "./pages/edit-question";
import SignUpPages from "./pages/signup";
import PrivateRoute from "./PrivateRoute";
import DashboardOne from "./pages/dashboard1";
import NotFound from "./pages/NotFound";
import ForgotPasswordPage from "./pages/forgot-password";
import ConfirmPasswordPage from "./pages/confirm-password";
import CreatePackage from "./components/CreatePackage/Index";
import Test from "./pages/tests";
import RichTextEditor from "./pages/rich-text-editor";
import TestQuestionBank from "./pages/test-question";
import TestByQuestionBank from "./pages/test-by-question";
import QuestionDragDrop from "./pages/question-drag-drop";
import Master from "./pages/master";
import Counter from "./pages/counter";
import PromoCodes from "./pages/promocode";
import TestPreview from "./pages/test-preview";
import Testimonials from "./pages/testimonial";
import AddBook from "./pages/Add-book";
import PublishedEBook from "./pages/publishedEBook";
import QuestionBankAnalysis from "./pages/QuestionBankAnalysis";
import AddTestimonialModal from "./pages/add-testimonial";
import EditBook from "./pages/edit-book";
import AddRandomQuestion from "./pages/add-random-question";

const user = JSON.parse(localStorage.getItem("app-token"));
const adminRoute = [
  {
    path: "/company/dashboard",
    element: <PrivateRoute component={<Dashboard />} role={"admin"} />,
  },
  {
    path: "/company/users",
    element: <PrivateRoute component={<Users />} role={"admin"} />,
  },
  {
    path: "/company/packages",
    element: <PrivateRoute component={<Packages />} role={"admin"} />,
  },
  {
    path: "/company/packages/create",
    element: <PrivateRoute component={<Package />} role={"admin"} />,
  },
  {
    path: "/company/packages/edit/:id",
    element: <PrivateRoute component={<EditPackage />} role={"admin"} />,
  },
  {
    path: "company/test/create",
    element: <PrivateRoute component={<CreateTest />} role={"admin"} />,
  },
  {
    path: "company/test/duplicate/:id",
    element: (
      <PrivateRoute component={<EditTest duplicate={true} />} role={"admin"} />
    ),
  },
  {
    path: "company/test/edit/:id",
    element: <PrivateRoute component={<EditTest />} role={"admin"} />,
  },
  {
    path: "company/add_instruction/:id",
    element: <PrivateRoute component={<RichTextEditor />} role={"admin"} />,
  },
  {
    path: "company/test_preview/:id",
    element: <PrivateRoute component={<TestPreview />} role={"admin"} />,
  },
  // { path: "/company/dashboard", element: <PrivateRoute component={<Dashboard />} /> },

  // {
  //   path: "/company/test/edit/:id",
  //   element:  <PrivateRoute component={<EditTest />} />,
  // },
  // { path: "/company/test/create", element: <PrivateRoute component={<CreateTest />} /> },
  {
    path: "/company/tests",
    element: <PrivateRoute component={<Tests />} role={"admin"} />,
  },
  {
    path: "/company/tests-question/:id",
    element: <PrivateRoute component={<TestQuestionBank />} role={"admin"} />,
  },
  {
    path: "/company/test-by-question/:id",
    element: <PrivateRoute component={<TestByQuestionBank />} role={"admin"} />,
  },
  {
    path: "/company/re-order/:id",
    element: <PrivateRoute component={<QuestionDragDrop />} role={"admin"} />,
  },
  {
    path: "/company/profile",
    element: <PrivateRoute component={<Profile />} role={"admin"} />,
  },
  {
    path: "/company/question-bank",
    element: <PrivateRoute component={<QuestionBank />} role={"admin"} />,
  },
  {
    path: "/company/question/add",
    element: <PrivateRoute component={<AddQuestion />} role={"admin"} />,
  },
  {
    path: "/company/random-question/:id",
    element: <PrivateRoute component={<AddRandomQuestion />} role={"admin"} />,
  },
  {
    path: "/company/question/add/:id",
    element: <PrivateRoute component={<AddQuestion />} role={"admin"} />,
  },
  {
    path: "/company/book",
    element: <PrivateRoute component={<PublishedEBook />} role={"admin"} />,
  },
  {
    path: "/company/book/:id",
    element: <PrivateRoute component={<EditBook />} role={"admin"} />,
  },
  {
    path: "/company/question/analysis",
    element: <PrivateRoute component={<QuestionBankAnalysis />} role={"admin"} />,
  },
  {
    path: "/company/question/edit/:id",
    element: <PrivateRoute component={<EditQuestion />} role={"admin"} />,
  },

  {
    path: "/company/question/add-excel-question",
    element: <PrivateRoute component={<AddExcelQuestion />} role={"admin"} />,
  },
  {
    path: "/company/question/add-word-question",
    element: <PrivateRoute component={<AddWordQuestion />} role={"admin"} />,
  },
  {
    path: "/company/essays/add",
    element: <PrivateRoute component={<CreateEssay />} role={"admin"} />,
  },
  {
    path: "/company/admin-tasks",
    element: <PrivateRoute component={<AdminTasks />} role={"admin"} />,
  },

  {
    path: "/company/master",
    element: <PrivateRoute component={<Master />} role={"admin"} />,
  },
  {
    path: "/company/counter",
    element: <PrivateRoute component={<Counter />} role={"admin"} />,
  },

  {
    path: "/company/discount",
    element: <PrivateRoute component={<PromoCodes />} role={"admin"} />,
  },
  // {
  //   path: "/company/publish-package",
  //   element: <PrivateRoute component={<PublishPackage />} />,
  // },
  // {
  //   path: "/company/package/publish-package/:id",
  //   element: <PrivateRoute component={<PublishNewPackage />} />,
  // },
  // {
  //   path: "/sign-up",
  //   element: <SignUpPages />,
  // },
  {
    path: "/company/testimonial",
    element: <PrivateRoute component={<Testimonials />} role={"admin"} />,
  },
  {
    path: "/company/add-testimonial",
    element: <PrivateRoute component={<AddTestimonialModal />} role={"admin"} />
  },
  {
    path: "/company/edit-testimonial/:id",
    element: <PrivateRoute component={<AddTestimonialModal />} role={"admin"} />
  },

  {
    path: "/company/add-book",
    element: <PrivateRoute component={<AddBook />} role={"admin"} />,
  },
  {
    path: "/login",
    element: <Signin />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/confirm-password/:id",
    element: <ConfirmPasswordPage />,
  },
];

const operatorRoute = [
  {
    path: "/login",
    element: <Signin />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/confirm-password/:id",
    element: <ConfirmPasswordPage />,
  },
  {
    path: "/company/question-bank",
    element: <PrivateRoute component={<QuestionBank />} role={"operator"} />,
  },
  {
    path: "/company/dashboard",
    element: <PrivateRoute component={<Dashboard />} role={"operator"} />,
  },
  {
    path: "/company/profile",
    element: <PrivateRoute component={<Profile />} role={"operator"} />,
  },
];
const router = createBrowserRouter([
  { path: "/", element: <Navigate to="/company/dashboard" /> },
  { path: "/signin", element: <Signin /> },
  {
    element: <ProtectedRoute />,
    children: user?.role === "admin" ? adminRoute : operatorRoute,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
