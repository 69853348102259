import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setAuthToken } from "../redux/slices/auth/authSlice";
import { getItem } from "../utils/basicService";
import { toast } from "react-toastify";

const PrivateRoute = ({ component, role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = JSON.parse(getItem('app-token'));

  useEffect(() => {
    // Retrieve token from local storage (or any other storage mechanism)
    let token = getItem('token');
    if ((!token || token === "null")) {
      navigate('/login');
    }
     else if (user?.role != role) {
      toast.error("You have not admin access please login with admin creditials");
      navigate('/login');

     }
    else {
      // Dispatch token to redux
      dispatch(setAuthToken(token));
    }
  }, [dispatch, navigate]);

  // If the token is available, render the component
  let token = getItem('token'); // This can be from local storage directly or redux state, depending on your flow
  return (token && token !== "null") && user?.role == role ? component : null;
};

export default PrivateRoute;
