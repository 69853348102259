import React, { useState } from 'react';

const OtpInput = ({ length, onOtpSubmit, buttonStyle }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));

  // Function to handle input change
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on the next input field if available
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  // Function to handle OTP submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onOtpSubmit(otp.join(""));
  };

  return (
    <>
   
    <form className='mt-4 space-y-5 md:mt-14' style={{textAlign:'center'}} onSubmit={handleSubmit}>
      <div style={{ display: 'flex', gap: '20px', justifyContent:'center', margin: '46px 10px 40px 10px'}}>
        {otp.map((value, index) => (
          <input
          className='otp-text'
            key={index}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
            style={{ width: '40px', height: '40px', textAlign: 'center', fontSize: '20px' }}
          />
        ))}
      </div>
      <button className='button-css' type="submit" style={buttonStyle}>Submit</button>
    </form>
    </>
  );
};

export default OtpInput;
