import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as DeleteIcon } from "../../../assests/icons/bin.svg";
import { ReactComponent as DescriptionIcon } from "../../../assests/icons/add-page.svg";
import { ReactComponent as EditIcon } from "../../../assests/icons/edit-icon.svg";
import { ReactComponent as LaunchIcon } from "../../../assests/icons/view.svg";
import { Link, useNavigate } from "react-router-dom";
import { SweetAlert } from "../../../utils/sweetAlert/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestAsyncThunk,
  DeleteTestAsyncThunk,
} from "../../../redux/slices/test/testSlice";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from "@mui/material";
import SweetAlertDemo from "../../SweatAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EBE5FF",
    color: "#454545",
    fontWeight: 500,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td, th": {
    border: 0,
    minWidth: "150px",
    textAlign: "center",
  },
}));

export default function Index({ tests, setSort }) {

  const loading = useSelector(
    (store) => store.packages.deletePackageAsyncThunk
  );
  const subjects = useSelector((store) => store.dropdown.subjects);
  const packages = useSelector((store) => store.packages.packages);
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const refs = React.useRef({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleTestDelete = (id) => {
    dispatch(DeleteTestAsyncThunk({ id, navigate }));
  };
  const [openRow, setOpenRow] = React.useState(null);

  const handleToggle = (test_id) => {
    setOpenRow((prevOpenRow) => (prevOpenRow === test_id ? null : test_id));
  };
  const [columns, setColumns] = React.useState([
    {
      name: "Sort",
      sort: "asc",
      value: "id",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("id");
            setSort({ name: "id", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Test Name",
      value: "test_name",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("test_name");
            setSort({ name: "test_name", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Subject",
      sort: "asc",
      value: "subject",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("test_name");
            setSort({ name: "test_name", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // value: "exam_type"
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Package Name",
      sort: "asc",
      value: "package_name",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package_name");
            setSort({ name: "package_name", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "Test Conducted By",
      sort: "asc",
      value: "test_conducted_by",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("test_conducted_by");
            setSort({ name: "test_conducted_by", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "Total Questions",
      value: "total_questions",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("total_questions");
            setSort({ name: "total_questions", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "Duration",
      value: "duration",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("duration");
            setSort({ name: "duration", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "State",
      sort: "asc",
      value: "state",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("state");
            setSort({ name: "state", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Package Published",
      sort: "asc",
      value: "package.package_quality_checked",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package");
            setSort({ name: "package", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
    },
  ]);

  const handleClose = () => {
    setOpenRow(null);
  };

  const handleChange = (name) => {
    setColumns((prevColumns) => {
      return prevColumns.map((it) => {
        if (it.value === name) {
          const newSort = it.sort === "asc" ? "desc" : "asc";
          return {
            ...it,
            sort: newSort,
            icon:
              newSort === "asc" ? (
                <ArrowDownwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ) : (
                <ArrowUpwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ),
          };
        }
        return {
          ...it,
        };
      });
    });
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenRow(null);
    } else if (event.key === "Escape") {
      setOpenRow(null);
    }
  };

  return (
    <>
      <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
        Count: {tests?.total}
      </p>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((item) => (
                  <StyledTableCell key={item.value} className="whitespace-nowrap">
                    {item.name} {item.icon}
                  </StyledTableCell>
                ))}
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests?.data?.map((test, ind) => {
              const packageName =
                packages?.data?.find(
                  (pack) => test?.package_id === pack.package_id
                )?.package_name || "N/A";
              const stateShortName =
                states.find((state) => state.id === test?.test_conducted_by)
                  ?.short_name || "N/A";

              // Initialize refs dynamically
              if (!refs.current[test.test_id]) {
                refs.current[test.test_id] = React.createRef();
              }

              return (
                <StyledTableRow key={test.test_id}>
                  <StyledTableCell>{ind + 1}</StyledTableCell>
                  <StyledTableCell>{test?.test_name}</StyledTableCell>
                  <StyledTableCell>{test.subjects_in_test.toString()}</StyledTableCell>
                  <StyledTableCell>{packageName}</StyledTableCell>
                  <StyledTableCell>{stateShortName}</StyledTableCell>
                  <StyledTableCell>{test.max_number_of_questions}</StyledTableCell>
                  <StyledTableCell>{test?.test_duration}</StyledTableCell>
                  <StyledTableCell>{test.state?.state_name}</StyledTableCell>
                  <StyledTableCell>
                    {test?.is_active ? (
                      <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                        Yes
                      </span>
                    ) : (
                      <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                        No
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex items-center justify-center gap-3">
                      <MoreVertIcon
                        ref={refs.current[test.test_id]}
                        onClick={() => handleToggle(test.test_id)}
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        className="activetooltip"
                      />
                      <Popper
                     
                      
                        open={openRow === test.test_id}
                        anchorEl={refs.current[test.test_id]?.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start" ? "left top" : "left bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={openRow === test.test_id}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <Link to={`/company/tests-question/${test?.test_id}`}>
                                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                                  </Link>
                                  <Link to={`/company/test/duplicate/${test?.test_id}`}>
                                    <MenuItem onClick={handleClose}>Duplicate</MenuItem>
                                  </Link>
                                  <Link
                                   to={`/company/tests-question/${test?.test_id}`}
                                   >
                                    <MenuItem onClick={handleClose}>Test preview</MenuItem>
                                  </Link>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                      <Link to={`/company/test/edit/${test?.test_id}`}>
                        <EditIcon />
                      </Link>
                      <SweetAlertDemo apiFunction={() => handleTestDelete(test?.test_id)} component={
                        <DeleteIcon
                        className="cursor-pointer"
                      />
                      } />
                      
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}