import { Avatar, Box } from "@mui/material";
import DashboardLayout from "../layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getTestAsyncThunk } from "../redux/slices/test/testSlice";
import { useDispatch, useSelector } from "react-redux";

const TestPreview = () => {
    const { id } = useParams();
  const singleTest = useSelector((store) => store.tests.test);
   const dispatch = useDispatch();
   useEffect(() => {
      console.log("singleTest---->", singleTest)
   }, [singleTest])

    useEffect(() => {
        dispatch(getTestAsyncThunk(id));
    }, [id])
  return (
    <>
      <DashboardLayout>
        <div className="mt-10 max-w-[900px] mx-auto rounded-[5px] py-[60px] px-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="col-span-2">
              {/* <h1 className="dashboard-heading text-center">Profile</h1> */}
              <br></br>
               <Box sx={{ float: 'left', background: "#fff", padding: "20px", borderRadius: "10px" }}>
              <div dangerouslySetInnerHTML={{__html: singleTest?.test_description}}></div> 
             </Box>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default TestPreview;
