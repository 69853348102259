import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as DeleteIcon } from "../../assests/icons/bin.svg";
import { ReactComponent as DescriptionIcon } from "../../assests/icons/add-page.svg";
import { ReactComponent as EditIcon } from "../../assests/icons/edit-icon.svg";
import { ReactComponent as LaunchIcon } from "../../assests/icons/view.svg";
import { Link, useNavigate } from "react-router-dom";
import { SweetAlert } from "../../utils/sweetAlert/Index";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { deletePackageAsyncThunk, getPackagesAsyncThunk } from "../../redux/slices/package/packageSlice";
import { setPackageId } from "../../redux/slices/test/testSlice";

import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { ApiRequest } from "../../utils/apiHelpers/apiRequest.ts";
import SweetAlertDemo from "../SweatAlert/index.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EBE5FF",
    color: "#454545",
    fontWeight: 500,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td, th": {
    border: 0,
    minWidth: "150px",
    textAlign: "center",
  },
}));

export default function Index({ packages, setSort, page, limit }) {
  const dispatch = useDispatch();

  const loading = useSelector(
    (store) => store.packages.deletePackageAsyncThunk
  );

  const navigate = useNavigate();

  const [columns, setColumns] = React.useState([
    {
      name: "Sort",
      sort: "asc",
      value: "package.id",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.id");
            setSort({ name: "package.id", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Package Name",
      value: "package.package_name",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.package_name");
            setSort({ name: "package.package_name", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Exam type",
      sort: "asc",
      value: "package.exam_type",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.exam_type");
            setSort({ name: "package.exam_type", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // value: "exam_type"
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Test Conducted B",
      sort: "asc",
      value: "package.conducted_by_id",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.conducted_by_id");
            setSort({ name: "package.conducted_by_id", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "State",
      sort: "asc",
      value: "package.state_id",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.state_id");
            setSort({ name: "package.state_id", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "Grade",
      value: "package.grade",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.grade");
            setSort({ name: "package.grade", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick:
    },
    {
      name: "Number of Tests",
      value: "package.number_of_tests",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.number_of_tests");
            setSort({ name: "package.number_of_tests", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Single or Multiple Subject?",
      sort: "asc",
      value: "package.test_type",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.test_type");
            setSort({ name: "package.test_type", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Quality Checked",
      sort: "asc",
      value: "package.package_quality_checked",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.package_quality_checked");
            setSort({ name: "package.package_quality_checked", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
      name: "Is Published",
      sort: "asc",
      value: "package.is_published",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.is_published");
            setSort({ name: "package.is_published", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
      // onClick: (name) => handleChange(name)
    },
    {
      name: "Package Price",
      value: "package.price",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.price");
            setSort({ name: "package.price", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
      name: "Essay",
      value: "package.hasEssays",
      sort: "asc",
      icon: (
        <ArrowDownwardIcon
          onClick={() => {
            handleChange("package.hasEssays");
            setSort({ name: "package.hasEssays", order: "DESC" });
          }}
          style={{ fontSize: "14px" }}
        />
      ),
    },
    // {
    //   name: "Quality Checked",
    //   icon: <ArrowDownwardIcon />,
    //   onClick: (name) => handleChange(name)
    // },
  ]);

  const handleChange = (name) => {
    setColumns((prevColumns) => {
      return prevColumns.map((it) => {
        if (it.value === name) {
          const newSort = it.sort === "asc" ? "desc" : "asc";
          return {
            ...it,
            sort: newSort,
            icon:
              newSort === "asc" ? (
                <ArrowDownwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ) : (
                <ArrowUpwardIcon
                  onClick={() => {
                    handleChange(it.value);
                    setSort({ name: it.value, order: it.sort.toUpperCase() });
                  }}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
              ),
          };
        }
        return {
          ...it,
        };
      });
    });

    console.log("Sort updated for:", name);
  };


  const handlePackageDelete = (id) => {
    dispatch(deletePackageAsyncThunk(id));
    // SweetAlert("success", "Sent", "Assessment Link Sent Successfully", "Ok");
  };

  const handleTest = async (id, publishPackage) => {
    try {
      console.log("publishPackage", publishPackage)
      const response = await ApiRequest.publishPackage(id, {is_published: publishPackage==="true"?"false":"true"});
      if(response.status===200) {
        dispatch(getPackagesAsyncThunk({ page, limit }));
      }
    } catch (e)
     {
      console.log("error", e);
      toast.error(e.response.data.message);
     }
  }
  // const columns
  return (
    <>
      <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
        Count: {packages?.total}
      </p>

      <TableContainer className="" component={Paper} style>
        <Table
          className="overflow-y-auto"
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((item) => (
                  <StyledTableCell className="whitespace-nowrap">
                    {item.name} {item.icon}
                  </StyledTableCell>
                ))}
              {/* <StyledTableCell >Sort<ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Package Name <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Exam type <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Test Conducted By <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>State <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Grade <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Number of Tests <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Single or Multiple Subject? <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Quality Checked <ArrowDownwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Is Published <ArrowUpwardIcon style={{fontSize: "14px"}} /></StyledTableCell>
              <StyledTableCell>Package Price <ArrowUpwardIcon style={{fontSize: "14px"}} /></StyledTableCell> */}
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages?.data?.map((pckg, ind) => (
              <StyledTableRow key={ind}>
                <StyledTableCell>{ind + 1}</StyledTableCell>
                <StyledTableCell>{pckg.package_name}</StyledTableCell>
                <StyledTableCell>{pckg?.exam_type}</StyledTableCell>
                <StyledTableCell>{pckg?.conductedBy?.name}</StyledTableCell>
                <StyledTableCell>
                  {pckg?.state_name?.state_name}
                </StyledTableCell>
                <StyledTableCell>{pckg.grade}</StyledTableCell>
                <StyledTableCell>{pckg.number_of_tests}</StyledTableCell>
                <StyledTableCell>{pckg?.test_type}</StyledTableCell>
                <StyledTableCell>
                  {pckg?.package_quality_checked ? (
                    <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                      Yes
                    </span>
                  ) : (
                    <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                      No
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {pckg?.is_published == "true" ? (
                    <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                      Yes
                    </span>
                  ) : (
                    <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                      No
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>{pckg.price}</StyledTableCell>
                <StyledTableCell>
                  {pckg.hasEssays ? "yes" : "No"}
                </StyledTableCell>

                <StyledTableCell className="flex items-center gap-3">
                  <div className="flex items-center gap-1 mt-[2px]">
                    
                      <Tooltip title="Publish" onClick={() => handleTest(pckg?.id, pckg?.is_published)}>
                        <span className="pl-[3px] pr-[3px] w-[16px] h-[15px] rounded-[3px] text-[#454545] text-xs bg-[#EBE5FF]">
                          P
                        </span>
                      </Tooltip>
                    <Link
                      to={`/company/test/create`}
                      onClick={() => dispatch(setPackageId({id: pckg.id, package_name: pckg.package_name}))}
                    >
                      <Tooltip title="Test">
                        <span className="w-[14px] h-[15px] rounded-[3px] text-[#454545] text-xs bg-[#EBE5FF]">
                          T
                        </span>
                      </Tooltip>
                    </Link>
                    {/* <LaunchIcon /> */}
                  </div>

                  <Link to={`/company/packages/edit/${pckg?.id}`}>
                    <EditIcon />
                  </Link>
                  <Link to={`/company/essays/add?package=${pckg?.id}`}>
                    <DescriptionIcon />
                  </Link>

                  <SweetAlertDemo apiFunction={() => handlePackageDelete(pckg?.id)} component={
                        <DeleteIcon
                        className="cursor-pointer"
                      />
                      } />

                
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
