import React from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assests/images/backgroundSvg.svg";

export default function DashboardCard({ Icon, title, count, navigateTo }) {
  const navigate = useNavigate(); // Initialize navigate

  const handleNavigation = () => {
    if (navigateTo) {
      navigate(navigateTo); 
    }
  };

  return (
    <div
      className={`dashboard-card-shadow px-5 py-3 h-[180px] rounded-[10px] text-center bg-white mx-auto relative overflow-hidden ${
        navigateTo ? "cursor-pointer" : ""
      }`} // Add cursor pointer only if navigateTo is provided
      onClick={navigateTo ? handleNavigation : undefined} // Conditionally add onClick handler
      style={{
        width: "110%",
        boxShadow:
          "rgba(128, 0, 128, 0.25) 0px 2px 5px -1px, rgba(128, 0, 128, 0.3) 0px 1px 3px -1px", // Purple color in RGBA
      }}
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-no-repeat bg-right-top z-0"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "60%", // Adjust the size to make it smaller
        }}
      ></div>

      {/* Foreground Content */}
      <p className="m-0 leading-6 text-[15px] font-medium text-primary text-left z-10 relative">
        {count}
      </p>
      <div className="flex items-center h-full justify-center flex-col z-10 relative">
        <Icon />
        <p className="m-0 text-[#202020B2] leading-6">{title}</p>
      </div>
    </div>
  );
}
