import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const TestLayout = ({ name, testId, questionBank }) => {
    const [isQuestionMenuOpen, setIsQuestionMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleQuestionMenu = () => {
        setIsQuestionMenuOpen((prev) => !prev);
    };

    return (
        <header className="flex shadow-md py-4 px-4 sm:px-10 xl:px-10 bg-black min-h-[70px] tracking-wide relative z-50 lxl:pr-13">
            <div className="flex flex-wrap items-center justify-between gap-5 w-full">
                <button className="relative inline-flex items-center bg-[#454545] justify-center me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white">
                    <span className="relative px-4 py-4 bg-[#454545] transition-all ease-in duration-75 text-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        <Link to={"/company/tests"}>Exit Test Editor</Link>
                    </span>
                </button>

                <div id="collapseMenu" className="text-white">
                    <h2 className="xl:text-[1.5rem] md:text-[1.3rem] sm:text-[.8rem] font-medium">
                        Test Name: {name || "Loading..."} {questionBank && '/ Add From Your Question Bank'}
                    </h2>
                </div>

                {!questionBank && <> <div className="flex items-center max-lg:mx-auto">
                    <button className="px-4 py-2 text-white text-sm rounded-full font-bold text-gray-500 bg-transparent transition-all ease-in-out duration-300 flex items-center justify-center space-x-2 max-sm:px-2 max-sm:py-1 max-sm:text-xs">
                        <i className="text-[1.8rem] fa-solid fa-eye max-sm:text-[1.5rem]"></i>{" "}
                        <span className="max-sm:text-xs">Preview</span>
                    </button>

                <div className="relative">
                        <button
                            id="addQuestionButton"
                            onClick={toggleQuestionMenu}
                            className="px-4 py-4 rounded-md text-sm text-white font-bold bg-purple-500 max-sm:px-3 max-sm:py-2 max-sm:text-xs"
                        >
                            <span className="max-sm:text-xs">Add Question</span>
                        </button>
                        <div
                            id="questionMenu"
                            className={`${
                                isQuestionMenuOpen ? "" : "hidden"
                            } -ml-[13px] px-4 py-2 bg-gray-100 shadow-lg rounded-lg absolute mt-2 space-y-2 w-48 md:w-40 max-sm:w-32`}
                        >
                            <button onClick={() => navigate(`/company/question/add/${testId}`)} className="w-full py-2 text-sm font-bold text-gray-700 hover:bg-gray-200 transition-all ease-in-out max-sm:text-xs">
                                {"Add new question"}
                            </button>
                            <button onClick={() => navigate(`/company/test-by-question/${testId}`)} className="w-full py-2 text-sm font-bold text-gray-700 hover:bg-gray-200 transition-all ease-in-out max-sm:text-xs">
                               {"Reuse from question bank"}  
                            </button>
                            <button onClick={() => navigate(`/company/random-question/${testId}`)} className="w-full py-2 text-sm font-bold text-gray-700 hover:bg-gray-200 transition-all ease-in-out max-sm:text-xs">
                                {"Add random questions"}
                            </button>
                        </div>
                    </div>

                    <button className="px-2 pl-8 py-2 text-sm font-bold text-white ml-4 transition-all ease-in-out duration-300 flex items-center justify-center space-x-4 max-sm:px-3 max-sm:py-1 max-sm:text-xs">
                        {"Actions"}
                    </button>
                </div></>}
            </div>
        </header>
    );
};

export default TestLayout;
