import React, { useEffect, useState } from "react";
import { Modal, Box, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { authorizatonCommonFun } from "../utils/basicService";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import moment from "moment";

const AddPromoCodeModal = ({ open, setOpen, id, data, fun }) => {
  console.log("data--->", data);
  
  const [formData, setFormData] = useState({
    code: data?.code,
    description: data?.description,
    value: data?.description,
    is_expired: data?.is_expired ? data?.is_expired : false,
    startDate: data?.startDate,
    endDate: data?.endDate
  });


  useEffect(() => {
    if (data) {
      setFormData({
        code: data?.code || "",
        description: data?.description || "",
        value: data?.value || "",
        is_expired: data?.is_expired || false,
        startDate: data?.startDate || null,
        endDate: data?.endDate || null,
      });
    }
  }, [data]); // Re-run the effect when `data` changes


  console.log("formDataformData", formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Promo Code Submitted:", formData);
    try {
      formData.value = +formData.value;
      const response = id ? await ApiRequest.updateDiscounts(formData, id) : await ApiRequest.createDiscounts(formData);
      if(response.status===201) {
        toast.success("Disount created successfully");
         setOpen(false);
      }
      if(response.status===200) {
        toast.success("Disount updated successfully");
         setOpen(false);
         fun();
      }
    } catch (e) {
      authorizatonCommonFun(e);
      toast.error(e.response.data.message);
      console.log("error", e);
    }
    // Add API call or state update logic here
    setOpen(false); // Close the modal
    setFormData({ code: "", description: "", value: "" }); // Reset form
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-promo-code-modal-title"
      aria-describedby="add-promo-code-modal-description"
    >
      <Box
       sx={{
        backgroundColor: "white",
        p: 4,
        borderRadius: "8px",
        boxShadow: 24,
        width: "500px",
        maxHeight: "90vh", // Limits height to 90% of the viewport height
        overflowY: "auto", // Adds scroll if content exceeds the height
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      >
        <h2 id="add-promo-code-modal-title" className="text-2xl font-bold mb-4">
          Add Promo Code
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <TextField
              label="Promo Code"
              name="code"
              value={formData.code}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
     
          <div className="mb-4">
            <TextField
              label="Value"
              name="value"
              value={formData.value}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
              type="number"
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </div>

          <div className="mb-4">
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
              multiline
              rows={4}
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Start Date"
              name="startDate"
              type="date"
              value={moment(formData.startDate).format('YYYY-MM-DD')}
              onChange={handleChange}
              fullWidth
              variant="standard"
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="End date"
              name="endDate"
              type="date"
              value={moment(formData.endDate).format("YYYY-MM-DD")}
              onChange={handleChange}
              fullWidth
              variant="standard"
              required
            />
          </div>
          { id && data && (
  <div className="mb-4">
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
      <RadioGroup
        row // This makes the radio buttons align in one line
        aria-labelledby="demo-radio-buttons-group-label"
        value={formData.is_expired ? false : true} // Adjust based on is_expired
        name="is_expired"
        onChange={(e) =>
          setFormData({ ...formData, is_expired: e.target.value === "false" })
        }
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="Active"
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="Unactive"
        />
      </RadioGroup>
    </FormControl>
  </div>
)}

          <div className="flex justify-end">
            <Button
              onClick={() => setOpen(false)}
              variant="text"
              color="secondary"
              className="mr-2"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default AddPromoCodeModal;
