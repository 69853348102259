import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
const Counter = () => {
  const [counterData, setCounterData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getCounterFun = async () => {
    try {
      const response = await ApiRequest.getCounter();
      if (response.status === 200) {
        setCounterData(response.data);
      }
    } catch (e) {
      console.log("Error", e);
      toast.error(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCounterFun();
  }, []);

  const formik = useFormik({
    enableReinitialize: true, // Reinitialize form values when counterData changes
    initialValues: {
      student_count: counterData?.student_count || "",
      test_count: counterData?.test_count || "",
      tutor_count: counterData?.tutor_count || "",
      question_count: counterData?.question_count || "",
    },
    onSubmit: async (values) => {
      try {
        const response = await ApiRequest.updateCounter(values);
        if(response.status===201) {
          toast.success("Succesfully updated");
        }
      } catch (e) {
        console.log("Error", e);
        toast.error(e.response.data.message);
      }
      console.log("Submitted values:", values);
    },
  });

  if (isLoading) {
    return <p>Loading...</p>; // Or a spinner
  }

  return (
    <DashboardLayout>
      <h2 className="text-center text-2xl font-bold mb-6">
        Add Counter Details
      </h2>
      <div className="flex h-[70vh] justify-center p-20 bg-gray-100">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-[40px] rounded-lg w-[78%]">
            <div className="grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 lg:grid-col-2 gap-6">
              {["student_count", "question_count", "test_count", "tutor_count"].map((field) => (
                <div key={field}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {field.replace("_count", "").replace("_", " ").toUpperCase()}
                  </label>
                  <input
                    type="number"
                    id={field}
                    name={field}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    placeholder={`Enter ${field.replace("_count", "")}`}
                    className="mt-2 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="mt-20 w-[450px] text-[1rem] bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                Publish Counter
              </button>
            </div>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default Counter;
