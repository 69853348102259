import { CircularProgress } from "@mui/material";
import React, { useRef, useState } from "react";
import CloudUploadSvg from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";

export default function UploadFile({ file, setFile, accept }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.size > 50 * 1000 * 1024) {
      toast.error("Maximum video size is 50mb");
      return;
    }
    setFile(e.target.files[0]);
    // if (droppedFile && droppedFile.type.startsWith("video/")) {
    //   uploadFile(droppedFile);
    // }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile?.size > 50 * 1000 * 1024) {
      toast.error("Maximum video size is 50mb");
      return;
    }
    setFile(e.target.files[0]);
    // if (selectedFile && selectedFile.type.startsWith("video/")) {
    //   uploadFile(selectedFile);
    // }
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <div
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
      className={`w-full rounded-[3px] border border-dashed p-5 ${
        isUploading ? "border-primary" : "border-[#D4BEFF]"
      } cursor-pointer bg-gray-100 text-center`}
    >
      <input
        title="assessmentVideo"
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        accept={accept}
      />
      {file ? (
        <>
          <CloudUploadSvg className="text-[#20202080] w-9 h-7" />
          <div className="mt-4">
            <p className="text-sm font-bold">Selected File: {file.name}</p>
          </div>
        </>
      ) : (
        <>
          <CloudUploadSvg className="text-[#20202080] w-9 h-7" />
          <p className="mb-2 text-xs leading-[18px] text-[#202020CC] font-bold">
            Browse File
          </p>
          <p className="mb-2 text-xs leading-[18px] text-[#202020CC]">
            Drag and drop files here
          </p>
        </>
      )}
    </div>
  );
}
