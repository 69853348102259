

import AWS from "aws-sdk";
import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

export const uploadToBucket = async (file) => {
  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
  const REGION = process.env.REACT_APP_AWS_REGION;
  
  
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  
  const s3 = new AWS.S3({
    region: REGION,
    params: { Bucket: S3_BUCKET },
  });

  // Handling file as base64
  const isBase64 = typeof file === "string" && file.startsWith("data:");
  const fileData = isBase64 ? Buffer.from(file.split(",")[1], "base64") : file;
  const fileName = isBase64 ? `image_${Date.now()}.jpg` : file.name;
  const fileType = isBase64
    ? "image/jpeg" // Assuming base64 is JPEG
    : file.type || "application/octet-stream";

  const params = {
    Bucket: S3_BUCKET,
    Key: `uploads/${Date.now()}_${fileName}`,
    Body: fileData,
    ACL: "public-read",
    ContentType: fileType,
    ContentEncoding: isBase64 ? "base64" : undefined, 
  };

  try {
    const data = await s3.upload(params).promise();
    return data.Location; 
  } catch (error) {
    console.error("Error uploading file: ", error);
    throw error; 
  }
};
