import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { authorizatonCommonFun } from "../utils/basicService/index.js";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";

export default function AddRandomQuestion() {
  const [questionType, setQuestionType] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setQuestionType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!questionType) {
      toast.error("Please select a question type.");
      return;
    }

    try {
      const response = await ApiRequest.addRandomQuestion(id, questionType);
      if (response.status === 200) {
        toast.success("Question added successfully!");
        setQuestionType("");
        navigate(`/company/tests-question/${id}`);
      }
    } catch (err) {
      console.log("Error adding question:", err);
      toast.error(err?.response?.data?.message || "Failed to add question.");
      authorizatonCommonFun(err);
    }
  };

  return (
    <DashboardLayout>
      <Typography variant="h4" className="dashboard-heading">
        Add Question
      </Typography>
      <Box
        mt={2}
        bgcolor="#E7FEF3"
        px={2.5}
        py={1.5}
        maxWidth="400px"
        borderRadius="4px"
      >
        <Typography variant="body2" color="#202020">
          You are adding Questions to your Question bank, not to a Test.
        </Typography>
        <Typography variant="caption" color="#202020">
          To add questions to a Test instead, go to the{" "}
          <span style={{ color: "#FB3311" }}>Test Section.</span>
        </Typography>
      </Box>
      <Box
        mt={3}
        mx="auto"
        bgcolor="#fff"
        p={4}
        borderRadius="8px"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
        maxWidth="800px"
      >
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="question-type-select-label">Question Type</InputLabel>
            <Select
              labelId="question-type-select-label"
              id="question-type-select"
              value={questionType}
              onChange={handleChange}
            >
              <MenuItem value="">Please Select</MenuItem>
              <MenuItem value="MCQ">MCQ</MenuItem>
              <MenuItem value="TrueFalse">True False</MenuItem>
              <MenuItem value="MultiCorrect">Multi-Correct</MenuItem>
              <MenuItem value="comprehension">Comprehension</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
          >
            Add Question
          </Button>
        </form>
      </Box>
    </DashboardLayout>
  );
}
